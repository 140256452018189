<template>
  <div>
    <MainNavbar :user="user" :currentPage="paginaAtual" />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div
      class="container mt-5 pt-5"
      style="min-height: 100vh"
      v-if="enderecos.length > 0"
    >
      <h4>Meus Endereços</h4>

      <div
        class="cardGrande mx-1 my-4 p-1"
        v-for="endereco in enderecos"
        :key="endereco.idEndereco"
      >
        <fieldset
          disabled="disabled"
          v-if="enderecoEmEdicao != endereco.idEndereco"
        >
          <div class="card-body">
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Apelido do endereço*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.nome"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Destinatário*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.destinatario"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >CEP (apenas números)*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.cep"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Logradouro*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.logradouro"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Número*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.numero"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Unidade</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="endereco.unidade"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Complemento/Referência</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="endereco.complemento"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Bairro*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.bairro"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Cidade*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.localidade"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Estado*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="endereco.uf"
                />
              </div>
            </div>
            <center>
              <span
                class="btn btn-primary"
                @click="enderecoEmEdicao = endereco.idEndereco"
              >
                <i class="far fa-edit"></i> Editar Endereço
              </span>
            </center>
          </div>
        </fieldset>
        <fieldset v-else>
          <!-- FORM DE EDITAR ENDEREÇO -->
          <div class="card-body">
            <form
              v-on:submit.prevent="
                gravarAlteracoesEndereco(
                  endereco.cep,
                  endereco.nome,
                  endereco.destinatario,
                  endereco.logradouro,
                  endereco.numero,
                  endereco.unidade,
                  endereco.bairro,
                  endereco.localidade,
                  endereco.uf,
                  endereco.complemento,
                  novoEndereco.logradouro,
                  novoEndereco.bairro,
                  novoEndereco.localidade,
                  novoEndereco.uf
                )
              "
            >
              <p clas="card-text">Editar endereço</p>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >Apelido do endereço*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="endereco.nome"
                    :placeholder="endereco.nome"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >Destinatário*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="endereco.destinatario"
                    :placeholder="endereco.destinatario"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >CEP (apenas números)*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="endereco.cep"
                    maxlength="8"
                    @keyup="consultaCep(endereco.cep)"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >Logradouro*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="novoEndereco.logradouro"
                    :placeholder="endereco.logradouro"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >Número*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="endereco.numero"
                    :placeholder="endereco.numero"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >Unidade (ex.: apt, casa etc.)</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="endereco.unidade"
                    :placeholder="endereco.unidade"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >Complemento/Referência</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="endereco.complemento"
                    :placeholder="endereco.complemento"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >bairro*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="novoEndereco.bairro"
                    :placeholder="endereco.bairro"
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >cidade*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="novoEndereco.localidade"
                    :placeholder="endereco.localidade"
                    readonly
                  />
                </div>
              </div>
              <div class="form-group row mt-3">
                <label for="name" class="col-md-4 col-form-label text-md-right"
                  >Estado*</label
                >
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    required
                    v-model="novoEndereco.uf"
                    :placeholder="endereco.uf"
                    readonly
                  />
                </div>
              </div>
              <center>
                <p>Os campos marcados com * são obrigatórios.</p>
                <span
                  class="btn btn-primary"
                  @click="
                    enderecoEmEdicao = null;
                    novoEndereco = '';
                    novoCep = '';
                    carregarEndereços();
                  "
                >
                  <i class="fas fa-undo-alt"></i> Cancelar Edição
                </span>

                <button class="btn btn-success" type="submit">
                  <i class="far fa-save"></i> Gravar Alterações
                </button>

                <span class="btn btn-danger" @click="excluirEndereco()">
                  <i class="far fa-trash-alt"></i> Excluir Endereço
                </span>
              </center>
            </form>
          </div>
        </fieldset>
      </div>
    </div>
    <div v-else class="container mt-5 pt-5" style="min-height: 100vh">
      <h4>Meus Endereços</h4>
      <p>
        Você ainda não cadastrou nenhum endereço para entrega... Que tal
        adicionar o primeiro agora?
      </p>
      <center>
        <i
          class="fas fa-map-marked-alt fa-7x mt-5"
          style="color: lightgray"
        ></i>
      </center>
    </div>

    <button
      class="btn btn-success rounded-circle p-1 mb-1 mr-3"
      style="
        z-index: 3;
        height: 80px;
        width: 80px;
        white-space: normal;
        position: fixed;
        bottom: 0px;
        right: 0px;
      "
      ref="aplicarFiltro"
      @click="modals.adicionarEndereco = true"
    >
      <i class="fas fa-plus"></i>
      Adicionar Endereço
    </button>

    <!-- INICIO MODAL ADICIONAR ENDEREÇO -->
    <modal
      :show.sync="modals.adicionarEndereco"
      :show-close="false"
      headerClasses="justify-content-center"
      style="z-index: 1000"
      ><center>
        <h4 class="mt-0">Cadastrar Endereço</h4>
      </center>
      <form v-on:submit.prevent="cadastrarEndereco(3)">
        <div class="form-group row mt-3">
          <label for="name" class="col-md-12">CEP</label>
          <div class="col-md-12">
            <input
              required
              id="novoCep"
              type="text"
              maxlength="8"
              class="form-control"
              name="novoCep"
              placeholder="Informe o seu CEP (apenas números)"
              autocomplete="off"
              v-model="novoCep"
              @keyup="consultaCep(novoCep)"
            />
          </div>
        </div>

        <div v-if="novoEndereco.erro == true" style="color: red">
          <p>O CEP digitado é inválido. Tente novamente.</p>
        </div>

        <div v-if="novoEndereco != '' && novoEndereco.erro != true">
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">Apelido do endereço*</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                required
                v-model="novonomeEndereco"
                placeholder="Ex.: Minha casa, Casa da minha tia etc."
              />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">Destinatário*</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                required
                v-model="novoDestinatario"
                placeholder="nome da pessoa que vai receber o envio."
              />
            </div>
          </div>

          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">logradouro*</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                required
                v-model="novoEndereco.logradouro"
              />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">Número*</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                required
                v-model="novoEndereco.numero"
              />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12"
              >Unidade (ex.: apt, casa etc.)</label
            >
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                v-model="novoEndereco.unidade"
              />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">Complemento/Referência</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                v-model="novoEndereco.complemento"
              />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">bairro*</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                required
                v-model="novoEndereco.bairro"
              />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">cidade*</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                required
                v-model="novoEndereco.localidade"
                readonly
              />
            </div>
          </div>
          <div class="form-group row mt-3">
            <label for="name" class="col-md-12">Estado*</label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control"
                required
                v-model="novoEndereco.uf"
                readonly
              />
            </div>
          </div>
          <center>
            <p>Os campos marcados com * são obrigatórios.</p>
            <button
              class="btn btn-success"
              type="submit"
              @click="verificaFormEndereco()"
            >
              <i class="fas fa-check"></i> Cadastrar Endereço
            </button>
          </center>
        </div>

        <center>
          <n-button
            type="primary"
            @click.native="modals.adicionarEndereco = false"
            >Cancelar</n-button
          >
        </center>
      </form>
    </modal>
    <!-- FIM MODAL busca -->
  </div>
</template>
<script>
// import firebase from "firebase";
import MainNavbar from "./MainNavbar";
import { Modal, Button } from "@/components";
import { mapGetters } from "vuex";
import * as axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  components: {
    MainNavbar,
    Modal,
    [Button.name]: Button,

    Loading,
  },
  data() {
    return {
      paginaAtual: "enderecos",
      novonome: this.$store.getters.user.fullname,
      novoTelefone: this.$store.getters.user.Telefone,
      novoCPF: this.$store.getters.user.CPF,

      enderecos: [],
      enderecoEmEdicao: null,
      dadosConsultaCep: "",
      novoCep: "",
      novoEndereco: "",
      novoDestinatario: "",
      novonomeEndereco: "",

      modals: {
        adicionarEndereco: false,
      },

      isLoading: false,
      onCancel: Function,
      fullPage: "",
    };
  },
  mounted() {
    this.carregarEndereços();
  },
  methods: {
    carregarEndereços() {
      this.isLoading = true;
      axios
        .post(ambiente + "enderecos.php", {
          transaction: 1,
          idUsuario: this.$store.getters.user.id,
        })
        .then((response) => {
          if (response.data.status != 200) {
            this.isLoading = false;
            this.enderecos = response.data.enderecos;
          } else {
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "warning",
              title: response.data.title + "\n" + response.data.message,
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          alert(error);
        });
    },
    cadastrarEndereco(transaction) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Endereço Cadastrado");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Endereço Cadastrado");
      }

      axios
        .post(ambiente + "enderecos.php", {
          transaction: transaction, // 2 - EDITAR | 3 - CADASTRAR NOVO
          cep: this.novoCep,
          idEndereco: this.enderecoEmEdicao,
          idUsuario: this.$store.getters.user.id,
          nome: this.novonomeEndereco,
          destinatario: this.novoDestinatario,
          logradouro: this.novoEndereco.logradouro,
          numero: this.novoEndereco.numero,
          unidade: this.novoEndereco.unidade,
          bairro: this.novoEndereco.bairro,
          cidade: this.novoEndereco.localidade,
          estado: this.novoEndereco.uf,
          complemento: this.novoEndereco.complemento,
        })
        .then((response) => {
          this.enderecoEmEdicao = null;
          this.modals.adicionarEndereco = false;

          this.novoCep = "";
          this.novoEndereco = "";
          this.novonomeEndereco = "";
          this.novoDestinatario = "";

          this.carregarEndereços();
          this.isLoading = false;
          this.$swal.fire({
            toast: true,
            icon: "success",
            title: response.data.title + "\n" + response.data.message,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          this.enderecoEmEdicao = null;
          this.isLoading = false;
          alert(error);
        });
    },

    gravarAlteracoesEndereco(
      cep,
      nome,
      destinatario,
      logradouro,
      numero,
      unidade,
      bairro,
      localidade,
      uf,
      complemento,
      novoLogradouro,
      novoBairro,
      novoLocalidade,
      novoUF
    ) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Endereço Editado");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Endereço Editado");
      }

      axios
        .post(ambiente + "enderecos.php", {
          transaction: 2, // 2 - EDITAR | 3 - CADASTRAR NOVO
          cep: cep,
          idEndereco: this.enderecoEmEdicao,
          idUsuario: this.$store.getters.user.id,
          nome: nome,
          destinatario: destinatario,
          logradouro: novoLogradouro || logradouro,
          numero: numero,
          unidade: unidade,
          bairro: novoBairro || bairro,
          cidade: novoLocalidade || localidade,
          estado: novoUF || uf,
          complemento: complemento,
        })
        .then((response) => {
          this.enderecoEmEdicao = null;
          this.modals.adicionarEndereco = false;

          this.endereco = "";

          this.carregarEndereços();
          this.isLoading = false;
          this.$swal.fire({
            toast: true,
            icon: "success",
            title: response.data.title + "\n" + response.data.message,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((error) => {
          this.enderecoEmEdicao = null;
          this.isLoading = false;
          alert(error);
        });
    },

    excluirEndereco() {
      this.$swal({
        title: "Excluir endereço",
        text:
          "Tem certeza que deseja excluir o endereço selecionado?\n\nEsta operação não poderá ser desfeita.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "excluir",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;

          if (!this.$store.getters.user) {
            this.$gtag.event("Endereço Excluído");
          } else if (this.$store.getters.user.conta != 1) {
            this.$gtag.event("Endereço Excluído");
          }

          axios
            .post(ambiente + "enderecos.php", {
              transaction: 4, //EXCLUIR
              idEndereco: this.enderecoEmEdicao,
              idUsuario: this.$store.getters.user.id,
            })
            .then((response) => {
              this.enderecoEmEdicao = null;
              this.enderecos = [];
              this.carregarEndereços();
              this.isLoading = false;
              this.$swal.fire({
                toast: true,
                icon: "success",
                title: response.data.title + "\n" + response.data.message,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((error) => {
              this.enderecoEmEdicao = null;
              this.isLoading = false;
              alert(error);
            });
        } else {
          this.enderecoEmEdicao = null;
        }
      });
    },

    consultaCep(cep) {
      cep = cep.replace(/[^0-9]/g, "");
      if (cep.length >= 8) {
        this.novoEndereco = "";
        this.isLoading = true;
        axios
          .post(ambiente + "consulta_cep.php", {
            cep: cep,
          })
          .then((response) => {
            if (response.erro) {
              this.novoEndereco.erro == true;
            } else {
              this.novoEndereco.erro == false;
            }
            this.isLoading = false;
            this.novoEndereco = response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
          });
      }
    },

    verificaFormEndereco() {
      var error = [];
      if (!this.novoCep) {
        error.push("<li>É necessário informar o CEP para prosseguir.</li>");
      } else if (!this.novonomeEndereco) {
        error.push(
          "<li>É preciso atribuir um nome de endereço para prosseguir.</li>"
        );
      } else if (!this.novoDestinatario) {
        error.push(
          "<li>É preciso informar o nome do destinatário para prosseguir.</li>"
        );
      } else if (!this.novoEndereco.numero) {
        error.push(
          "<li>É preciso informar o número do endereço para prosseguir.</li>"
        );
      }
      const formErrMsg = String(error).replace(/,/g, "<br/>");
      console.log(formErrMsg.length);
      if (formErrMsg.length > 3) {
        this.$swal({
          title: "Ops...",
          html: "<ul>" + formErrMsg + "</ul>",
          icon: "warning",
          confirmButtonText: "OK",
          showLoaderOnConfirm: false,
        });
      }
    },
  },
};
</script>
<style scopped></style>
