<template>
  <div
    v-if="$store.getters.user.conta == 1 && this.$route.query.modulo"
    class="section my-0 py-0"
  >
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
    <div class="cardGrande mb-0 mt-3" v-if="vendas">
      <h5 class="p-3" v-if="$route.query.modulo == 0">
        Vendas concluídas
        <font style="color: #BEBEBE">({{ vendas.length }})</font>
      </h5>
      <h5 class="p-3" v-else-if="$route.query.modulo == 1">
        Vendas em prazo de troca
        <font style="color: #BEBEBE">({{ vendas.length }})</font>
      </h5>
      <h5 class="p-3" v-else-if="$route.query.modulo == 2">
        Vendas em andamento
        <font style="color: #BEBEBE">({{ vendas.length }})</font>
      </h5>
      <h5 class="p-3" v-else-if="$route.query.modulo == 3">
        Vendas sem checkout
        <font style="color: #BEBEBE">({{ vendas.length }})</font>
      </h5>
      <h5 class="p-3" v-else-if="$route.query.modulo == 5">
        Resultado da busca pela sacola {{ $route.query.idSacola }}
      </h5>

      <b-pagination
        v-if="vendas.length > perPage"
        class="d-flex justify-content-center m-0 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="vendasPaginadas"
      ></b-pagination>

      <div
        class="container"
        id="vendasPaginadas"
        style="min-height: 100vh;"
        :vendas="vendas"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <div class="row">
          <div class="col-md-12">
            <ul v-if="vendas.length > 0" class="list-group mb-3">
              <span
                v-for="(venda, index) in vendas.slice(
                  perPage * (currentPage - 1),
                  perPage * currentPage
                )"
                :key="index"
                class="mb-5"
              >
                <h6>
                  Pedido nº {{ venda.idSacola }}
                  <font
                    v-if="venda.dadosSacola.length == 1"
                    style="color: #BEBEBE"
                    >({{ venda.dadosSacola.length }} item)</font
                  >
                  <font
                    v-else-if="venda.dadosSacola.length > 1"
                    style="color: #BEBEBE"
                    >({{ venda.dadosSacola.length }} itens)</font
                  >
                </h6>

                <span
                  v-for="produto in venda.dadosSacola"
                  :key="produto.idProduto"
                >
                  <li
                    class="list-group-item d-flex justify-content-left lh-condensed mx-0 pb-1"
                  >
                    <img
                      :src="
                        'https://www.infanziabrecho.com.br/uploads/' +
                          produto.Foto1
                      "
                      class="rounded-circle mr-4"
                      style="width: 50px; height: 50px"
                    />
                    <div class="mx-0 px-0">
                      <h6 class="my-0">
                        (Produto: {{ produto.idProduto }} - Fornecedor:
                        {{ produto.idFornecedor }} -
                        {{ produto.NomeFornecedor }})
                        <router-link
                          :to="
                            `/administrativo/produto/detalhe?produto=${produto.idProduto}`
                          "
                          target="_blank"
                        >
                          {{ produto.NomeProduto }}
                        </router-link>
                      </h6>
                      <p class="text-muted m-0 p-0">
                        <span v-if="produto.PrecoDescontoInfanzia != 0">
                          <s
                            >R$
                            {{
                              produto.Preco.toFixed(2)
                                .toString()
                                .replace(".", ",")
                            }}</s
                          >

                          R$
                          {{
                            produto.PrecoDescontoInfanzia.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                        <span v-else-if="produto.PrecoPromocional != 0">
                          <s
                            >R$
                            {{
                              produto.Preco.toFixed(2)
                                .toString()
                                .replace(".", ",")
                            }}</s
                          >
                          R$
                          {{
                            produto.PrecoPromocional.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                        <span v-else-if="produto.PrecoCategoria != 0">
                          <s
                            >R$
                            {{
                              produto.Preco.toFixed(2)
                                .toString()
                                .replace(".", ",")
                            }}</s
                          >
                          R$
                          {{
                            produto.PrecoCategoria.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                        <span v-else>
                          R$
                          {{
                            produto.Preco.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                      </p>

                      <b-button
                        size="sm"
                        pill
                        style="background-color: white; color: #ff6052; border: 1px solid #ff6052;"
                        @click="removerItem(produto.idSacola, produto)"
                        >Remover</b-button
                      >
                    </div>
                  </li>
                </span>

                <li
                  v-if="venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Soma dos produtos</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        parseFloat(venda.somaProdutos)
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>

                <li
                  v-if="$route.query.modulo != 3 && $route.query.modulo != 0"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0 w-100">
                    <h6 class="my-0">Adicionar produto</h6>
                    <div class="input-group my-2">
                      <input
                        type="number"
                        class="form-control m-0"
                        placeholder="Informar código"
                        v-model="idProduto"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-sm m-0 form-control"
                          style="background-color: #19ce0f; color: #ffff; border: 1px"
                          @click="adicionarProduto(venda, idProduto)"
                        >
                          Adicionar
                        </button>
                      </div>
                    </div>
                  </div>
                </li>

                <li
                  v-if="$route.query.modulo != 3 && venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Frete</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        venda.dadosSacola[0].Frete.toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                      {{ venda.dadosSacola[0].TipoFrete }}
                    </p>
                  </div>
                </li>

                <li
                  v-if="venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Desconto</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        venda.dadosSacola[0].Desconto.toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>

                <li
                  v-if="venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Total</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        venda.dadosSacola[0].Valor.toString().replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>

                <li
                  v-if="venda.dadosSacola[0] && venda.dadosSacola[0].UsoCredito"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div
                    class="mx-0 px-0"
                    v-if="venda.dadosSacola[0].UsoCredito != 0"
                  >
                    <h6 class="my-0">Uso de Créditos</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        venda.dadosSacola[0].UsoCredito.toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>

                <li
                  v-if="venda.dadosSacola[0] && venda.dadosSacola[0].UsoCredito"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div
                    class="mx-0 px-0"
                    v-if="venda.dadosSacola[0].UsoCredito != 0"
                  >
                    <h6 class="my-0">A receber</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        (
                          venda.dadosSacola[0].Valor -
                          venda.dadosSacola[0].UsoCredito
                        )
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>

                <li
                  v-if="venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h5 class="my-0">Data do pedido</h5>
                    <p class="text-muted mb-1">
                      {{ venda.dadosSacola[0].DataSacola }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="venda.dadosSacola[0] && $route.query.modulo != 3"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0 w-100" v-if="venda.dadosSacola[0]">
                    <h5 class="my-0">Status Pagamento</h5>

                    <div class="input-group my-2">
                      <select
                        class="form-control m-0"
                        v-model="venda.dadosSacola[0].StatusPedido"
                      >
                        <option value="Aguardando Pagamento"
                          >Aguardando Pagamento</option
                        >
                        <option value="Aguardando Depósito/Transferência"
                          >Aguardando Depósito/Transferência</option
                        >
                        <option value="Pagamento Recebido"
                          >Pagamento Recebido</option
                        >
                      </select>

                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-sm m-0 form-control"
                          style="background-color: #19ce0f; color: #ffff; border: 1px"
                          @click="
                            editaStatusPagamento(
                              venda.idSacola,
                              venda.dadosSacola[0].StatusPedido,
                              venda.dadosSacola[0].Email
                            )
                          "
                        >
                          Aplicar
                        </button>
                      </div>
                    </div>

                    <span
                      v-if="
                        venda.dadosSacola[0].StatusPedido ==
                          'Aguardando Pagamento'
                      "
                      class="text-muted mb-1"
                      style="font-size: 11px"
                    >
                      <div class="input-group my-2">
                        <input
                          type="text"
                          class="form-control m-0"
                          placeholder="Digite o link para pagamento"
                          v-model="venda.dadosSacola[0].LinkPagamento"
                        />
                        <div class="input-group-append">
                          <button
                            type="submit"
                            class="btn btn-sm m-0 form-control"
                            style="background-color: #19ce0f; color: #ffff; border: 1px"
                            @click="
                              editaLinkPagamento(
                                venda.idSacola,
                                venda.dadosSacola[0].LinkPagamento
                              )
                            "
                          >
                            Aplicar
                          </button>
                        </div>
                      </div>
                    </span>
                  </div>
                </li>
                <li
                  v-if="$route.query.modulo != 3 && venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0 w-100" v-if="venda.dadosSacola[0]">
                    <h5 class="mt-0 mb-2">Status Entrega</h5>
                    <input
                      type="checkbox"
                      v-model="venda.dadosSacola[0].Rastreio"
                      :id="`rastreio${venda.idSacola}`"
                    />
                    &nbsp;
                    <label :for="`rastreio${venda.idSacola}`">
                      Objeto postado via Correios</label
                    >
                    <br />
                    <input
                      type="checkbox"
                      v-model="venda.dadosSacola[0].Entregue"
                      :id="`entregue${venda.idSacola}`"
                    />
                    &nbsp;
                    <label :for="`entregue${venda.idSacola}`">
                      Pedido Entregue</label
                    >
                    <br />
                    <label>Data da entrega</label>
                    <datetime
                      v-model="venda.dadosSacola[0].DataEntrega"
                      value-zone="America/Sao_Paulo"
                      zone="America/Sao_Paulo"
                    ></datetime>
                    <div class="input-group my-2">
                      <input
                        type="text"
                        class="form-control m-0"
                        placeholder="Status da entrega"
                        v-model="venda.dadosSacola[0].CodigoRastreio"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-sm m-0 form-control"
                          style="background-color: #19ce0f; color: #ffff; border: 1px"
                          @click="
                            editaStatusEntrega(
                              venda.idSacola,
                              venda.dadosSacola[0].Rastreio,
                              venda.dadosSacola[0].CodigoRastreio,
                              venda.dadosSacola[0].Entregue,
                              venda.dadosSacola[0].DataEntrega,
                              venda.dadosSacola[0].Email
                            )
                          "
                        >
                          Aplicar
                        </button>
                      </div>
                    </div>
                    <button
                      class="btn btn-success"
                      v-if="venda.dadosSacola[0].Rastreio != 0"
                      @click="rastreio(venda.dadosSacola[0].CodigoRastreio)"
                    >
                      Rastrear Entrega
                    </button>
                  </div>
                </li>
                <li
                  v-if="$route.query.modulo != 3 && venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0 w-100">
                    <h5 class="my-0">Custo efetivo de postagem</h5>
                    <div class="input-group my-2">
                      <input
                        type="number"
                        class="form-control m-0"
                        placeholder="Informar valor"
                        v-model="venda.dadosSacola[0].CustoPostagem"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-sm m-0 form-control"
                          style="background-color: #19ce0f; color: #ffff; border: 1px"
                          @click="
                            editaCustoEfetivoPostagem(
                              venda.idSacola,
                              venda.dadosSacola[0].CustoPostagem
                            )
                          "
                        >
                          Aplicar
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="$route.query.modulo != 3 && venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0 w-100">
                    <h5 class="my-0">Custo Mercado Pago</h5>
                    <div class="input-group my-2">
                      <input
                        type="number"
                        class="form-control m-0"
                        placeholder="Informar valor"
                        v-model="venda.dadosSacola[0].CustoMercadoPago"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-sm m-0 form-control"
                          style="background-color: #19ce0f; color: #ffff; border: 1px"
                          @click="
                            editaCustoMercadoPago(
                              venda.idSacola,
                              venda.dadosSacola[0].CustoMercadoPago
                            )
                          "
                        >
                          Aplicar
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0 w-100">
                    <h5 class="my-0">Dados do cliente</h5>
                    Cliente: {{ venda.dadosSacola[0].Nome }}<br />
                    E-mail: {{ venda.dadosSacola[0].Email }}<br />
                    Telefone:
                    <span v-if="venda.dadosSacola[0].Telefone">
                      {{ venda.dadosSacola[0].Telefone }}<br />
                    </span>
                    <span v-else> ---------<br /> </span>
                    <hr v-if="$route.query.modulo != 3" />
                    <span v-if="$route.query.modulo != 3">
                      <h5 class="my-0">Dados para entrega</h5>

                      <span v-if="venda.dadosSacola[0].idEndereco != 0">
                        Destinatário:
                        <span v-if="venda.dadosSacola[0].Destinatario">
                          {{ venda.dadosSacola[0].Destinatario }}<br />
                        </span>
                        <span v-else>
                          {{ venda.dadosSacola[0].Nome }}<br />
                        </span>

                        Logradouro: {{ venda.dadosSacola[0].Logradouro }}<br />
                        Nº: {{ venda.dadosSacola[0].Numero }}<br />
                        Unidade:
                        <span v-if="venda.dadosSacola[0].Unidade">
                          {{ venda.dadosSacola[0].Unidade }}<br />
                        </span>
                        <span v-else> ---------<br /> </span>
                        Bairro: {{ venda.dadosSacola[0].Bairro }}<br />
                        Cidade: {{ venda.dadosSacola[0].Cidade }}<br />
                        Estado: {{ venda.dadosSacola[0].UF }}<br />
                        CEP:
                        <span v-if="$route.query.modulo != 5">
                          {{
                            venda.dadosSacola[0].CEP.replace(
                              /^([\d]{2})\.*([\d]{3})-*([\d]{3})/,
                              "$1.$2-$3"
                            )
                          }}
                        </span>
                        <br />
                        Complemento/Referência:
                        <span v-if="venda.dadosSacola[0].Complemento">
                          {{ venda.dadosSacola[0].Complemento }}<br />
                        </span>
                        <span v-else> ---------<br /> </span>
                      </span>
                      <span v-else>
                        <p class="mb-1">
                          Destinatário:
                          <span v-if="venda.dadosSacola[0].Destinatario">
                            {{ venda.dadosSacola[0].Destinatario }}<br />
                          </span>
                          <span v-else>
                            {{ venda.dadosSacola[0].Nome }}<br />
                          </span>
                          Retirada no local: Engenho do Mato - Niterói/RJ - Avenida Irene Lopes Sodre, 900 (Condomínio Ubá Floresta) - PORTARIA
                        </p>
                      </span>
                    </span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <button
                      v-if="$route.query.modulo == 3"
                      class="btn btn-success"
                      style="max-height: 70px"
                      @click="emailLembraSacola(venda.dadosSacola)"
                    >
                      Enviar e-mail de lembrança
                    </button>
                    <button
                      v-if="
                        $route.query.modulo == 2 &&
                          venda.dadosSacola[0].StatusPedido !=
                            'Pagamento Recebido'
                      "
                      class="btn btn-success"
                      style="max-height: 70px"
                      @click="emailLembraSacolaSemPgto(venda.dadosSacola)"
                    >
                      Enviar e-mail de lembrança de pagamento
                    </button>
                    <button
                      v-if="
                        $route.query.modulo == 2 &&
                          venda.dadosSacola[0].StatusPedido !=
                            'Pagamento Recebido'
                      "
                      class="btn btn-danger"
                      style="max-height: 70px"
                      @click="cancelarVenda(venda.idSacola)"
                    >
                      Cancelar venda sem pagamento
                    </button>
                  </div>
                </li>
                <li
                  v-if="!venda.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-center lh-condensed mx-0 pb-1"
                >
                  <h6>Esta sacola está vazia</h6>
                </li>
              </span>
            </ul>
          </div>
        </div>
      </div>

      <b-pagination
        v-if="vendas.length > perPage"
        class="d-flex justify-content-center m-0 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="vendasPaginadas"
      ></b-pagination>
    </div>
    <div v-else>
      <h5 class="p-3">
        Nenhuma sacola foi localizada com esse código
      </h5>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Button, Checkbox } from "@/components";
import AdministrativoMenu from "./AdministrativoMenu";
import { Datetime } from "vue-datetime";
import ambiente from "../../variables/variables.js";

export default {
  props: {
    filtro: Object,
    colocarNaSacola: { type: Function },
    contaProdutosSacola: Number,
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.vendas.length;
    },
  },

  watch: {
    $route() {
      this.carregarCompras();
    },
  },
  mounted() {
    if (this.$store.getters.user != null) {
      this.carregarCompras();
    }
  },
  components: {
    Loading,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    AdministrativoMenu,
    datetime: Datetime,
  },

  data() {
    return {
      paginaAtual: "compras",
      produtos: null,

      produto: {
        idProduto: Number,
        Preco: Number,
        PrecoDescontoInfanzia: Number,
        PrecoPromocional: Number,
        PrecoCategoria: Number,
      },

      perPage: 20,
      currentPage: 1,

      vendas: {},

      idProduto: "",
      idSacola: null,

      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),

      modals: {
        busca: false,
        login: false,
      },
    };
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarCompras() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 4,
            conta: this.$store.getters.user.conta,
            modulo: this.$route.query.modulo,
            idUsuario: this.$store.getters.user.id,
            idSacola: this.$route.query.idSacola,
          })
          .then((response) => {
            this.isLoading = false;

            if (response.data.produtos) {
              this.vendas = response.data.produtos;
            } else {
              this.vendas = [];
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    removerItem(idSacola, produto) {
      this.$swal({
        title: "Excluir produto",
        text:
          "Tem certeza que deseja excluir o produto '" +
          produto.NomeProduto +
          "' da sacola '" +
          idSacola +
          "'?\n\nEsta operação não poderá ser desfeita.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          try {
            axios
              .post(ambiente + "sacola.php", {
                transaction: 0,
                produto: produto,
                idUsuario: produto.Cliente,
                idSacola: idSacola,
              })
              .then(() => {
                this.isLoading = false;
                this.carregarCompras();
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
              });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
        }
      });
    },

    cancelarVenda(idSacola) {
      this.$swal({
        title: "Cancelar venda",
        text:
          "Tem certeza que deseja cancelar a venda do pedido nº " +
          idSacola +
          "?\n\nEsta operação não poderá ser desfeita.",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          try {
            axios
              .post(ambiente + "sacola.php", {
                transaction: 6,
                idSacola: idSacola,
                conta: this.$store.getters.user.conta,
                idUsuario: this.$store.getters.user.id,
              })
              .then(() => {
                this.isLoading = false;
                this.carregarCompras();
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
              });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
        }
      });
    },

    async rastreio(codigoCorreios) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "rastreio.php", {
            rastreio: codigoCorreios,
          })
          .then((response) => {
            console.log(response);
            this.$swal({
              title: "Rastreio",
              html: response.data,
              icon: "success",
              confirmButtonText: "OK",
              showLoaderOnConfirm: false,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async editaStatusPagamento(idSacola, StatusPedido, email) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "edita_venda.php", {
            transaction: 1,
            idSacola: idSacola,
            StatusPedido: StatusPedido,
            email: email,
          })
          .then((response) => {
            alert(response.data.message);
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async editaStatusEntrega(
      idSacola,
      Rastreio,
      CodigoRastreio,
      Entregue,
      dataEntrega,
      email
    ) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "edita_venda.php", {
            transaction: 2,
            idSacola: idSacola,
            Rastreio: Rastreio,
            CodigoRastreio: CodigoRastreio,
            Entregue: Entregue,
            dataEntrega: dataEntrega,
            email: email,
          })
          .then((response) => {
            alert(response.data.message);
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async emailLembraSacola(dadosSacola) {
      this.isLoading = true;

      var body =
        "<center><h2>A sua sacola está esperando por você!</h2></center><h3>Esses são os itens que estão doidinhos para irem para a sua casa:</h3><center>";

      var l = dadosSacola.length;
      for (var i = 0; i < l; i++) {
        body +=
          "<br><img style='width: 150px; height: 150px;' src='https://www.infanziabrecho.com.br/uploads/" +
          dadosSacola[i].Foto1 +
          "'><br>" +
          dadosSacola[i].NomeProduto +
          "<br>";
      }

      body +=
        "</center><h3>Corre lá para a sua sacola antes que alguém acabe comprando antes!</h3><br><hr><h3><br>Ahh! Aproveitando o contato, aqui está a nossa política de frete, caso você ainda não tenha visto:<br><br><u>Frete grátis</u></h3><p style='font-weight: bold;'>O frete é sempre grátis para:</p><ul><li>Compras de qualquer valor e com entrega nas proximidades do Engenho do Mato (Niterói/RJ);</li><li>Compras a partir R$ 250,00 e com entrega para a região Sudeste.</li></ul><h3><u>Frete promocional</u></h3><p style='font-weight: bold;'>O frete promocional R$ 19,99 é aplicável para:</p><ul><li>Compras a partir R$ 300,00 e com entrega para qualquer lugar do Brasil.</li></ul><br><br><b>Para consultar o frete, cadastre o endereço de entrega e acesse a sua sacola.<br /> Caso tenha qualquer dúvida <a href='https://api.whatsapp.com/send?phone=5521982031042&text=Olá! Tenho uma dúvida com relação ao frete.' target='_blank'>manda um Zap pra gente</a>!</b>";

      try {
        await axios
          .post(ambiente + "mail.php", {
            email: dadosSacola[0].Email,
            titulo: "Sua sacola está com saudades...",
            mensagem: body,
          })
          .then((response) => {
            this.$swal.fire({
              toast: true,
              icon: "success",
              title:
                "E-mails enviados: " +
                response.data.enviados +
                "\nErros: " +
                response.data.erros[0] +
                "\n" +
                response.data.erros[1],
              showConfirmButton: false,
              timer: 10000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async emailLembraSacolaSemPgto(dadosSacola) {
      this.isLoading = true;

      var body =
        "<center><h2>As suas compras estão esperando por você!</h2></center><h3>Você efetuou a compra, mas ainda não identificamos o pagamento/depósito.<br><br>Caso não haja a confirmação do pagamento pelos próximos 2 dias o seu pedido será cancelado.<br><br>Esses são os itens que estão doidinhos para irem para a sua casa:</h3><center>";

      var l = dadosSacola.length;
      for (var i = 0; i < l; i++) {
        body +=
          "<br><img style='width: 150px; height: 150px;' src='https://www.infanziabrecho.com.br/uploads/" +
          dadosSacola[i].Foto1 +
          "'><br>" +
          dadosSacola[i].NomeProduto +
          "<br>";
      }

      body +=
        "</center><h3>Caso já tenha efetuado o pagamento, por favor desconsidere esta mensagem.</h3><br><hr><h3><br>Ahh! Aproveitando o contato, aqui está a nossa política de frete, caso você ainda não tenha visto:<br><br><u>Frete grátis</u></h3><p style='font-weight: bold;'>O frete é sempre grátis para:</p><ul><li>Compras de qualquer valor e com entrega nas proximidades do Engenho do Mato (Niterói/RJ);</li><li>Compras a partir R$ 250,00 e com entrega para a região Sudeste.</li></ul><h3><u>Frete promocional</u></h3><p style='font-weight: bold;'>O frete promocional R$ 19,99 é aplicável para:</p><ul><li>Compras a partir R$ 300,00 e com entrega para qualquer lugar do Brasil.</li></ul><br><br><b>Para consultar o frete, cadastre o endereço de entrega e acesse a sua sacola.<br /> Caso tenha qualquer dúvida <a href='https://api.whatsapp.com/send?phone=5521982031042&text=Olá! Tenho uma dúvida com relação ao frete.' target='_blank'>manda um Zap pra gente</a>!</b>";

      try {
        await axios
          .post(ambiente + "mail.php", {
            email: dadosSacola[0].Email,
            titulo: "Seu pedido está aguardando pagamento...",
            mensagem: body,
          })
          .then((response) => {
            this.$swal.fire({
              toast: true,
              icon: "success",
              title:
                "E-mails enviados: " +
                response.data.enviados +
                "\nErros: " +
                response.data.erros[0] +
                "\n" +
                response.data.erros[1],
              showConfirmButton: false,
              timer: 10000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async editaCustoEfetivoPostagem(idSacola, CustoPostagem) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "edita_venda.php", {
            transaction: 3,
            idSacola: idSacola,
            CustoPostagem: CustoPostagem,
          })
          .then((response) => {
            alert(response.data.message);
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async editaCustoMercadoPago(idSacola, CustoMercadoPago) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "edita_venda.php", {
            transaction: 4,
            idSacola: idSacola,
            CustoMercadoPago: CustoMercadoPago,
          })
          .then((response) => {
            alert(response.data.message);
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async editaLinkPagamento(idSacola, LinkPagamento) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "edita_venda.php", {
            transaction: 5,
            idSacola: idSacola,
            LinkPagamento: LinkPagamento,
          })
          .then((response) => {
            alert(response.data.message);
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        alert(error);
        console.log(error);
      }

      this.isLoading = false;
    },

    async adicionarProduto(venda, idProduto) {
      this.isLoading = true;
      console.log(venda);
      for (var i = 0; i < venda.dadosSacola.length; i++) {
        if (venda.dadosSacola[i].idProduto == idProduto) {
          alert("Impossível adicionar produto, pois ele já está no pedido.");
          this.isLoading = false;
          return;
        }
      }

      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 5,
            idSacola: venda.dadosSacola[0].idSacola,
            idProduto: idProduto,
            idUsuario: venda.dadosSacola[0].Cliente,
            conta: this.$store.getters.user.conta,
          })
          .then((result) => {
            if (result.data.produtos == false) {
              alert(
                "Impossível adicionar produto: código inexistente ou item já vendido."
              );
            }
            this.idProduto = "";
            this.carregarCompras();
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
