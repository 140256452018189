<template>
  <div class="mt-5">
    <MainNavbar
      :currentPage="paginaAtual"
      :contaProdutosSacola="contaProdutosSacola"
      :user="user"
      :modals="modals"
    />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div
      v-if="this.$store.getters.user != null"
      class="container mt-5 pt-5"
      style="min-height: 100vh"
    >
      <h4 class="d-flex justify-content-between align-items-center mb-0">
        <span class="text-muted">Minhas Compras </span>
      </h4>

      <b-pagination
        v-if="rows > 9"
        class="d-flex justify-content-center mt-5 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="comprasPaginadas"
      ></b-pagination>

      <div
        class="container"
        id="comprasPaginadas"
        :compras="compras"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <div class="row" v-if="compras">
          <div class="col-md-12">
            <ul v-if="compras.length != 0" class="list-group mb-3">
              <span
                v-for="(compra, index) in compras.slice(
                  perPage * (currentPage - 1),
                  perPage * currentPage
                )"
                :key="index"
                class="mb-5"
              >
                <h5 v-if="compra.dadosSacola[0]" class="mt-2 mb-2">
                  Pedido nº {{ compra.idSacola }}
                </h5>

                <span
                  v-for="produto in compra.dadosSacola"
                  :key="produto.idProduto"
                >
                  <li
                    class="list-group-item d-flex justify-content-left lh-condensed mx-0 pb-1"
                  >
                    <img
                      :src="
                        'https://www.infanziabrecho.com.br/uploads/' +
                          produto.Foto1
                      "
                      class="rounded-circle mr-4"
                      style="width: 50px; height: 50px"
                    />
                    <div class="mx-0 px-0">
                      <h6 class="my-0">
                        {{ produto.NomeProduto }}
                        <font style="color: #bababa; font-size: 9px;"
                          >(Código: {{ produto.idProduto }})</font
                        >
                      </h6>
                      <p class="text-muted">
                        <span v-if="produto.PrecoDescontoInfanzia != 0">
                          <s
                            >R$
                            {{
                              produto.Preco.toFixed(2)
                                .toString()
                                .replace(".", ",")
                            }}</s
                          >

                          R$
                          {{
                            produto.PrecoDescontoInfanzia.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                        <span v-else-if="produto.PrecoPromocional != 0">
                          <s
                            >R$
                            {{
                              produto.Preco.toFixed(2)
                                .toString()
                                .replace(".", ",")
                            }}</s
                          >
                          R$
                          {{
                            produto.PrecoPromocional.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                        <span v-else-if="produto.PrecoCategoria != 0">
                          <s
                            >R$
                            {{
                              produto.Preco.toFixed(2)
                                .toString()
                                .replace(".", ",")
                            }}</s
                          >
                          R$
                          {{
                            produto.PrecoCategoria.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                        <span v-else>
                          R$
                          {{
                            produto.Preco.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </span>
                      </p>
                    </div>
                  </li>
                </span>
                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Soma dos produtos</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        parseFloat(compra.somaProdutos)
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Frete</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        compra.dadosSacola[0].Frete.toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Desconto</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        compra.dadosSacola[0].Desconto.toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h6 class="my-0">Total</h6>
                    <p class="text-muted mb-1">
                      R$
                      {{
                        compra.dadosSacola[0].Valor
                          .toString()
                          .replace(".", ",")
                      }}
                    </p>
                  </div>
                </li>

                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h5 class="my-0">Data do pedido</h5>
                    <p class="text-muted mb-1">
                      {{ compra.dadosSacola[0].DataSacola }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h5 class="my-0">Status Pagamento</h5>
                    <p class="text-muted mb-1">
                      {{ compra.dadosSacola[0].StatusPedido }}
                    </p>
                    <span
                      v-if="
                        compra.dadosSacola[0].StatusPedido ==
                          'Aguardando Pagamento' ||
                          compra.dadosSacola[0].StatusPedido ==
                            'Aguardando Depósito/Transferência'
                      "
                      class="text-muted mb-1"
                    >
                      <p v-if="compra.dadosSacola[0].UsoCredito > 0">
                        Créditos de fornecedor utilizados nesta compra: R$
                        {{
                          compra.dadosSacola[0].UsoCredito.toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                        <br />
                        Aguardando pagamento da diferença: R$
                        {{
                          (
                            parseFloat(compra.dadosSacola[0].Valor) -
                            parseFloat(compra.dadosSacola[0].UsoCredito)
                          )
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </p>

                      <a
                        v-if="
                          compra.dadosSacola[0].StatusPedido ==
                            'Aguardando Pagamento'
                        "
                        class="btn btn-success"
                        :href="compra.dadosSacola[0].LinkPagamento"
                        target="_blank"
                        >Efetuar Pagamento</a
                      >
                      <p
                        v-if="
                          compra.dadosSacola[0].StatusPedido ==
                            'Aguardando Depósito/Transferência'
                        "
                      >
                        Os dados para Depósito/Transferência foram enviados para
                        o seu e-mail.
                      </p>
                    </span>
                    <span v-else>
                      <p v-if="compra.dadosSacola[0].UsoCredito > 0">
                        Créditos de fornecedor utilizados nesta compra: R$
                        {{
                          compra.dadosSacola[0].UsoCredito.toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </p>
                    </span>
                  </div>
                </li>
                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h5 class="my-0">Status Entrega</h5>
                    <p class="text-muted mb-1">
                      {{ compra.dadosSacola[0].CodigoRastreio }}
                    </p>
                    <button
                      class="btn btn-success"
                      v-if="compra.dadosSacola[0].Rastreio != 0"
                      @click="rastreio(compra.dadosSacola[0].CodigoRastreio)"
                    >
                      Rastrear Entrega
                    </button>
                    <p
                      class="text-muted mb-1"
                      v-if="
                        compra.dadosSacola[0].DataEntrega &&
                          compra.dadosSacola[0].DataEntrega != '00/00/00'
                      "
                    >
                      Pedido entregue em {{ compra.dadosSacola[0].DataEntrega }}
                    </p>
                  </div>
                </li>
                <li
                  v-if="compra.dadosSacola[0]"
                  class="list-group-item d-flex justify-content-between lh-condensed mx-0 pb-1"
                >
                  <div class="mx-0 px-0">
                    <h5 class="my-0">Endereço de entrega selecionado</h5>

                    <p
                      v-if="compra.dadosSacola[0].idEndereco != 0"
                      class="text-muted mb-1"
                    >
                      Logradouro: {{ compra.dadosSacola[0].Logradouro }}<br />
                      Nº: {{ compra.dadosSacola[0].Numero }}<br />
                      Unidade:
                      <span v-if="compra.dadosSacola[0].Unidade">
                        {{ compra.dadosSacola[0].Unidade }}<br />
                      </span>
                      <span v-else> ----<br /> </span>
                      Bairro: {{ compra.dadosSacola[0].Bairro }}<br />
                      Cidade: {{ compra.dadosSacola[0].Cidade }}<br />
                      Estado: {{ compra.dadosSacola[0].UF }}<br />
                      CEP:
                      {{
                        compra.dadosSacola[0].CEP.replace(
                          /^([\d]{2})\.*([\d]{3})-*([\d]{3})/,
                          "$1.$2-$3"
                        )
                      }}<br />
                      Complemento/Referência:
                      <span v-if="compra.dadosSacola[0].Complemento">
                        {{ compra.dadosSacola[0].Complemento }}<br />
                      </span>
                      <span v-else> ----<br /> </span>
                    </p>
                    <p v-else class="text-muted mb-1">
                      Retirada no local:
                      <br />
                      <b
                        >Engenho do Mato - Niterói/RJ - Avenida Irene Lopes Sodre, 900 (Condomínio Ubá Floresta) - PORTARIA</b
                      >
                    </p>
                  </div>
                </li>
              </span>
            </ul>
            <ul v-else class="list-group my-5">
              <p>
                Você ainda não realizou nenhuma compra conosco...
                <i class="far fa-frown m-0 p-0"></i>
              </p>
            </ul>
          </div>
        </div>
      </div>

      <b-pagination
        v-if="rows > 9"
        class="d-flex justify-content-center mb-5 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="comprasPaginadas"
      ></b-pagination>
    </div>
    <div v-else style="min-height: 100vh" class="mt-5 pt-5">
      <h4 style="text-align: center" class="mt-5 pt-5">
        É preciso estar logado(a) para acessar as suas compras.
      </h4>
    </div>

    <!-- INICIO MODAL LOGIN -->
    <modal
      :show.sync="modals.login"
      :show-close="false"
      headerClasses="p-0 m-0"
      style="z-index: 9999"
    >
      <n-button
        type="primary p-2 m-0"
        round
        icon
        simple
        style="width: 50px; height: 50px"
        @click.native="modals.login = false"
        ><i class="fas fa-times"></i
      ></n-button>
      <Login />
    </modal>
    <!-- FIM MODAL LOGIN -->

    <MainFooter />
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";
import Login from "./admin/Login";
import { Modal, Button, Checkbox } from "@/components";
import ambiente from "../variables/variables.js"

export default {
  props: {
    filtro: Object,
    colocarNaSacola: { type: Function },
    contaProdutosSacola: Number,
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      if (this.compras) {
        return this.compras.length;
      } else {
        return 0;
      }
    },
  },

  watch: {
    $route() {
      this.carregarCompras();
    },
  },
  mounted() {
    if (!this.$store.getters.user) {
      this.$gtag.event("Visualização - Compras Feitas");
    } else if (this.$store.getters.user.conta != 1) {
      this.$gtag.event("Visualização - Compras Feitas");
    }

    if (this.$store.getters.user != null) {
      this.carregarCompras();
    }
  },
  components: {
    Loading,
    MainNavbar,
    MainFooter,
    Modal,
    Login,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },

  data() {
    return {
      paginaAtual: "compras",
      produtos: null,

      produto: {
        Preco: Number,
        PrecoDescontoInfanzia: Number,
        PrecoPromocional: Number,
        PrecoCategoria: Number,
      },

      compras: null,

      perPage: 10,
      currentPage: 1,

      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),

      modals: {
        busca: false,
        login: false,
      },
    };
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarCompras() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 3,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            if (response.data.produtos) {
              this.compras = response.data.produtos;
            } else {
              this.compras = [];
            }

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }

      this.isLoading = false;
    },
    async rastreio(codigoCorreios) {

      this.isLoading = true;
      this.$swal({
        title: "Rastreio",
        html:
          "Você será redirecionado(a) para o site dos Correios para efetuar o rastreio da encomenda.<br><br>Código de rastreio: <b>"+codigoCorreios+"</b><br><br>Dica: copie o código de rastreio para colar ele lá no site dos correios.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Rastrear",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          window.open("https://rastreamento.correios.com.br/app/index.php");
        }
      });


      // A API DOS CORREIOS PARA CONSULTA FOI ALTERADA
      // O CÓDIGO FOI MANTIDO AQUI PARA REFERENCIA E FUTURAS IMPLEMENTAÇÕES


      // try {
      //   await axios
      //     .post(ambiente + "rastreio.php", {
      //       rastreio: codigoCorreios,
      //     })
      //     .then((response) => {
      //       console.log(response);
      //       this.$swal({
      //         title: "Rastreio",
      //         html: response.data,
      //         icon: "success",
      //         confirmButtonText: "OK",
      //         showLoaderOnConfirm: false,
      //       });
      //     })
      //     .catch((error) => {
      //       this.isLoading = false;
      //       alert(error);
      //       console.log(error);
      //     });
      // } catch (error) {
      //   this.isLoading = false;
      //   alert(error);
      //   console.log(error);
      // }

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
