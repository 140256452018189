<template>
  <div class="mt-5 pt-4" style="min-height: 100vh">
    <MainNavbar :user="user" :currentPage="paginaAtual" />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <b-pagination
      v-if="rows > 24"
      class="d-flex justify-content-center mt-3"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="produtosPaginados"
    ></b-pagination>

    <div
      style="min-height: 100vh"
      class="container"
      v-if="this.favoritos.length == 0"
    >
      <h4 style="text-align: center" class="mt-5 pt-5">
        Você ainda não possui nenhum produto favorito.
      </h4>
      <p class="text-muted" style="text-align: center">
        Colecione seus itens favoritos clicando no botão
        <i class="far fa-heart"></i>
      </p>
    </div>
    <div
      style="min-height: 100vh"
      id="produtosPaginados"
      :favoritos="favoritos"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <center>
        <div v-if="this.favoritos.length != 0" class="">
          <h4>Meus Favoritos</h4>
          <div class="row justify-content-center">
            <div
              class="mx-2 px-2 my-2 py-2 col-auto"
              v-for="produto in favoritos.slice(
                perPage * (currentPage - 1),
                perPage * currentPage
              )"
              :key="produto.idProduto"
            >
              <router-link
                :to="`/produto/detalhe?produto=${produto.idProduto}`"
              >
                <img
                  class="rounded-circle"
                  style="height: 100px; width: 100px"
                  :src="
                    'https://www.infanziabrecho.com.br/uploads/' + produto.Foto1
                  "
                />
                <p style="font-size: 12px;">{{ produto.NomeProduto }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </center>
    </div>
    <b-pagination
      v-if="rows > 12"
      class="d-flex justify-content-center m-0 p-0"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="produtosPaginados"
    ></b-pagination>

    <MainFooter />
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.favoritos.length;
    },
  },

  async mounted() {
    if (!this.$store.getters.user) {
      this.$gtag.event("Visualização - Favoritos");
    } else if (this.$store.getters.user.conta != 1) {
      this.$gtag.event("Visualização - Favoritos");
    }

    if (this.$store.getters.user != null) {
      try {
        await this.carregarFavoritos();
      } catch (err) {
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    }
  },

  components: {
    Loading,
    MainNavbar,
    MainFooter,
  },

  data() {
    return {
      paginaAtual: "favoritos",

      favoritos: [],

      perPage: 24,
      currentPage: 1,
      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),
      // rows: null,
    };
  },
  watch: {
    filtro: {
      handler: function() {
        this.carregarTodosProdutos(this.filtro);
      },
      deep: true,
    },
    $route() {
      this.carregarFavoritos();
      this.cepEntrega = "";
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarFavoritos() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "favoritos.php", {
            transaction: 2,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.favoritos = response.data.favoritos;

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    confereFavorito(idProduto) {
      if (!this.favoritos) {
        return true;
      } else {
        return (
          this.favoritos.filter((obj) => obj.idProduto == idProduto).length == 0
        );
      }
    },
  },
};
</script>
<style scoped>
/* @import "../../assets/css/simple-sugest-autocomplete.css"; */

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
