<template>
  <div class="container">
    <center>
      <b-dropdown variant="primary" class="mx-1" left text="Produtos">
        <b-dropdown-item :to="`/administrativo/cadastrar-produto`"
          >Cadastrar produto</b-dropdown-item
        >
        <b-dropdown-item :to="`/administrativo/exibir-produtos`"
          >Exibir produtos</b-dropdown-item
        >
      </b-dropdown>

      <b-dropdown variant="primary" class="mx-1" left text="Vendas">
        <b-dropdown-item :to="`/administrativo/vendas?modulo=3`"
          >Vendas sem checkout</b-dropdown-item
        >
        <b-dropdown-item :to="`/administrativo/vendas?modulo=2`"
          >Vendas em andamento</b-dropdown-item
        >
        <b-dropdown-item :to="`/administrativo/vendas?modulo=1`"
          >Vendas em prazo de troca</b-dropdown-item
        >
        <b-dropdown-item :to="`/administrativo/vendas?modulo=0`"
          >Vendas concluídas</b-dropdown-item
        >
        <b-dropdown-item @click="busca = true">Buscar</b-dropdown-item>
      </b-dropdown>

      <b-dropdown variant="primary" class="mx-1" left text="Fornecedores">
        <b-dropdown-item :to="`/administrativo/exibir-fornecedores`"
          >Exibir fornecedores</b-dropdown-item
        >
        <b-dropdown-item :to="`/administrativo/pedidos-resgate`"
          >Pedidos de resgate</b-dropdown-item
        >
        <b-dropdown-item :to="`/administrativo/creditar-vendas`"
          >Creditar vendas</b-dropdown-item
        >
      </b-dropdown>

      <b-dropdown variant="primary" class="mx-1" left text="Clientes">
        <b-dropdown-item :to="`/administrativo/exibir-clientes`">Exibir clientes</b-dropdown-item>
      </b-dropdown>

      <b-dropdown variant="primary" class="mx-1" left text="Descontos">
        <b-dropdown-item :to="`/administrativo/descontos`"
          >Gerenciar descontos</b-dropdown-item
        >
      </b-dropdown>

      <b-dropdown variant="primary" class="mx-1" left text="E-mail">
        <b-dropdown-item :to="`/administrativo/email`"
          >Enviar e-mail</b-dropdown-item
        >
      </b-dropdown>

      <b-dropdown
        variant="primary"
        class="mx-1"
        left
        text="Business Intelligence"
      >
        <b-dropdown-item :to="`/administrativo/bi-vendas`"
          >Vendas</b-dropdown-item
        >
        <b-dropdown-item :to="`/administrativo/bi-produtos`"
          >Produtos</b-dropdown-item
        >
        <b-dropdown-item>Clientes</b-dropdown-item>
        <b-dropdown-item>Fornecedores</b-dropdown-item>
      </b-dropdown>
    </center>

    <modal
      :show.sync="busca"
      :show-close="false"
      headerClasses="justify-content-center"
      style="z-index: 99999"
    >
      <p>Buscar sacola por id:</p>

      <input
        id="nome"
        type="text"
        class="form-control m-1"
        v-model="idSacola"
        placeholder="Informe o id da sacola"
        autocomplete="off"
      />

      <center>
        <n-button type="secondary" @click.native="busca = false"
          >Cancelar</n-button
        >
        <n-button type="primary" @click.native="buscar(idSacola)"
          >Buscar</n-button
        >
      </center>
    </modal>
  </div>
</template>
<script>
import { Modal, Button } from "@/components";

export default {
  components: {
    Modal,
    [Button.name]: Button,
  },
  data() {
    return {
      busca: false,
      idSacola: "",
    };
  },
  methods: {
    buscar(idSacola) {
      console.log(idSacola)
      window.location.href="/administrativo/vendas?modulo=5&idSacola="+idSacola

    },
  },
};
</script>
