<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3">
      <h5>Detalhes do fornecedor</h5>

      <form @submit.prevent="editaDadosFornecedor()">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="cardGrande p-3 mb-0 mt-3">
                <h6 class="mt-0">Resumo de produtos</h6>
                <p class="mb-2 p-0">
                  Total:
                  <font style="color: #808080">{{ produtos.length }}</font>
                </p>
                <p
                  class="m-0 p-0"
                  style="font-size: 14px; color: green; font-weight: bold;"
                >
                  Anunciados: {{ anunciados
                  }}<font style="color: #808080"></font>
                </p>
                <p
                  class="m-0 p-0"
                  style="font-size: 14px; color: orange; font-weight: bold;"
                >
                  Em sacola: {{ emSacola }} <font style="color: #808080"></font>
                </p>
                <p
                  class="m-0 p-0"
                  style="font-size: 14px; color: blue; font-weight: bold;"
                >
                  Vendidos: {{ vendidos }} <font style="color: #808080"></font>
                </p>
                <p
                  class="m-0 p-0"
                  style="font-size: 14px; color: red; font-weight: bold;"
                >
                  Pendentes: {{ pendentes }}
                  <font style="color: #808080"></font>
                </p>
                <p
                  class="m-0 p-0"
                  style="font-size: 14px; color: gray; font-weight: bold;"
                >
                  Devolução: {{ devolucao }}
                  <font style="color: #808080"></font>
                </p>
              </div>
              <div class="cardGrande p-3 mb-0 mt-3">
                <h6>Dados pessoais</h6>

                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Código:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  readonly
                  v-model="fornecedor.idFornecedor"
                  placeholder="Código"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Nome:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.NomeFornecedor"
                  placeholder="Nome"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Telefone:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Telefone"
                  placeholder="Telefone"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  E-mail:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Email"
                  placeholder="E-mail"
                />
                <button
                  type="submit"
                  class="btn btn-success btn-sm float-right"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="cardGrande p-3 mb-0 mt-3">
                <h6>Endereço</h6>

                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  CEP:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.CEP"
                  placeholder="CEP"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Logradouro:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Logradouro"
                  placeholder="Logradouro"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Número:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Numero"
                  placeholder="Número"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Unidade:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Unidade"
                  placeholder="Unidade"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Complemento:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Complemento"
                  placeholder="Complementos"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Bairro:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Bairro"
                  placeholder="Bairro"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Cidade:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Cidade"
                  placeholder="Cidade"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Estado:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.UF"
                  placeholder="Estado"
                />
                <button
                  type="submit"
                  class="btn btn-success btn-sm float-right"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="cardGrande p-3 mb-0 mt-3">
                <h6 class="mt-0">Dados bancários</h6>
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Banco:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Banco"
                  placeholder="Banco"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Agência:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Agencia"
                  placeholder="Agencia"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Conta:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.Conta"
                  placeholder="Conta"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  CPF:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  required
                  v-model="fornecedor.CPF"
                  placeholder="CPF"
                />
                <p
                  class="cardGrande-text mb-0 mt-2"
                  style="font-size: 12px; color: #555"
                >
                  Obs.:
                </p>
                <input
                  autocomplete="off"
                  type="text"
                  class="form-control mb-1"
                  v-model="fornecedor.Obs"
                  placeholder="Observações"
                />
                <button
                  type="submit"
                  class="btn btn-success btn-sm float-right"
                >
                  Salvar alterações
                </button>
              </div>

              <div class="cardGrande p-1 mb-0 mt-3">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6 d-flex justify-content-left m-0 p-0">
                      <h6 class="mt-0 p-2">
                        Extrato
                      </h6>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end m-0 p-0">
                      <a
                        class="btn btn-primary btn-sm float-right px-4 mt-0"
                        @click="imprimir('extratoParaImprimir')"
                        ><i class="fas fa-print"></i
                      ></a>
                    </div>
                  </div>
                </div>

                <div
                  id="extrato"
                  class="m-0 p-0"
                  style="overflow-y: auto; max-height: 330px;"
                >
                  <table
                    class="table table-striped"
                    v-if="this.extrato.length != null"
                    id="extratoParaImprimir"
                  >
                    <thead>
                      <tr>
                        <th
                          class="px-0 py-1 text-center"
                          style="position: sticky; top: 0; background-color: white; border: 1px;"
                        >
                          <font style="font-size: 10px;">Data</font>
                        </th>
                        <th
                          class="px-0 py-1 text-center"
                          style="position: sticky; top: 0; background-color: white; border: 1px;"
                        >
                          <font style="font-size: 10px;">Descrição</font>
                        </th>
                        <th
                          class="px-0 py-1 text-center"
                          style="position: sticky; top: 0; background-color: white; border: 1px;"
                        >
                          <font style="font-size: 10px;">Produto</font>
                        </th>
                        <th
                          class="px-0 py-1 text-center"
                          style="position: sticky; top: 0; background-color: white; border: 1px;"
                        >
                          <font style="font-size: 10px;">Valor</font>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in extrato"
                        :key="item.idFornecedoresCreditos"
                      >
                        <td class="text-center">
                          <font style="font-size: 10px;">
                            {{ item.Data }}
                          </font>
                        </td>
                        <td v-if="item.TipoCredito == 1" class="text-center">
                          <font style="color: green; font-size: 10px;"
                            >acumulado</font
                          >
                        </td>
                        <td
                          v-else-if="item.TipoCredito == 2"
                          class="text-center"
                        >
                          <font style="color: red; font-size: 10px;"
                            >utilizado</font
                          >
                        </td>
                        <td
                          v-else-if="item.TipoCredito == 3"
                          class="text-center"
                        >
                          <font style="color: orange; font-size: 10px;"
                            >resgatado</font
                          >
                        </td>
                        <td v-if="item.TipoCredito == 1" class="text-center">
                          <font style="font-size: 10px;">
                            <router-link
                              :to="
                                `/administrativo/produto/detalhe?produto=${item.idProduto}`
                              "
                            >
                              {{ item.idProduto }}
                            </router-link>
                          </font>
                        </td>
                        <td v-else class="text-center">
                          ---
                        </td>
                        <td class="text-right">
                          <font style="font-size: 10px;">
                            {{
                              item.Credito.toFixed(2)
                                .toString()
                                .replace(".", ",")
                            }}
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">Saldo</td>
                        <td v-if="fornecedor.Credito">
                          {{
                            fornecedor.Credito.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <h5 class="mt-4">
        Produtos do fornecedor
        <font style="color: #BEBEBE">
          (<span v-if="filtro.status == 5">todos:</span>
          <span v-if="filtro.status == 4">devolução:</span>
          <span v-if="filtro.status == 3">pendentes:</span>
          <span v-if="filtro.status == 2">vendidos:</span>
          <span v-if="filtro.status == 1">em sacola:</span>
          <span v-if="filtro.status == 0">anunciados:</span>
          {{ produtosFiltrados.length }})</font
        >
      </h5>

      <div class="container">
        <div class="row">
          <div class="col-md-4 d-flex justify-content-center m-0 p-0">
            <label
              >Buscar
              <input type="text" v-model="filtro.busca" class="mt-2 mr-1" />
            </label>
          </div>
          <div class="col-md-4 d-flex justify-content-center m-0 p-0">
            <label
              >Filtrar
              <select v-model="filtro.status" class="mt-2 mr-1">
                <option value="5" selected>todos</option>
                <option value="0">anunciados</option>
                <option value="1">em sacola</option>
                <option value="2">vendidos</option>
                <option value="3">pendentes</option>
                <option value="4">devolução</option>
              </select>
            </label>
          </div>
          <div class="col-md-4 d-flex justify-content-center m-0 p-0">
            <a
              class="btn btn-primary btn-sm float-right px-4"
              @click="imprimir('produtosPaginados')"
              ><i class="fas fa-print"></i
            ></a>
          </div>
        </div>
      </div>

      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center mt-4"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="produtosFiltrados"
      ></b-pagination>

      <div
        id="produtosFiltrados"
        :produtosFiltrados="produtosFiltrados"
        :per-page="perPage"
        :current-page="currentPage"
        class="table-responsive"
      >
        <center>
          <table
            class="table table-striped"
            id="produtosPaginados"
            v-if="this.produtosFiltrados.length != null"
          >
            <thead>
              <tr>
                <th>Foto</th>
                <th>Código</th>
                <th>Produto</th>
                <th>Preço</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="produto in produtosFiltrados.slice(
                  perPage * (currentPage - 1),
                  perPage * currentPage
                )"
                :key="produto.idProduto"
              >
                <td>
                  <img
                    style="width: 80px"
                    :src="
                      'https://www.infanziabrecho.com.br/uploads/' +
                        produto.Foto1
                    "
                    class="rounded-circle img-raised"
                  />
                </td>
                <td>{{ produto.idProduto }}</td>
                <td>
                  <router-link
                    :to="
                      `/administrativo/produto/detalhe?produto=${produto.idProduto}`
                    "
                  >
                    {{ produto.NomeProduto }}
                  </router-link>
                  <p class="m-0 p-0" style="font-size: 12px">
                    Marca: {{ produto.Marca }}
                  </p>
                  <p class="m-0 p-0" style="font-size: 12px">
                    Descrição: {{ produto.Descricao }}
                  </p>
                  <p class="m-0 p-0" style="font-size: 12px">
                    {{ produto.Tamanho }}
                  </p>
                  <p
                    class="m-0 p-0"
                    style="font-size: 12px"
                    v-if="
                      produto.Menino == 1 &&
                        (produto.Menina == 0 || !produto.Menina)
                    "
                  >
                    Gênero: masculino
                  </p>
                  <p
                    class="m-0 p-0"
                    style="font-size: 12px"
                    v-if="
                      (produto.Menino == 0 || !produto.Menino) &&
                        produto.Menina == 1
                    "
                  >
                    Gênero: feminino
                  </p>
                  <p
                    class="m-0 p-0"
                    style="font-size: 12px"
                    v-if="produto.Menino == 1 && produto.Menina == 1"
                  >
                    Gênero: unisex
                  </p>
                  <p
                    class="m-0 p-0"
                    style="font-size: 12px"
                    v-if="produto.Condicao == 1"
                  >
                    Condição: Wow! Ainda com etiqueta! Que tal presentear
                    alguém?
                  </p>
                  <p
                    class="m-0 p-0"
                    style="font-size: 12px"
                    v-if="produto.Condicao == 2"
                  >
                    Condição: Como novo! Nunca usado ou pouquíssimo uso.
                  </p>
                  <p
                    class="m-0 p-0"
                    style="font-size: 12px"
                    v-if="produto.Condicao == 3"
                  >
                    Condição: Super conservado! Pouquíssimas marcas de uso.
                  </p>
                  <p
                    class="m-0 p-0"
                    style="font-size: 12px"
                    v-if="produto.Condicao == 4"
                  >
                    Condição: Conservado, possui marcas de uso.
                  </p>
                </td>
                <td>
                  <p v-if="produto.PrecoDescontoInfanzia != 0">
                    <s>
                      {{
                        produto.Preco.toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}</s
                    >

                    {{
                      produto.PrecoDescontoInfanzia.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    <br />
                    ({{
                      Math.ceil(
                        100 -
                          (produto.PrecoDescontoInfanzia * 100) / produto.Preco
                      )
                    }}%)
                  </p>
                  <p v-else-if="produto.PrecoPromocional != 0">
                    <s>
                      {{
                        produto.Preco.toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}</s
                    >
                    <br />
                    {{
                      produto.PrecoPromocional.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    <br />
                    ({{
                      Math.ceil(
                        100 - (produto.PrecoPromocional * 100) / produto.Preco
                      )
                    }}%)
                  </p>
                  <p v-else-if="produto.PrecoCategoria != 0">
                    <s>{{
                      produto.Preco.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}</s>
                    <br />
                    {{
                      produto.PrecoCategoria.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                    <br />
                    ({{
                      Math.ceil(
                        100 - (produto.PrecoCategoria * 100) / produto.Preco
                      )
                    }}%)
                  </p>
                  <p v-else>
                    {{
                      produto.Preco.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                  </p>
                </td>
                <td>
                  <p
                    class="mt-1"
                    style="font-size: 14px; color: green;"
                    v-if="produto.Status == 0"
                  >
                    Disponivel
                  </p>
                  <p
                    class="mt-1"
                    style="font-size: 14px; color: orange;"
                    v-if="produto.Status == 1"
                  >
                    Sacola
                    <br><a :href="'/administrativo/vendas?modulo=5&idSacola='+produto.idSacola">Ver sacola</a>
                  </p>
                  <p
                    class="mt-1"
                    style="font-size: 14px; color: blue;"
                    v-if="produto.Status == 2 && produto.Credito == 1"
                  >
                    Vendido
                    <br><a :href="'/administrativo/vendas?modulo=5&idSacola='+produto.idSacola">Ver sacola</a>
                  </p>
                  <p
                    class="mt-1"
                    style="font-size: 14px; color: purple;"
                    v-if="produto.Status == 2 && produto.Credito == 0"
                  >
                    Processando Venda
                    <br><a :href="'/administrativo/vendas?modulo=5&idSacola='+produto.idSacola">Ver sacola</a>
                  </p>
                  <p
                    class="mt-1"
                    style="font-size: 14px; color: red;"
                    v-if="produto.Status == 3"
                  >
                    Pendente
                  </p>
                  <p
                    class="mt-1"
                    style="font-size: 14px; color: gray;"
                    v-if="produto.Status == 4"
                  >
                    Devolução
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </center>
      </div>

      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="produtosPaginados"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Button, FormGroupInput } from "@/components";
import * as axios from "axios";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.produtosFiltrados.length;
    },
  },
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    AdministrativoMenu,
    Loading,
  },

  data() {
    return {
      isLoading: false,
      onCancel: this.cancelLoading(),
      fullPage: true,

      perPage: 1000,
      currentPage: 1,

      produtos: [],
      produtosFiltrados: [],
      fornecedor: Object,
      extrato: [],

      anunciados: 0,
      emSacola: 0,
      vendidos: 0,
      pendentes: 0,
      devolucao: 0,

      filtro: {
        status: 5,
        busca: "",
      },
    };
  },

  async mounted() {
    if (this.$store.getters.user.conta == 1) {
      try {
        await this.carregarFornecedor(this.$route.query.fornecedor);
      } catch (err) {
        console.log(err);
      }
      try {
        await this.carregarProdutosFornecedor(this.$route.query.fornecedor);
      } catch (err) {
        console.log(err);
      }
    }
  },

  watch: {
    "$route.query.fornecedor": {
      handler: function() {
        this.carregarFornecedor(this.$route.query.fornecedor);
        this.carregarProdutosFornecedor(this.$route.query.fornecedor);
      },
      deep: true,
      immediate: true,
    },

    filtro: {
      handler: function() {
        this.filtrarProdutos();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarFornecedor(idFornecedor) {
      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            idFornecedor: idFornecedor,
            idUsuario: this.$store.getters.user.id,
            transaction: 6,
          })
          .then((response) => {
            this.fornecedor = response.data[0];
            this.carregarExtratoFornecedor(idFornecedor);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    },

    async carregarProdutosFornecedor(idFornecedor) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            idFornecedor: idFornecedor,
            idUsuario: this.$store.getters.user.id,
            transaction: 7,
          })
          .then((response) => {
            this.produtos = response.data;

            this.anunciados = 0;
            this.emSacola = 0;
            this.vendidos = 0;
            this.pendentes = 0;
            this.devolucao = 0;

            for (var i = 0; i < this.produtos.length; i++) {
              if (this.produtos[i].Status === 0) {
                this.anunciados += 1;
              } else if (this.produtos[i].Status === 1) {
                this.emSacola += 1;
              } else if (this.produtos[i].Status === 2) {
                this.vendidos += 1;
              } else if (this.produtos[i].Status === 3) {
                this.pendentes += 1;
              } else if (this.produtos[i].Status === 4) {
                this.devolucao += 1;
              }
            }

            this.filtrarProdutos();

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },

    filtrarProdutos() {
      this.produtosFiltrados = [];

      for (var i = 0; i < this.produtos.length; i++) {
        if (
          (this.produtos[i].NomeProduto.toLowerCase().includes(
            this.filtro.busca
          ) ||
            this.produtos[i].idProduto == this.filtro.busca) &&
          this.filtro.status == 0 &&
          this.produtos[i].Status === 0
        ) {
          this.produtosFiltrados.push(this.produtos[i]);
        } else if (
          (this.produtos[i].NomeProduto.toLowerCase().includes(
            this.filtro.busca
          ) ||
            this.produtos[i].idProduto == this.filtro.busca) &&
          this.filtro.status == 1 &&
          this.produtos[i].Status === 1
        ) {
          this.produtosFiltrados.push(this.produtos[i]);
        } else if (
          (this.produtos[i].NomeProduto.toLowerCase().includes(
            this.filtro.busca
          ) ||
            this.produtos[i].idProduto == this.filtro.busca) &&
          this.filtro.status == 2 &&
          this.produtos[i].Status === 2
        ) {
          this.produtosFiltrados.push(this.produtos[i]);
        } else if (
          (this.produtos[i].NomeProduto.toLowerCase().includes(
            this.filtro.busca
          ) ||
            this.produtos[i].idProduto == this.filtro.busca) &&
          this.filtro.status == 3 &&
          this.produtos[i].Status === 3
        ) {
          this.produtosFiltrados.push(this.produtos[i]);
        } else if (
          (this.produtos[i].NomeProduto.toLowerCase().includes(
            this.filtro.busca
          ) ||
            this.produtos[i].idProduto == this.filtro.busca) &&
          this.filtro.status == 4 &&
          this.produtos[i].Status === 4
        ) {
          this.produtosFiltrados.push(this.produtos[i]);
        } else if (
          (this.produtos[i].NomeProduto.toLowerCase().includes(
            this.filtro.busca
          ) ||
            this.produtos[i].idProduto == this.filtro.busca) &&
          this.filtro.status == 5
        ) {
          this.produtosFiltrados.push(this.produtos[i]);
        }
      }
    },

    async editaDadosFornecedor() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            idUsuario: this.$store.getters.user.id,
            idFornecedor: this.fornecedor.idFornecedor,
            //dados pessoais
            nome: this.fornecedor.NomeFornecedor,
            telefone: this.fornecedor.Telefone,
            email: this.fornecedor.Email,
            cep: this.fornecedor.CEP,
            logradouro: this.fornecedor.Logradouro,
            numero: this.fornecedor.Numero,
            unidade: this.fornecedor.Unidade,
            complemento: this.fornecedor.Complemento,
            bairro: this.fornecedor.Bairro,
            cidade: this.fornecedor.Cidade,
            estado: this.fornecedor.UF,
            //dados bancarios
            banco: this.fornecedor.Banco,
            agencia: this.fornecedor.Agencia,
            conta: this.fornecedor.Conta,
            cpf: this.fornecedor.CPF,
            obs: this.fornecedor.Obs,
            transaction: 8,
          })
          .then(() => {
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Dados do fornecedor alterados com sucesso!",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },

    async carregarExtratoFornecedor(idFornecedor) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            idFornecedor: idFornecedor,
            idUsuario: this.$store.getters.user.id,
            transaction: 9,
          })
          .then((response) => {
            this.extrato = response.data;
            this.isLoading = false;

            // inicia o extrato com a barra de rolagem no final
            var container = this.$el.querySelector("#extrato");
            container.scrollTop = container.scrollHeight;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },

    imprimir(tabela) {
      var divToPrint = this.$el.querySelector("#" + tabela);
      var newWin = window.open("");
      newWin.document.write(
        '<span style="font-family: Verdana;"><center>' +
          divToPrint.outerHTML +
          "</center></span>"
      );
      newWin.print();
      newWin.close();
    },
  },
};
</script>
<style scoped></style>
