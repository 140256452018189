<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3">
      <form v-on:submit.prevent="enviarEmail()">
        <h5>Enviar e-mail</h5>
        <br />
        <div class="border p-3">
          <div
            id="fornecedores"
            class="m-0 p-0"
            style="overflow-y: auto; max-height: 300px;"
          >
            <h6>Fornecedores</h6>

            <select
              multiple
              class="form-control mt-1 select-multiple"
              v-model="fornecedoresSelecionados.Email"
              style="border-radius: 20px"
            >
              <option
                v-for="(value, key, index) in fornecedores"
                :key="index"
                :value="value.Email"
                >{{ value.idFornecedor }} | {{ value.NomeFornecedor }} |
                {{ value.Email }}</option
              >
            </select>
            <span v-if="fornecedoresSelecionados.Email">
              <span v-if="fornecedoresSelecionados.Email.length > 0">
                Fornecedores selecionados:
                {{ fornecedoresSelecionados.Email.length }}
              </span>
            </span>
          </div>
          <label class="mt-3 mr-3 d-flex justify-content-end"
            ><input
              type="checkbox"
              name="checkboxFornecedores"
              v-model="checkboxFornecedores"
              @click="selecionarTodos('fornecedores')"
              class="mr-1 mt-1"
            />
            <b>Selecionar todos os fornecedores</b></label
          >
        </div>

        <br />

        <div class="border p-3">
          <div
            id="clientes"
            class="m-0 p-0"
            style="overflow-y: auto; max-height: 300px;"
          >
            <h6>Clientes</h6>

            <select
              multiple
              class="form-control mt-1 select-multiple"
              v-model="clientesSelecionados.Email"
              style="border-radius: 20px"
            >
              <option
                v-for="(value, key, index) in clientes"
                :key="index"
                :value="value.Email"
                >{{ value.idUsuario }} | {{ value.Nome }} |
                {{ value.Email }}</option
              >
            </select>
            <span v-if="clientesSelecionados.Email">
              <span v-if="clientesSelecionados.Email.length > 0">
                Clientes selecionados:
                {{ clientesSelecionados.Email.length }}
              </span>
            </span>
          </div>
          <label class="mt-3 mr-3 d-flex justify-content-end"
            ><input
              type="checkbox"
              name="checkboxClientes"
              v-model="checkboxClientes"
              @click="selecionarTodos('clientes')"
              class="mr-1 mt-1"
            />
            <b>Selecionar todos os clientes</b></label
          >
        </div>

        <br />

        <div class="border p-3">
          <h6>Título</h6>
          <input
            required
            tupe="text"
            v-model="titulo"
            class="form-control"
            placeholder="Digite o título do e-mail"
          />
          <br />
          <h6>Texto</h6>

          <wysiwyg style="min-height: 300px" v-model="texto" />
          
        </div>
        <span class=" d-flex justify-content-end">
          <button class="btn btn-success my-5 mr-3" type="submit">
            Enviar e-mail
          </button>
        </span>
      </form>
    </div>

  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.fornecedores.length;
    },
  },

  mounted() {
    this.carregarFornecedores();
    this.carregarClientes();
  },
  components: {
    Loading,
    AdministrativoMenu,
  },

  data() {
    return {
      isLoading: false,

      fornecedores: [],
      clientes: [],
      fornecedoresSelecionados: {
        Email: [String],
      },
      clientesSelecionados: {
        Email: [String],
      },

      titulo: "",
      texto: "",

      checkboxFornecedores: false,
      checkboxClientes: false,

      fullPage: true,
      onCancel: this.cancelLoading(),
      // rows: null,
    };
  },
  watch: {
    $route() {
      this.carregarFornecedores();
      this.carregarClientes();
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    selecionarTodos(transaction) {
      var i = 0;

      if (transaction == "fornecedores") {
        if (this.checkboxFornecedores == false) {
          this.checkboxFornecedores = true;

          for (i = 0; i < this.fornecedores.length; i++) {
            this.fornecedoresSelecionados.Email.push(
              this.fornecedores[i].Email
            );
          }
        } else {
          this.checkboxFornecedores = false;
          this.fornecedoresSelecionados.Email = [];
        }
      } else if (transaction == "clientes") {
        if (this.checkboxClientes == false) {
          this.checkboxClientes = true;

          for (i = 0; i < this.clientes.length; i++) {
            this.clientesSelecionados.Email.push(this.clientes[i].Email);
          }
        } else {
          this.checkboxClientes = false;
          this.clientesSelecionados.Email = [];
        }
      }
    },

    async carregarFornecedores() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 5,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.fornecedores = response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async carregarClientes() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "usuario.php", {
            transaction: 1,
            conta: this.$store.getters.user.conta,
          })
          .then((response) => {
            this.clientes = response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async enviarEmail() {
      this.isLoading = true;
      this.$gtag.event("Admin - Email Enviado");
      const emails = this.fornecedoresSelecionados.Email.concat(
        this.clientesSelecionados.Email
      );
      if (emails.length > 0) {
        try {
          await axios
            .post(ambiente + "mail.php", {
              email: emails,
              titulo: this.titulo,
              mensagem: this.texto,
            })
            .then((response) => {
              this.fornecedoresSelecionados.Email = [];
              this.clientesSelecionados.Email = [];

              this.$swal.fire({
                toast: true,
                icon: "success",
                title:
                  "E-mails enviados: " +
                  response.data.enviados +
                  "\nErros: " +
                  response.data.erros[0] +
                  "\n" +
                  response.data.erros[1],
                showConfirmButton: false,
                timer: 10000,
                timerProgressBar: true,
              });
            })
            .catch((error) => {
              this.isLoading = false;
              alert("Ops, ocorreu um erro, tente novamente.");
              console.log(error);
            });
        } catch (err) {
          this.isLoading = false;
          console.log(err);
        }
      } else {
        this.$swal.fire({
          toast: true,
          icon: "warning",
          title: "Selecione ao menos um destinatário para enviar o e-mail.",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
