<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    style="z-index: 1000"
    :contaProdutosSacola="contaProdutosSacola"
  >
    <template>
      <router-link class="navbar-brand m-0 p-0" to="/">
        <span @click="scrollTop()"
          ><img src="/img/infanzia_logo_horizontal.png" style="height: 60px"
        /></span>
      </router-link>
    </template>

    <template slot="navbar-menu">
      <li class="nav-item" v-if="currentPage != 'produtos'">
        <nav-link
          style="font-size: 14px; color: #503625;"
          class="nav-link"
          to="/"
        >
          <i class="fas fa-store mr-1"></i>
          <p>Voltar para Loja</p>
        </nav-link>
      </li>

      <li class="nav-item" v-if="currentPage != 'sacola'">
        <nav-link
          class="nav-link pr-5 mr-4 mb-4"
          to="/sacola"
          style="color: #503625"
        >
          <div style="position: absolute; margin-left: 20px">
            <i
              class="fas fa-shopping-bag"
              style="font-size: 30px; color: #503625;"
            ></i>
            <span
              v-if="$store.getters.user != null"
              class="badge badge-pill p-1"
              style="border-radius: 50%; height: 23px; width: 23px; background-color: #f00; position: absolute; top: 13px; left: 20px"
              >{{ contaProdutosSacola }}</span
            >
          </div>
        </nav-link>
      </li>

      <drop-down
        v-if="currentPage == 'produtos'"
        tag="li"
        title="Filtrar"
        icon="fas fa-filter mr-1"
        class="nav-item"
      >
        <nav-link style="font-size: 18px" to="#" class="m-0 p-0">
          <button
            @click="
              ocultarFiltros();
              modals.busca = true;
            "
            style="color: #503626; background-color: rgba(0, 0, 0, 0); width: 160px;"
            class="btn btn-primary m-0 text-left"
          >
            <i class="fas fa-search mr-1"></i> Buscar
          </button>
        </nav-link>

        <nav-link style="font-size: 18px" to="#" class="m-0 p-0">
          <button
            @click="exibirFiltros('genero', 'aplicarFiltroGenero')"
            style="color: #503626; background-color: rgba(0, 0, 0, 0); width: 160px"
            class="btn btn-primary m-0 text-left"
          >
            <i class="fas fa-venus-mars"></i> Gênero
          </button>
        </nav-link>

        <nav-link style="font-size: 18px" to="#" class="m-0 p-0">
          <button
            @click="exibirFiltros('idade', 'aplicarFiltroIdade')"
            style="color: #503626; background-color: rgba(0, 0, 0, 0); width: 160px"
            class="btn btn-primary m-0 text-left"
          >
            <i class="fas fa-baby"></i> Idade
          </button>
        </nav-link>

        <nav-link style="font-size: 18px" to="#" class="m-0 p-0">
          <button
            @click="exibirFiltros('categoria', 'aplicarFiltroCategoria')"
            style="color: #503626; background-color: rgba(0, 0, 0, 0); width: 160px"
            class="btn btn-primary m-0 text-left"
          >
            <i class="far fa-folder-open"></i> Categoria
          </button>
        </nav-link>

        <nav-link style="font-size: 18px" to="#" class="m-0 p-0">
          <button
            @click="exibirFiltros('calcado', 'aplicarFiltroCalcado')"
            style="color: #503626; background-color: rgba(0, 0, 0, 0); width: 160px"
            class="btn btn-primary m-0 text-left"
          >
            <i class="fas fa-shoe-prints"></i> Calçados
          </button>
        </nav-link>
        
        <nav-link style="font-size: 18px" to="#" class="m-0 p-0">
          <button
            @click="exibirFiltros('marca', 'aplicarFiltroMarca')"
            style="color: #503626; background-color: rgba(0, 0, 0, 0); width: 160px"
            class="btn btn-primary m-0 text-left"
          >
            <i class="fas fa-tag"></i> Marca
          </button>
        </nav-link>
      </drop-down>

      <!-- INICIO USUARIO NÃO LOGADO -->
      <li class="nav-item" v-if="user == null">
        <a class="nav-link" href="#" @click="modals.login = true">
          <i class="fas fa-sign-in-alt mr-1"></i>
          <p>Efetuar Login</p>
        </a>
      </li>
      <!-- FIM USUARIO NÃO LOGADO -->

      <!-- INICIO SE USUARIO ESTIVER LOGADO EXIBE ITEM PERFIL NO MENU -->
      <li class="nav-item" v-else>
        <drop-down
          tag="li"
          title="Meu Perfil"
          icon="fas fa-user-circle mr-1"
          class="nav-item"
        >
          <nav-link style="font-size: 14px" to="/dados-pessoais">
            <span style="color: #503626"
              ><i class="fas fa-user"></i> Dados Pessoais</span
            ></nav-link
          >
          <nav-link
            style="font-size: 14px"
            v-if="user.conta == 2"
            to="/fornecedor"
          >
            <span style="color: #503626"
              ><i class="fas fa-list"></i> Painel do Fornecedor</span
            ></nav-link
          >

          <nav-link style="font-size: 14px" to="/compras"
            ><span style="color: #503626"
              ><i class="fas fa-box-open"></i> Minhas Compras</span
            ></nav-link
          >
          <nav-link style="font-size: 14px" to="/favoritos">
            <span style="color: #503626"
              ><i class="fas fa-heart"></i> Produtos Favoritos</span
            ></nav-link
          >
          <nav-link style="font-size: 14px" to="/enderecos"
            ><span style="color: #503626"
              ><i class="fas fa-map-marked-alt"></i> Meus Endereços</span
            ></nav-link
          >

          <nav-link style="font-size: 14px" to="/">
            <span @click="signOut()" style="color: #B31004"
              ><i class="fas fa-sign-out-alt"></i> Deslogar</span
            ></nav-link
          >
        </drop-down>
      </li>

      <!-- FIM SE USUARIO ESTIVER LOGADO EXIBE ITEM PERFIL NO MENU -->
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from "@/components";
import store from "../store";
import Vue from "vue";
Vue.directive("click-outside", {});
export default {
  name: "main-navbar",
  props: {
    currentPage: String,
    transparent: Boolean,
    colorOnScroll: Number,
    contaProdutosSacola: Number,
    exibirFiltros: { type: Function },
    ocultarFiltros: { type: Function },
    modals: {
      busca: false,
      login: false,
    },
    user: Object,
  },

  mounted() {
    // this.exibirFiltros()
  },

  components: {
    DropDown,
    Navbar,
    NavLink,
  },
  methods: {
    signOut() {
      if (!this.$store.getters.user) {
        this.$gtag.event("Usuário fez Logout");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Usuário fez Logout");
      }
      localStorage.removeItem("infanzia");
      store.dispatch("fetchUser", null);
      this.$router.push("/");
      this.$swal.fire({
        toast: true,
        icon: "warning",
        title: "Você deslogou do sistema.",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
      // firebase
      //   .auth()
      //   .signOut()
      //   .then(() => {});
    },
    scrollTop() {
      setTimeout(() => {
        document.getElementById("app").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }, 50);
    },
  },
};
</script>

<style></style>
