import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// var x = localStorage.getItem("infanzia")
//             console.log(x)
// var x = JSON.parse(localStorage.getItem("infanzia"))
//             console.log(x)
export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem("infanzia"))
  },

  getters: {
    user(state){
      return state.user
    }
  },
  mutations: {
    // SET_LOGGED_IN(state, value) {
    //   state.user.loggedIn = value;
    // },
    SET_USER(state, data) {
      state.user = data;
    }
  },
  actions: {
    
    fetchUser({ commit }, user) {
      // VARIAVEL "user" GUARDA TODOS OS DADOS DO LOGIN

      if (user != null) {
        commit("SET_USER", user)
        // commit("SET_LOGGED_IN", true);

      } else {
        commit("SET_USER", null);
      }
    }
  },
});