<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3">
      <h5>Detalhes do produto</h5>

      <form @submit.prevent="salvarAlteracoes()">
        <p class="cardGrande-text mb-0 mt-2" style="font-size: 12px; color: #555">
          Código:
        </p>
        <input
          autocomplete="off"
          id="idProduto"
          type="text"
          class="form-control mb-1"
          name="idProduto"
          required
          readonly
          v-model="produto.idProduto"
          placeholder="Nome do produto"
        />

        <p class="cardGrande-text mb-0 mt-2" style="font-size: 12px; color: #555">
          Status:
        </p>
        <select
          required
          class="form-control mt-1"
          v-model="produto.status"
          style="border-radius: 20px"
        >
          <option disabled selected>--- Selecione o status ---</option>
          <option value="0">Disponível</option>
          <option value="1">Sacola</option>
          <option value="2">Vendido</option>
          <option value="3">Pendente</option>
          <option value="4">Devolução</option>
        </select>

        <span class="mr-5 p-0" v-for="(item, index) in produto.idSacola"
            :key="index"><a :href="'/administrativo/vendas?modulo=5&idSacola='+item.idSacola">Ver sacola {{item.idSacola}}</a>
        </span>

        <p class="cardGrande-text mb-0 mt-2" style="font-size: 12px; color: #555">
          Instagram:
        </p>
        <select
          required
          class="form-control mt-1"
          v-model="produto.instagram"
          style="border-radius: 20px"
        >
          <option value="0">Não postado</option>
          <option value="1">Postado</option>
        </select>

        <p class="cardGrande-text mb-0 mt-2" style="font-size: 12px; color: #555">
          Nome:
        </p>
        <input
          autocomplete="off"
          type="text"
          class="form-control mb-1"
          name="nome"
          required
          v-model="produto.nome"
          placeholder="Nome do produto"
        />
        <p class="cardGrande-text mb-0 mt-2" style="font-size: 12px; color: #555">
          Marca:
        </p>
        <vue-simple-suggest
          autocomplete="off"
          id="marca"
          type="text"
          required
          placeholder="Marca"
          v-model="produto.marca"
          :list="listaDeMarcas"
          :filter-by-query="true"
        >
        </vue-simple-suggest>

        <p class="cardGrande-text mb-0 mt-2" style="font-size: 12px; color: #555">
          Selecione categoria(s):
          <span
            v-for="(item, index) in produto.categorias"
            :key="index"
            style="color: #000; font-weight: bold"
          >
            |
            {{ item.categoria }}
          </span>
        </p>

        <select
          required
          multiple
          class="form-control mt-1 select-multiple"
          size="10"
          v-model="produto.categorias"
          id="categorias"
          style="border-radius: 20px"
        >

          <option v-for="categoria in categorias"
              :key="categoria.idCategoria"
              :id="categoria.idCategoria" :value="categoria">{{categoria.categoria}}</option>
        </select>

        <p
          v-if="produto.categorias.includes('calcado')"
          class="cardGrande-text mb-0 mt-2"
          style="font-size: 12px; color: #555"
        >
          Selecione numero(s) do calçado:
          <span
            v-for="(item, index) in produto.tamanhos"
            :key="index"
            style="color: #000; font-weight: bold"
          >
            |
            {{ parseInt(item) }}
          </span>
        </p>
        <p
          v-else
          class="cardGrande-text mb-0 mt-2"
          style="font-size: 12px; color: #555"
        >
          Selecione idade(s):
          <span
            v-for="(item, index) in produto.tamanhos"
            :key="index"
            style="color: #000; font-weight: bold"
          >
            |
            <span v-if="item == 0">RN a 3 meses</span>
            <span v-if="item == 0.3">3 a 6 meses</span>
            <span v-if="item == 0.6">6 a 9 meses</span>
            <span v-if="item == 0.9">9 a 12 meses</span>
            <span v-if="item == 1">1 ano</span>
            <span v-if="item == 1.6">18 meses</span>
            <span v-if="item > 1.6">{{ parseInt(item) }} anos</span>
          </span>
        </p>

        <select
          required
          v-if="verificaCalcado()"
          multiple
          id="calcado"
          v-model="produto.tamanhos"
          class="form-control mb-1 select-multiple"
          size="10"
        >
          <option :value="parseFloat(1).toFixed(2)">1 (14/15 bebê)</option>
          <option :value="parseFloat(2).toFixed(2)">2 (15/16 bebê)</option>
          <option :value="parseFloat(3).toFixed(2)">3 (16/17 bebê)</option>
          <option :value="parseFloat(4).toFixed(2)">4 (17/18 bebê)</option>
          <option
            v-for="index in range(19, 38)"
            :key="index"
            :value="parseFloat(index).toFixed(2)"
          >
            {{ index }}
          </option>
        </select>

        <select
          required
          v-else
          multiple
          id="idade"
          v-model="produto.tamanhos"
          class="form-control mb-1 select-multiple"
          size="10"
        >
          <option :value="parseFloat(0).toFixed(2)">RN a 3 meses</option>
          <option :value="parseFloat(0.3).toFixed(2)">3 a 6 meses</option>
          <option :value="parseFloat(0.6).toFixed(2)">6 a 9 meses</option>
          <option :value="parseFloat(0.9).toFixed(2)">9 a 12 meses</option>
          <option :value="parseFloat(1).toFixed(2)">1 ano</option>
          <option :value="parseFloat(1.6).toFixed(2)">18 meses</option>
          <option
            v-for="index in range(2, 16)"
            :key="index"
            :value="parseFloat(index).toFixed(2)"
          >
            {{ index }} anos
          </option>
        </select>
        <textarea
          required
          class="form-control mb-1"
          v-model="produto.descricaoTamanho"
          id="descricaoTamanho"
          placeholder="Tamanho (descrição)"
        ></textarea>

        <textarea
          required
          class="form-control mb-1"
          v-model="produto.descricao"
          id="descricao"
          placeholder="Descrição do produto"
        ></textarea>
        <p class="cardGrande-text mb-0 mt-3" style="font-size: 12px; color: #555">
          Selecione condição:
        </p>
        <select
          required
          class="form-control mt-1"
          v-model="produto.condicao"
          id="condicao"
          style="border-radius: 20px"
        >
          <option disabled selected>--- Selecione a Condição ---</option>
          <option value="1">Novo com etiqueta</option>
          <option value="2">Como novo</option>
          <option value="3">Super conservado</option>
          <option value="4">Conservado</option>
        </select>

        <p class="cardGrande-text mb-0 mt-1" style="font-size: 12px; color: #555">
          Selecione fornecedor:
        </p>
        <select
          required
          class="form-control mt-1"
          v-model="produto.fornecedor"
          id="fornecedor"
          style="border-radius: 20px"
        >
          <option disabled>Selecione fornecedor</option>
          <option
            v-for="fornecedor in fornecedores"
            :key="fornecedor.key"
            :value="fornecedor.idFornecedor"
          >
            {{ fornecedor.idFornecedor }} - {{ fornecedor.NomeFornecedor }}
          </option>
        </select>

        <p class="cardGrande-text mb-0 mt-1" style="font-size: 12px; color: #555">
          Preço:
        </p>
        <input
          autocomplete="off"
          id="preco"
          type="number"
          step=".01"
          class="form-control mb-1"
          name="preco"
          required
          v-model.number="produto.preco"
          placeholder="Preço"
        />

        <p class="cardGrande-text mb-0 mt-1" style="font-size: 12px; color: #555">
          Preço desconto Infanzia (custeado pelo Infanzia):
        </p>
        <input
          autocomplete="off"
          id="PrecoDescontoInfanzia"
          type="number"
          step=".01"
          class="form-control mb-1"
          name="PrecoDescontoInfanzia"
          v-model.number="produto.PrecoDescontoInfanzia"
          placeholder="Preço"
        />

        <p class="cardGrande-text mb-0 mt-1" style="font-size: 12px; color: #555">
          Preço promocional (custeado 50/50 com fornecedor):
        </p>
        <input
          autocomplete="off"
          id="PrecoPromocional"
          type="number"
          step=".01"
          class="form-control mb-1"
          name="PrecoPromocional"
          v-model.number="produto.PrecoPromocional"
          placeholder="Preço"
        />
        <p class="cardGrande-text mb-0 mt-1" style="font-size: 12px; color: #555">
          Preço categoria (custeado pelo Infanzia):
        </p>
        <input
          readonly
          autocomplete="off"
          id="PrecoCategoria"
          type="number"
          step=".01"
          class="form-control mb-1"
          name="PrecoCategoria"
          v-model.number="produto.PrecoCategoria"
          placeholder="Preço"
        />

        <p class="cardGrande-text mb-0 mt-1" style="font-size: 10px; color: #555">
          *Hierarquia de precificação: 1º desconto infanzia; 2º preço
          promocional; 3º preço categoria; 4º preço
        </p>

        <p class="cardGrande-text mt-4 mb-0">Fotos cadastradas:</p>
        <div class="row m-1 p-1">
          <div v-for="(foto, i) in produto.fotosAtuais" :key="i">
            <img
              v-lazy="'https://www.infanziabrecho.com.br/uploads/' + foto"
              class="m-2 p-1"
              style="width: 100px; height: 100px; border: 1px solid #f96432"
            />
          </div>
        </div>

        <p class="cardGrande-text mt-4 mb-0">Substituir fotos:</p>

        <div class="mt-0 mb-1 p-1">
          <p class="cardGrande-text m-0" style="font-size: 14px">
            Atenção às seguintes regras:
          </p>
          <ul class="m-1" style="font-size: 12px; color: #777">
            <li>número máximo de 4 fotos</li>
            <li>
              a ordem das fotos será a ordem com que você as seleciona
            </li>
            <li>enviar fotos quadradas (1:1)</li>
          </ul>
          <input
            accept=".jpg"
            class="form-control btn btn-primary"
            style="border: 0px"
            type="file"
            id="fotos"
            v-on:input="arquivaFotos()"
            ref="fotos"
            multiple="multiple"
          />
        </div>
        <div class="row m-1 p-1">
          <div v-for="(foto, i) in previewFotos" :key="foto.key">
            <img
              :src="foto"
              class="m-2 p-1"
              style="width: 100px; height: 100px; border: 1px solid #f96432"
            />
            Foto {{ i + 1 }}
          </div>
        </div>
        <div class="m-1 p-1">
          <button
            type="submit"
            class="btn btn-success btn-lg float-right mb-5 mr-4"
          >
            Salvar Alterações
          </button>
          <a
            v-if="produto.status == 3"
            class="btn btn-danger btn-lg float-right mb-5 mr-4"
            @click="excluirProduto(produto.idProduto, produto.nome)"
          >
            Excluir Produto
          </a>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import { Button, FormGroupInput } from "@/components";
import VueSimpleSuggest from "vue-simple-suggest";
import * as axios from "axios";
import AdministrativoMenu from "./AdministrativoMenu";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    VueSimpleSuggest,
    AdministrativoMenu,
    Loading,
  },

  data() {
    return {
      produto: {
        idProduto: "",
        status: "",
        nome: "",
        instagram: "",
        marca: "",
        condicao: "",
        descricao: "",
        descricaoTamanho: "",
        fornecedor: "",
        categorias: [],
        tamanhos: [],
        preco: "",
        PrecoDescontoInfanzia: "",
        PrecoPromocional: "",
        PrecoCategoria: "",
        fotos: [],
        fotosAtuais: [],
        idSacola: [],
      },
      categorias: [],
      listaDeMarcas: [],
      condicoes: [],
      fornecedores: [],

      onCancel: this.cancelLoading(),
      fullPage: "",

      formData: new FormData(),
      fotos: null,
      previewFotos: [],
    };
  },

  async mounted() {
    if (this.$store.getters.user.conta == 1) {
      try {
        await this.carregarCategorias();
        await this.carregarProduto(this.$route.query.produto);
      } catch (err) {
        console.log(err);
      }
      try {
        await this.carregarMarcas();
      } catch (err) {
        console.log(err);
      }
      try {
        await this.carregarFornecedores();
      } catch (err) {
        console.log(err);
      }
    }
  },

  watch: {
    $route() {
      this.carregarProduto(this.$route.query.produto);
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },
    async carregarProduto(id) {
      this.isLoading = true;
      this.produto.tamanhos = [];
      this.produto.categorias = [];
      this.produto.fotosAtuais = [];
      this.previewFotos = [];
      try {
        await axios
          .post(ambiente + "produto.php", {
            idProduto: id,
            status: [0, 1, 2, 3, 4],
            transaction: "0",
          })
          .then((response) => {
            if (response.data == false) {
              //this.$router.go(-1)
              //window.location.href = '/';
            } else {

              this.produto.idProduto = response.data.idProduto;
              this.produto.status = response.data.Status;
              this.produto.instagram = response.data.Instagram;
              this.produto.nome = response.data.NomeProduto;
              this.produto.marca = response.data.Marca;
              this.produto.condicao = response.data.Condicao;
              this.produto.descricao = response.data.Descricao;
              this.produto.descricaoTamanho = response.data.Tamanho;
              this.produto.fornecedor = response.data.Fornecedor;
              this.produto.idSacola = response.data.idSacola;
              var tam = response.data.length;

              //para o caso de o produto estar em mais de uma sacola:
              for(var i = 0; i<tam; i++){
                this.produto.idSacola.push(response.data[i].idSacola);
              }

              this.produto.categorias = response.data.categorias;

              //aloca as categorias:
              // tam = response.data.categorias.length;
              // for(i = 0; i<tam; i++){
              //   this.produto.categorias.push(response.data.categorias[i]);
              // }              
            
              //tamanhos
              if (response.data.tamanho1)
                this.produto.tamanhos.push(response.data.tamanho1);
              if (response.data.tamanho2)
                this.produto.tamanhos.push(response.data.tamanho2);
              if (response.data.tamanho3)
                this.produto.tamanhos.push(response.data.tamanho3);
              //fotos
              if (
                response.data.Foto1 &&
                !this.produto.fotosAtuais.includes(response.data.Foto1)
              )
                this.produto.fotosAtuais.push(response.data.Foto1);
              if (
                response.data.Foto2 &&
                !this.produto.fotosAtuais.includes(response.data.Foto2)
              )
                this.produto.fotosAtuais.push(response.data.Foto2);
              if (
                response.data.Foto3 &&
                !this.produto.fotosAtuais.includes(response.data.Foto3)
              )
                this.produto.fotosAtuais.push(response.data.Foto3);
              if (
                response.data.Foto4 &&
                !this.produto.fotosAtuais.includes(response.data.Foto4)
              )
                this.produto.fotosAtuais.push(response.data.Foto4);

              this.produto.preco = response.data.Preco;
              this.produto.PrecoDescontoInfanzia =
                response.data.PrecoDescontoInfanzia;
              this.produto.PrecoPromocional = response.data.PrecoPromocional;
              this.produto.PrecoCategoria = response.data.PrecoCategoria;

              this.fotoDetalhe = this.produto.Foto1;
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async carregarCategorias() {
      axios
        .post(ambiente + "categorias.php", {
          transaction: "carregar",
        })
        .then((response) => {
          this.categorias = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // VINDOS DO ARQUIVO DE CADASTRAR PRODUTO:

    async salvarAlteracoes() {
      this.isLoading = true;

      //se tiver novas fotos para substituir as antigas...
      if (this.previewFotos.length > 0) {
        axios
          .post(
            ambiente + "upload.php",
            this.formData,
            {
              useCredentails: true,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .catch(function(erro) {
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ocorreu um erro, tente novamente.\n(" + erro + ")",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            return;
          });
      } else {
        this.produto.fotos = null;
      }

      // grava novos dados do produto
      try {
        await axios
          .post(ambiente + "produto.php", {
            conta: this.$store.getters.user.conta,
            transaction: 2,
            produto: this.produto,
            tamanhos: this.produto.tamanhos,
            fotos: this.produto.fotos,
          })
          .then(() => {
            this.carregarProduto(this.$route.query.produto);
            document.getElementById("fotos").value = "";
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto editado com sucesso!",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    excluirProduto(idProduto, nome) {
      this.$swal({
        title: "Excluir produto",
        text:
          "Tem certeza que deseja excluir o produto " +
          nome +
          "?\n\nATENÇÃO! Esta operação não poderá ser desfeita!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          try {
            axios
              .post(ambiente + "produto.php", {
                conta: this.$store.getters.user.conta,
                transaction: 4,
                idProduto: idProduto,
              })
              .then((response) => {
                if (response.data == true) {
                  this.$swal.fire({
                    toast: true,
                    icon: "success",
                    title: "Produto excluído com sucesso!",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                } else {
                  this.$swal.fire({
                    toast: true,
                    icon: "error",
                    title: "Ocorreu um erro.",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
              });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
        }
      });

      this.isLoading = false;
    },

    arquivaFotos() {
      if (this.$refs.fotos.files.length > 0) {
        this.previewFotos = [];
        this.produto.fotos = [];
        if (this.$refs.fotos.files.length >= 5) {
          //limpa o FileList
          document.getElementById("fotos").value = "";
          this.$swal.fire({
            toast: true,
            icon: "warning",
            title: "Cada produto pode ter no máximo 4 fotos. Tente novamente.",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else {
          for (var i = 0; i < this.$refs.fotos.files.length; i++) {
            this.previewFotos.push(
              URL.createObjectURL(this.$refs.fotos.files[i])
            );
            const nomeArquivoFoto = this.generateRandomString(32);
            this.formData.append(
              "fotos[" + i + "]",
              this.$refs.fotos.files[i],
              nomeArquivoFoto + ".jpg"
            );
            this.produto.fotos.push(nomeArquivoFoto + ".jpg");
          }
        }
      }
    },
    generateRandomString(length) {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },

    range: function(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },

    verificaCalcado() {
        var tam =  this.produto.categorias.length;
        for(var i = 0;i<tam;i++){
          if(this.produto.categorias[i].categoria == "Calçados"){
            return true;
          }
        }
        return false;      
    },

    async carregarMarcas() {
      this.listaDeMarcas = [];
      axios
        .post(ambiente + "marcas.php", {
          conta: this.$store.getters.user.conta,
          transaction: 2,
        })
        .then((response) => {
          this.listaDeMarcas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async carregarFornecedores() {
      this.fornecedores = [];
      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            conta: this.$store.getters.user.conta,
            idUsuario: this.user.id,
            transaction: 5,
          })
          .then((response) => {
            this.fornecedores = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        //this.isLoading = false;
        console.log(err);
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/simple-sugest-autocomplete.css";
</style>
