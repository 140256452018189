<template>
  <div>
    <MainNavbar
      :filtro="filtro"
      :modals="modals"
      :exibirFiltros="exibirFiltros"
      :ocultarFiltros="ocultarFiltros"
      :user="user"
      :contaProdutosSacola="contaProdutosSacola"
      :currentPage="paginaAtual"
    />

    <div>
      <GradeDeProdutos
        :filtro="filtro"
        :modals="modals"
        :colocarNaSacola="colocarNaSacola"
        :removerDaSacola="removerDaSacola"
        :carregarSacola="carregarSacola"
        :sacola="sacola"
        :favoritos="favoritos"
        :confereFavorito="confereFavorito"
        :adicionarFavoritos="adicionarFavoritos"
        :removerFavoritos="removerFavoritos"
        :isLoading="isLoading"
        :exibirFiltros="exibirFiltros"
        :ocultarFiltros="ocultarFiltros"
      />
    </div>

    <!-- INICIO DIVS DE FILTROS -->

    <!-- FILTRO GENERO -->
    <div id="genero" class="overlay mt-3 p-0">
      <div class="overlay-content">
        <p>Selecione o gênero:</p>
        <input
          style="transform: scale(1.5);"
          type="checkbox"
          id="Menina"
          value="Menina"
          v-model="filtro.categorias"
        />
        <label class="ml-1" for="Menina">Menina</label>
        <br />
        <input
          style="transform: scale(1.5);"
          type="checkbox"
          id="Menino"
          value="Menino"
          v-model="filtro.categorias"
        />
        <label class="ml-1" for="Menino">Menino</label>

        <button
          class="btn btn-success rounded-circle p-1 mb-1 mr-3"
          style="
            height: 15vw;
            width: 15vw;
            min-width: 90px;
            min-height: 90px;
            max-width: 120px;
            max-height: 120px;
            white-space: normal;
            display: none;
            position: fixed;
            bottom: 0px;
            right: 0px;
          "
          id="aplicarFiltroGenero"
          @click="ocultarFiltros()"
        >
          Aplicar / Voltar
        </button>
      </div>
    </div>

    <!-- FILTRO CATEGORIA -->
    <div id="categoria" class="overlay mt-3 p-0">
      <div class="overlay-content">
        <p>Selecione a(s) categoria(s):</p>
        <div class="container">
          <div class="row">
            <span
              v-for="categoria in categorias"
              :key="categoria.idCategoria"
              :id="categoria.idCategoria"
            >
              <div
                v-if="
                  categoria.categoria != 'Menino' &&
                    categoria.categoria != 'Menina' &&
                    categoria.categoria != 'Calçados'
                "
                class="col p-0 ml-1 mr-0 my-0"
                style="min-width: 180px;max-width: 200px;display: inline-block; text-align: left;"
              >
                <input
                  style="transform: scale(1.5);"
                  type="checkbox"
                  :id="categoria.categoria"
                  :value="categoria.categoria"
                  v-model="filtro.categorias"
                />
                <label class="ml-1" :for="categoria.categoria">{{
                  categoria.categoria
                }}</label>
                <div
                  v-if="
                    categoria.idCategoria == 18 &&
                      filtro.categorias.includes('Calçados')
                  "
                >
                  <p>Selecione o(s) número(s) para filtrar calçados:</p>
                  <div class="container d-flex justify-content-center">
                    <div class="row">
                      <div class="col-xs" style="text-align: left">
                        <span
                          v-for="(n, index) in tamanhosCalcado"
                          :key="n.key"
                        >
                          <button
                            :id="index"
                            v-if="
                              filtro.categorias.includes('Calçados') &&
                                filtro.calcado.includes(n)
                            "
                            class="btn btn-primary"
                            style="width: 60px"
                            @click="filtrarCalcado(n)"
                          >
                            <span v-if="n == 35"> {{ n }}+ </span>
                            <span v-else> {{ n }} </span>
                          </button>
                          <button
                            v-else
                            class="btn btn-secondary"
                            style="width: 60px"
                            @click="filtrarCalcado(n)"
                          >
                            <span v-if="n == 35"> {{ n }}+ </span>
                            <span v-else> {{ n }} </span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        <button
          class="btn btn-success rounded-circle p-1 mb-1 mr-3"
          style="
            height: 15vw;
            width: 15vw;
            min-width: 90px;
            min-height: 90px;
            max-width: 120px;
            max-height: 120px;
            white-space: normal;
            display: none;
            position: fixed;
            bottom: 0px;
            right: 0px;
          "
          id="aplicarFiltroCategoria"
          @click="ocultarFiltros()"
        >
          Aplicar / Voltar
        </button>
      </div>
    </div>

    <!-- FILTRO CALCADO -->

    <div id="calcado" class="overlay mt-3 p-0">
      <div class="overlay-content">
        <p>Selecione o(s) número(s) para filtrar calçados:</p>
        <div class="container d-flex justify-content-center">
          <div class="row">
            <div class="col-xs" style="text-align: left">
              <span v-for="(n, index) in tamanhosCalcado" :key="n.key">
                <button
                  :id="index"
                  v-if="
                    filtro.categorias.includes('Calçados') &&
                      filtro.calcado.includes(n)
                  "
                  class="btn btn-primary"
                  style="width: 60px"
                  @click="filtrarCalcado(n)"
                >
                  <span v-if="n == 35"> {{ n }}+ </span>
                  <span v-else> {{ n }} </span>
                </button>
                <button
                  v-else
                  class="btn btn-secondary"
                  style="width: 60px"
                  @click="filtrarCalcado(n)"
                >
                  <span v-if="n == 35"> {{ n }}+ </span>
                  <span v-else> {{ n }} </span>
                </button>
              </span>
            </div>
          </div>
        </div>

        <button
          class="btn btn-success rounded-circle p-1 mb-1 mr-3"
          style="
            height: 15vw;
            width: 15vw;
            min-width: 90px;
            min-height: 90px;
            max-width: 120px;
            max-height: 120px;
            white-space: normal;
            display: none;
            position: fixed;
            bottom: 0px;
            right: 0px;
          "
          id="aplicarFiltroCalcado"
          @click="ocultarFiltros()"
        >
          Aplicar / Voltar
        </button>
      </div>
    </div>

    <!-- FILTRO IDADE -->

    <div id="idade" class="overlay mt-3 p-0">
      <div class="overlay-content">
        <p>Selecione a(s) idade(s):</p>
        <div class="container d-flex justify-content-center">
          <div class="row">
            <div class="col-xs" style="text-align: center">
              <button
                v-if="filtro.idade.includes(0)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(0)"
              >
                RN a 3 meses
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(0)"
              >
                RN a 3 meses
              </button>
              <button
                v-if="filtro.idade.includes(0.3)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(0.3)"
              >
                3 a 6 meses
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(0.3)"
              >
                3 a 6 meses
              </button>
              <button
                v-if="filtro.idade.includes(0.6)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(0.6)"
              >
                6 a 9 meses
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(0.6)"
              >
                6 a 9 meses
              </button>
              <button
                v-if="filtro.idade.includes(0.9)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(0.9)"
              >
                9 a 12 meses
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(0.9)"
              >
                9 a 12 meses
              </button>
              <button
                v-if="filtro.idade.includes(1)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(1)"
              >
                1 ano
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(1)"
              >
                1 ano
              </button>
              <button
                v-if="filtro.idade.includes(1.6)"
                class="btn btn-primary"
                style="width: 100px"
                @click="filtrarIdade(1.6)"
              >
                18 meses
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(1.6)"
              >
                18 meses
              </button>
              <button
                v-if="filtro.idade.includes(2)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(2)"
              >
                2 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(2)"
              >
                2 anos
              </button>

              <button
                v-if="filtro.idade.includes(3)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(3)"
              >
                3 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(3)"
              >
                3 anos
              </button>
              <button
                v-if="filtro.idade.includes(4)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(4)"
              >
                4 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(4)"
              >
                4 anos
              </button>
              <button
                v-if="filtro.idade.includes(5)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(5)"
              >
                5 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(5)"
              >
                5 anos
              </button>

              <button
                v-if="filtro.idade.includes(6)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(6)"
              >
                6 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(6)"
              >
                6 anos
              </button>
              <button
                v-if="filtro.idade.includes(7)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(7)"
              >
                7 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(7)"
              >
                7 anos
              </button>
              <button
                v-if="filtro.idade.includes(8)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(8)"
              >
                8 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(8)"
              >
                8 anos
              </button>

              <button
                v-if="filtro.idade.includes(9)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(9)"
              >
                9 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(9)"
              >
                9 anos
              </button>
              <button
                v-if="filtro.idade.includes(10)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(10)"
              >
                10 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(10)"
              >
                10 anos
              </button>
              <button
                v-if="filtro.idade.includes(11)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(11)"
              >
                11 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(11)"
              >
                11 anos
              </button>

              <button
                v-if="filtro.idade.includes(12)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(12)"
              >
                12 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(12)"
              >
                12 anos
              </button>
              <button
                v-if="filtro.idade.includes(13)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(13)"
              >
                13 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(13)"
              >
                13 anos
              </button>
              <button
                v-if="filtro.idade.includes(14)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(14)"
              >
                14 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(14)"
              >
                14 anos
              </button>
              <button
                v-if="filtro.idade.includes(15)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(15)"
              >
                15 anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(15)"
              >
                15 anos
              </button>
              <button
                v-if="filtro.idade.includes(16)"
                class="btn btn-primary"
                style="width: 125px"
                @click="filtrarIdade(16)"
              >
                16+ anos
              </button>
              <button
                v-else
                class="btn btn-secondary"
                style="width: 125px"
                @click="filtrarIdade(16)"
              >
                16+ anos
              </button>
            </div>
          </div>
        </div>

        <button
          class="btn btn-success rounded-circle p-1 mb-1 mr-3"
          style="
            height: 15vw;
            width: 15vw;
            min-width: 90px;
            min-height: 90px;
            max-width: 120px;
            max-height: 120px;
            white-space: normal;
            display: none;
            position: fixed;
            bottom: 0px;
            right: 0px;
          "
          id="aplicarFiltroIdade"
          @click="ocultarFiltros()"
        >
          Aplicar / Voltar
        </button>
      </div>
    </div>

    <!-- FILTRO MARCA -->

    <div id="marca" class="overlay mt-3 p-0">
      <div class="overlay-content mb-5 pb-5">
        <div class="container justify-content-center">
          <input
            type="text"
            class="form-control"
            v-model="buscaMarca"
            placeholder="Filtrar marcas..."
          />
          <p class="mt-3 mb-0">Selecione a(s) marca(s) desejada(s):</p>
          <div class="row">
            <div v-for="(value, key, index) in marcas" :key="index">
              <div
                v-if="
                  value.Marca.toLowerCase().includes(
                    buscaMarca.toLowerCase()
                  ) && buscaMarca != ''
                "
              >
                <button
                  v-if="filtro.brand.includes(value.Marca)"
                  class="btn btn-primary"
                  @click="filtrarMarca(value.Marca)"
                >
                  {{ value.Marca }}
                </button>
                <button
                  v-else
                  class="btn btn-secondary"
                  @click="filtrarMarca(value.Marca)"
                >
                  {{ value.Marca }}
                </button>
              </div>
            </div>
          </div>

          <p style="text-align: left; font-size: 14px">Marcas em destaque:</p>
          <div class="row">
            <div v-for="(value, key, index) in marcas" :key="index">
              <div
                v-if="
                  value.Marca.includes('Adidas') ||
                    value.Marca.includes('Alphabeto') ||
                    value.Marca.includes('Carters') ||
                    value.Marca.includes('Crocs') ||
                    value.Marca.includes('Disney') ||
                    value.Marca.includes('Dress To') ||
                    value.Marca.includes('Fábula') ||
                    value.Marca.includes('GAP') ||
                    value.Marca.includes('Gymboree') ||
                    value.Marca.includes('Momi') ||
                    value.Marca.includes('Mon Sucré') ||
                    value.Marca.includes('Nike') ||
                    value.Marca.includes('Oh Boy') ||
                    value.Marca.includes('Old Navy') ||
                    value.Marca.includes('Oshkosh') ||
                    value.Marca.includes('Petit Cherie') ||
                    value.Marca.includes('Ralph Lauren') ||
                    value.Marca.includes('Reserva') ||
                    value.Marca.includes('Tommy Hilfiger') ||
                    value.Marca.includes('Zara')
                "
              >
                <button
                  v-if="filtro.brand.includes(value.Marca)"
                  class="btn btn-primary"
                  @click="filtrarMarca(value.Marca)"
                >
                  {{ value.Marca }}
                </button>
                <button
                  v-else
                  class="btn btn-secondary"
                  @click="filtrarMarca(value.Marca)"
                >
                  {{ value.Marca }}
                </button>
              </div>
            </div>
          </div>

          <p style="text-align: left; font-size: 14px">Demais marcas:</p>
          <div class="row">
            <div v-for="(value, key, index) in marcas" :key="index">
              <div
                v-if="
                  (value.Marca.includes(buscaMarca) == false &&
                    value.Marca.includes('Adidas') == false) ||
                    value.Marca.includes('Alphabeto') == false ||
                    value.Marca.includes('Carters') == false ||
                    value.Marca.includes('Crocs') == false ||
                    value.Marca.includes('Disney') == false ||
                    value.Marca.includes('Dress To') == false ||
                    value.Marca.includes('Fábula') == false ||
                    value.Marca.includes('GAP') == false ||
                    value.Marca.includes('Gymboree') == false ||
                    value.Marca.includes('Momi') == false ||
                    value.Marca.includes('Mon Sucré') == false ||
                    value.Marca.includes('Nike') == false ||
                    value.Marca.includes('Oh Boy') == false ||
                    value.Marca.includes('Old Navy') == false ||
                    value.Marca.includes('Oshkosh') == false ||
                    value.Marca.includes('Petit Cherie') == false ||
                    value.Marca.includes('Ralph Lauren') == false ||
                    value.Marca.includes('Reserva') == false ||
                    value.Marca.includes('Tommy Hilfiger') == false ||
                    value.Marca.includes('Zara') == false
                "
              >
                <button
                  v-if="filtro.brand.includes(value.Marca)"
                  class="btn btn-primary"
                  @click="filtrarMarca(value.Marca)"
                >
                  {{ value.Marca }}
                </button>
                <button
                  v-else
                  class="btn btn-secondary"
                  @click="filtrarMarca(value.Marca)"
                >
                  {{ value.Marca }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn btn-success rounded-circle p-1 mb-1 mr-3"
          style="
            height: 15vw;
            width: 15vw;
            min-width: 90px;
            min-height: 90px;
            max-width: 120px;
            max-height: 120px;
            white-space: normal;
            display: none;
            position: fixed;
            bottom: 0px;
            right: 0px;
          "
          id="aplicarFiltroMarca"
          @click="ocultarFiltros()"
        >
          Aplicar / Voltar
        </button>
      </div>
    </div>
    <!-- FIM DIVS DE FILTROS -->

    <!-- INICIO MODAL BUSCA -->
    <modal
      :show.sync="modals.busca"
      :show-close="false"
      headerClasses="justify-content-center"
      type="mini"
      style="z-index: 9999"
    >
      <p>O que deseja procurar?</p>
      <input
        type="text"
        class="form-control m-1"
        name="busca"
        v-model="filtro.busca"
        placeholder="Digite aqui..."
        autocomplete="off"
      />

      <center>
        <n-button type="primary" @click.native="modals.busca = false"
          >Ok!</n-button
        >
      </center>
    </modal>
    <!-- FIM MODAL busca -->

    <!-- INICIO MODAL LOGIN -->
    <modal
      :show.sync="modals.login"
      :show-close="false"
      headerClasses=" p-0 m-0"
      style="z-index: 1001"
    >
      <n-button
        type="primary p-2 m-0"
        class=""
        round
        icon
        simple
        style="width: 50px; height: 50px"
        @click.native="modals.login = false"
        ><i class="fas fa-times"></i
      ></n-button>
      <Login />
    </modal>
    <!-- FIM MODAL LOGIN -->

    <MainFooter />
  </div>
</template>

<script>
import { Checkbox, Modal, Button } from "@/components";
import GradeDeProdutos from "./GradeDeProdutos";
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";
import Login from "./admin/Login";
import * as axios from "axios";
import { mapGetters } from "vuex";
import ambiente from "../variables/variables.js";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  watch: {
    user: function() {
      this.carregarSacola();
    },
  },

  data() {
    return {
      paginaAtual: "produtos",
      filtro: {
        estado: [0, 1],
        brand: [],
        idade: [],
        calcado: [],
        ordenar: 1,
        categorias: [],
      },

      marcas: [],
      categorias: [],
      tamanhosCalcado: [
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
        32,
        33,
        34,
        35,
      ],

      sacola: null,
      favoritos: null,

      contaProdutosSacola: 0,

      modals: {
        busca: false,
        login: false,
      },
      buscaMarca: "",
      element: null,

      isLoading: false,
    };
  },
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  async mounted() {
    try {
      this.carregarMarcas();
      this.carregarCategorias();
    } catch (error) {
      console.log(error);
    }
    try {
      await this.ocultarFiltros();
    } catch (error) {
      console.log(error);
    }

    if (this.$store.getters.user != null) {
      try {
        await this.carregarSacola();
      } catch (error) {
        console.log(error);
      }
      try {
        await this.carregarFavoritos();
      } catch (error) {
        console.log(error);
      }
    }
  },
  components: {
    Modal,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    GradeDeProdutos,
    MainNavbar,
    MainFooter,
    Login,
  },
  methods: {
    async carregarSacola() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 2,
            idUsuario: this.$store.getters.user.id || null,
          })
          .then((response) => {
            this.sacola = response.data;
            this.soma = this.sacola.soma;

            if (response.data.status != 400) {
              this.contaProdutosSacola = this.sacola.produtos.length;
            } else {
              this.contaProdutosSacola = 0;
              this.sacola.produtos = [];
            }

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    exibirFiltros(element, id) {
      this.ocultarFiltros();
      document.getElementById(element).style.width = "100%";
      document.getElementById(id).style.display = "block";
    },

    ocultarFiltros() {
      document.getElementById("categoria").style.width = "0%";
      document.getElementById("genero").style.width = "0%";
      document.getElementById("idade").style.width = "0%";
      document.getElementById("calcado").style.width = "0%";
      document.getElementById("marca").style.width = "0%";
      document.getElementById("aplicarFiltroGenero").style.display = "none";
      document.getElementById("aplicarFiltroIdade").style.display = "none";
      document.getElementById("aplicarFiltroCategoria").style.display = "none";
      document.getElementById("aplicarFiltroCalcado").style.display = "none";
      document.getElementById("aplicarFiltroMarca").style.display = "none";
    },

    filtrarCalcado(calcado) {
      if (!this.$store.getters.user) {
        this.$gtag.event("Filtro Calçado");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Filtro Calçado");
      }

      if (this.filtro.calcado.includes(calcado)) {
        const index = this.filtro.calcado.indexOf(calcado);
        if (index > -1) {
          this.filtro.calcado.splice(index, 1);

          if (this.filtro.calcado.length == 0) {
            this.filtro.categorias.splice(
              this.filtro.categorias.indexOf("Calçados"),
              1
            );
          }
        }
      } else {
        //this.filtro.categorias = [];
        this.filtro.calcado.push(calcado);
        if (!this.filtro.categorias.includes("Calçados")) {
          this.filtro.categorias.push("Calçados");
        }
      }
    },

    filtrarIdade(idade) {
      if (!this.$store.getters.user) {
        this.$gtag.event("Filtro Idade");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Filtro Idade");
      }

      if (this.filtro.idade.includes(idade)) {
        const index = this.filtro.idade.indexOf(idade);
        if (index > -1) {
          this.filtro.idade.splice(index, 1);
        }
      } else {
        this.filtro.idade.push(idade);
      }
    },

    filtrarMarca(marca) {
      if (!this.$store.getters.user) {
        this.$gtag.event("Filtro Marca");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Filtro Marca");
      }

      if (this.filtro.brand.includes(marca)) {
        const index = this.filtro.brand.indexOf(marca);
        if (index > -1) {
          this.filtro.brand.splice(index, 1);
        }
      } else {
        this.filtro.brand.push(marca);
      }
    },

    async carregarMarcas() {
      axios
        .post(ambiente + "marcas.php", {
          transaction: 3,
        })
        .then((response) => {
          this.marcas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async carregarCategorias() {
      axios
        .post(ambiente + "categorias.php", {
          transaction: "carregar",
        })
        .then((response) => {
          this.categorias = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async colocarNaSacola(produto) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Produto Adicionado a Sacola");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Produto Adicionado a Sacola");
      }

      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 1,
            produto: produto,
            idUsuario: this.user.id,
          })
          .then(() => {
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto adicionado à sacola",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.carregarSacola();
      this.isLoading = false;
    },

    async removerDaSacola(produto) {
      if (this.user == null) {
        this.modals.login = true;
      } else {
        this.isLoading = true;

        try {
          await axios
            .post(ambiente + "sacola.php", {
              transaction: 0,
              produto: produto,
              idUsuario: this.user.id,
            })
            .then(() => {
              this.isLoading = false;
              this.$swal.fire({
                toast: true,
                icon: "success",
                title: "Produto removido da sacola",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              });
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);
              this.$swal.fire({
                toast: true,
                icon: "error",
                title: "Ops... Ocorreu um erro.",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        } catch (error) {
          this.isLoading = false;
          console.log(error);
          this.$swal.fire({
            toast: true,
            icon: "error",
            title: "Ops... Ocorreu um erro.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.carregarSacola();
        this.isLoading = false;
      }
    },

    async carregarFavoritos() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "favoritos.php", {
            transaction: 2,
            idUsuario: this.user.id,
          })
          .then((response) => {
            this.favoritos = response.data.favoritos;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async adicionarFavoritos(idProduto) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "favoritos.php", {
            transaction: 1,
            idUsuario: this.user.id,
            idProduto: idProduto,
          })
          .then(() => {
            this.carregarFavoritos();
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto adicionado aos favoritos",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    async removerFavoritos(idProduto) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "favoritos.php", {
            transaction: 0,
            idUsuario: this.user.id,
            idProduto: idProduto,
          })
          .then(() => {
            this.carregarFavoritos();
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto removido dos favoritos",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    confereFavorito(idProduto) {
      if (!this.favoritos) {
        return true;
      } else {
        return (
          this.favoritos.filter((obj) => obj.idProduto == idProduto).length == 0
        );
      }
    },
  },
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
