<template>
  <div class="mt-5" style="min-height: 100vh">
    <MainNavbar
      :currentPage="paginaAtual"
      :contaProdutosSacola="contaProdutosSacola"
      :user="user"
      :modals="modals"
    />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div v-if="produto.Foto1" class="row mb-5 mt-5 pt-5 mx-1">
      <div class="col-lg-6 col-md-12 mt-5">
        <center>
          <div class="icons-container">
            <img
              :src="'https://www.infanziabrecho.com.br/uploads/' + fotoDetalhe"
              class="rounded img-raised"
            />
          </div>
          <img
            v-if="fotoDetalhe != produto.Foto1"
            @click="fotoDetalhe = produto.Foto1"
            :src="'https://www.infanziabrecho.com.br/uploads/' + produto.Foto1"
            class="rounded img-raised m-1"
            style="width: 80px"
          />
          <img
            v-if="
              fotoDetalhe != produto.Foto2 &&
                produto.Foto2 != null &&
                produto.Foto2 != ''
            "
            @click="fotoDetalhe = produto.Foto2"
            :src="'https://www.infanziabrecho.com.br/uploads/' + produto.Foto2"
            class="rounded img-raised m-1"
            style="width: 80px"
          />
          <img
            v-if="
              fotoDetalhe != produto.Foto3 &&
                produto.Foto3 != null &&
                produto.Foto3 != ''
            "
            @click="fotoDetalhe = produto.Foto3"
            :src="'https://www.infanziabrecho.com.br/uploads/' + produto.Foto3"
            class="rounded img-raised m-1"
            style="width: 80px"
          />
          <img
            v-if="
              fotoDetalhe != produto.Foto4 &&
                produto.Foto4 != null &&
                produto.Foto4 != ''
            "
            @click="fotoDetalhe = produto.Foto4"
            :src="'https://www.infanziabrecho.com.br/uploads/' + produto.Foto4"
            class="rounded img-raised m-1"
            style="width: 80px"
          />
        </center>
      </div>
      <div class="col-lg-6 col-md-12">
        <h2 class="title">{{ produto.NomeProduto }}</h2>
        <h4>
          {{ produto.Descricao }}
        </h4>

        <ul class="list-group list-group-flush mb-5">
          <li class="list-group-item">{{ produto.Tamanho }}</li>
          <li
            v-if="
              produto.Menino == 1 && (produto.Menina == 0 || !produto.Menina)
            "
            class="list-group-item"
          >
            Gênero: masculino
          </li>
          <li
            v-if="
              (produto.Menino == 0 || !produto.Menino) && produto.Menina == 1
            "
            class="list-group-item"
          >
            Gênero: feminino
          </li>
          <li
            v-if="produto.Menino == 1 && produto.Menina == 1"
            class="list-group-item"
          >
            Gênero: unisex
          </li>

          <li v-if="produto.Condicao == 1" class="list-group-item">
            Condição: Wow! Ainda com etiqueta! Que tal presentear alguém?
          </li>
          <li v-if="produto.Condicao == 2" class="list-group-item">
            Condição: Como novo! Nunca usado ou pouquíssimo uso.
          </li>
          <li v-if="produto.Condicao == 3" class="list-group-item">
            Condição: Super conservado! Pouquíssimas marcas de uso.
          </li>
          <li v-if="produto.Condicao == 4" class="list-group-item">
            Condição: Conservado, possui marcas de uso.
          </li>

          <li class="list-group-item">
            {{ produto.Marca }}
          </li>

          <li class="list-group-item">Cód. INF{{ produto.idProduto }}</li>
        </ul>

        <h4 v-if="produto.PrecoDescontoInfanzia != 0">
          <p style="font-weight: 900">
            {{
              Math.ceil(
                100 - (produto.PrecoDescontoInfanzia * 100) / produto.Preco
              )
            }}% OFF!
          </p>
          <p style="font-weight: normal">
            <s>R$ {{ produto.Preco.toString().replace(".", ",") }}</s>
            R$
            {{ produto.PrecoDescontoInfanzia.toString().replace(".", ",") }}
          </p>
        </h4>
        <h4 v-else-if="produto.PrecoPromocional != 0">
          <p style="font-weight: 900">
            {{
              Math.ceil(100 - (produto.PrecoPromocional * 100) / produto.Preco)
            }}% OFF!
          </p>
          <p style="font-weight: normal">
            <s>R$ {{ produto.Preco.toString().replace(".", ",") }}</s>
            R$
            {{ produto.PrecoPromocional.toString().replace(".", ",") }}
          </p>
        </h4>
        <h4 v-else-if="produto.PrecoCategoria != 0">
          <p style="font-weight: 900">
            {{
              Math.ceil(100 - (produto.PrecoCategoria * 100) / produto.Preco)
            }}% OFF!
          </p>
          <p style="font-weight: normal">
            R$ <s>{{ produto.Preco.toString().replace(".", ",") }}</s>
            R$
            {{ produto.PrecoCategoria.toString().replace(".", ",") }}
          </p>
        </h4>
        <h4 v-else>
          <p style="font-weight: normal">
            R$ {{ produto.Preco.toString().replace(".", ",") }}
          </p>
        </h4>

        <div class="text-muted">
          <span v-if="$store.getters.user">
            <button
              v-if="
                sacola.produtos.filter(
                  (obj) => obj.idProduto == produto.idProduto
                ).length == 0
              "
              @click="adicionarNaSacola(produto)"
              class="btn btn-success m-0 p-2"
              style="width: 40%; height: 50px; border-radius: 0px; font-size: 16px;"
            >
              <i class="fas fa-shopping-bag"></i> eu quero!
            </button>
            <button
              v-else
              @click="removerDaSacola(produto)"
              class="btn btn-primary m-0 p-2"
              style="width: 40%; height: 50px; border-radius: 0px; font-size: 16px;"
            >
              <i class="fas fa-shopping-bag"></i> remover da sacola
            </button>
            <button
              v-if="confereFavorito(produto.idProduto)"
              @click="adicionarFavoritos(produto.idProduto)"
              class="btn m-0 p-2"
              style="width: 20%; height: 50px; border-radius: 0px; font-size: 16px; color: #f00; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;  border-bottom: 0.5px solid #e0e0e0;"
            >
              <i class="far fa-heart"></i>
            </button>
            <button
              v-else
              @click="removerFavoritos(produto.idProduto)"
              class="btn m-0 p-2"
              style="width: 20%; height: 50px; border-radius: 0px; font-size: 16px; color: #f00; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;  border-bottom: 0.5px solid #e0e0e0;"
            >
              <i class="fas fa-heart"></i>
            </button>
          </span>
          <span v-else>
            <button
              @click="modals.login = true"
              class="btn btn-success m-0 p-2"
              style="width: 40%; height: 50px; border-radius: 0px; font-size: 16px;"
            >
              <i class="fas fa-shopping-bag"></i> eu quero!
            </button>

            <button
              @click="modals.login = true"
              class="btn m-0 p-2"
              style="width: 20%; height: 50px; border-radius: 0px; font-size: 16px; color: #f00; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;  border-bottom: 0.5px solid #e0e0e0;"
            >
              <i class="far fa-heart"></i>
            </button>
          </span>

          <ShareNetwork
            class="btn m-0"
            style="width: 20%; height: 50px; border-radius: 0px; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;  border-bottom: 0.5px solid #e0e0e0; border-right: 0.5px solid #e0e0e0; border-left: 0.5px solid #e0e0e0;"
            network="facebook"
            :title="`\n${produto.NomeProduto} \n`"
            :url="
              `https://www.infanziabrecho.com.br/produto/detalhe?produto=${produto.idProduto}`
            "
            :quote="`${produto.NomeProduto} \n${produto.Descricao}\n`"
            :popup="{ width: 400, height: 200 }"
            hashtags="brechóinfantil,desapegokids"
          >
            <i class="fab fa-facebook fa-2x" style="color: #4267B2"></i>
          </ShareNetwork>

          <ShareNetwork
            class="btn m-0"
            style="width: 20%; height: 50px; border-radius: 0px; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;  border-bottom: 0.5px solid #e0e0e0;  border-right: 0.5px solid #e0e0e0;"
            network="whatsapp"
            :url="
              `https://www.infanziabrecho.com.br/produto/detalhe?produto=${produto.idProduto}`
            "
            :title="`\n${produto.NomeProduto} \n`"
            :description="`\n${produto.Descricao}\n`"
            :popup="{ width: 400, height: 200 }"
          >
            <i class="fab fa-whatsapp fa-2x" style="color: #25D366"></i>
          </ShareNetwork>
        </div>

        <div
          class="mb-2 text-muted mx-1"
          style="font-size: 11px"
          v-if="produto.PessoasComprando == 2"
        >
          <i class="fas fa-fire-alt" style="color: red"></i> Tem
          {{ produto.PessoasComprando-1 }} pessoa com esse produto na sacola...
        </div>
        <div
          class="mb-2 text-muted mx-1"
          style="font-size: 11px"
          v-if="produto.PessoasComprando > 2"
        >
          <i class="fas fa-fire-alt" style="color: red"></i> Tem
          {{ produto.PessoasComprando }} pessoas com esse produto na sacola...
        </div>
      </div>
    </div>

    <div
      v-if="!produto.Foto1 && isLoading == false"
      style="min-height: 70vh;"
      class="h-100 d-flex align-items-center justify-content-center"
    >
      <center>
        <h4>Este produto já foi vendido...</h4>
        <br />
        <b-button href="https://www.infanziabrecho.com.br" variant="primary"
          ><i class="fas fa-store"></i> Voltar para Loja</b-button
        >
      </center>
    </div>

    <div
      class="section m-0 p-2"
      v-if="sugestoes[0]"
      data-background-color="black"
    >
      <h4 class="mt-0 mx-2 pt-2" style="color: #fff">
        Sugestões Infanzia <i class="far fa-laugh-wink"></i>
      </h4>
      <div class="card-deck m-0 p-0 d-flex text-center justify-content-center">
        <div
          class="card mobile-screen mb-4 p-0"
          style="background-color: rgb(0, 0, 0, 0)"
          v-for="sugestao in sugestoes"
          :key="sugestao.idProduto"
        >
          <router-link :to="`/produto/detalhe?produto=${sugestao.idProduto}`">
            <img
              v-lazy="
                'https://www.infanziabrecho.com.br/uploads/' + sugestao.Foto1
              "
              class="rounded img-raised"
            />
          </router-link>

          {{ sugestao.NomeProduto }}

          <span v-if="sugestao.PrecoDescontoInfanzia != 0">
            <p class="m-0 p-0">
              {{
                Math.ceil(
                  100 - (sugestao.PrecoDescontoInfanzia * 100) / sugestao.Preco
                )
              }}% OFF!
            </p>
          </span>
          <span v-else-if="sugestao.PrecoPromocional != 0">
            <p class="m-0 p-0" style="font-weight: bold">
              {{
                Math.ceil(
                  100 - (sugestao.PrecoPromocional * 100) / sugestao.Preco
                )
              }}% OFF!
            </p>
          </span>
          <span v-else-if="sugestao.PrecoCategoria != 0">
            <p class="m-0 p-0">
              {{
                Math.ceil(
                  100 - (sugestao.PrecoCategoria * 100) / sugestao.Preco
                )
              }}% OFF!
            </p>
          </span>
          <span v-else>
            <p class="m-0 p-0" style="font-weight: normal">
              R$
              {{
                sugestao.Preco.toFixed(2)
                  .toString()
                  .replace(".", ",")
              }}
            </p>
          </span>
        </div>
      </div>
    </div>

    <!-- INICIO MODAL LOGIN -->
    <modal
      :show.sync="modals.login"
      :show-close="false"
      headerClasses=" p-0 m-0"
      style="z-index: 9999"
    >
      <n-button
        type="primary p-2 m-0"
        class=""
        round
        icon
        simple
        style="width: 50px; height: 50px"
        @click.native="modals.login = false"
        ><i class="fas fa-times"></i
      ></n-button>
      <Login />
    </modal>
    <!-- FIM MODAL LOGIN -->

    <MainFooter />
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";
import Login from "./admin/Login";
import { Modal, Button, Checkbox } from "@/components";
import ambiente from "../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.produtos.length;
    },
  },

  async mounted() {
    if (!this.$store.getters.user) {
      this.$gtag.event("Visualização - Detalhes Produto");
    } else if (this.$store.getters.user.conta != 1) {
      this.$gtag.event("Visualização - Detalhes Produto");
    }

    try {
      await this.carregarProduto(this.$route.query.produto);
    } catch (err) {
      console.log(err);
      this.$swal.fire({
        toast: true,
        icon: "error",
        title: "Ops... Ocorreu um erro.",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
    try {
      await this.carregarSacola();
    } catch (err) {
      console.log(err);
    }
    try {
      await this.carregarFavoritos();
    } catch (err) {
      console.log(err);
    }
  },
  components: {
    Loading,
    MainNavbar,
    MainFooter,
    Modal,
    Login,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },

  data() {
    return {
      paginaAtual: "produto-detalhe",
      produtos: null,

      produto: {
        Preco: "",
        PrecoDescontoInfanzia: "",
        PrecoPromocional: "",
        PrecoCategoria: "",
        Foto1: "",
        menino: 0,
        menina: 0,
      },

      fotoDetalhe: "",

      sugestoes: [],
      sugestao: null,
      favoritos: null,

      sacola: {
        produtos: [],
      },
      contaProdutosSacola: 0,

      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),

      modals: {
        busca: false,
        login: false,
      },
    };
  },
  metaInfo() {
    return {
      title: `Infanzia Brechó - ${this.produto.NomeProduto}`,
      meta: [
        {
          name: "description",
          content: `${this.produto.NomeProduto}\n${this.produto.Descricao}`,
        },
        { property: "og:title", content: `${this.produto.NomeProduto}` },
        { property: "og:site_name", content: "Infanzia Brechó" },
        {
          property: "og:image",
          content: `https://www.infanziabrecho.com.br/uploads/${this.produto.Foto1}`,
        },
        {
          property: "og:image:width",
          content: "500",
        },
        {
          property: "og:image:height",
          content: "500",
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  watch: {
    "$route.query.produto": {
      handler: function(idProduto) {
        this.carregarProduto(idProduto);
      },
      deep: true,
      immediate: true,
    },

    user: function() {
      this.carregarSacola();
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    confereFavorito(idProduto) {
      if (!this.favoritos) {
        return true;
      } else {
        return (
          this.favoritos.filter((obj) => obj.idProduto == idProduto).length == 0
        );
      }
    },

    async carregarFavoritos() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "favoritos.php", {
            transaction: 2,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.favoritos = response.data.favoritos;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async adicionarFavoritos(idProduto) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Favorito Adicionado");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Favorito Adicionado");
      }

      try {
        await axios
          .post(ambiente + "favoritos.php", {
            transaction: 1,
            idUsuario: this.$store.getters.user.id,
            idProduto: idProduto,
          })
          .then(() => {
            this.carregarFavoritos();
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto adicionado aos favoritos",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    async removerFavoritos(idProduto) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Favorito Removido");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Favorito Removido");
      }

      try {
        await axios
          .post(ambiente + "favoritos.php", {
            transaction: 0,
            idUsuario: this.$store.getters.user.id,
            idProduto: idProduto,
          })
          .then(() => {
            this.carregarFavoritos();
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto removido dos favoritos",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    async carregarProduto(id) {
      this.isLoading = true;
      this.carregarSacola();

      try {
        await axios
          .post(ambiente + "produto.php", {
            idProduto: id,
          })
          .then((response) => {
            this.produto = response.data;
            this.fotoDetalhe = this.produto.Foto1;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.carregarSugestoesInteligentes();
      this.isLoading = false;
    },

    async carregarSugestoesInteligentes() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "sugestoes.php", {
            idFornecedor: this.produto.Fornecedor,
            idProduto: this.produto.idProduto,
            menino: this.produto.Menino,
            menina: this.produto.Menina,
          })
          .then((response) => {
            this.sugestoes = response.data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async adicionarNaSacola(produto) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Produto Adicionado a Sacola");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Produto Adicionado a Sacola");
      }

      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 1,
            produto: produto,
            idUsuario: this.$store.getters.user.id,
          })
          .then(() => {
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto adicionado à sacola",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.carregarSacola();
      this.isLoading = false;
    },

    async removerDaSacola(produto) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Produto Removido da Sacola");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Produto Removido da Sacola");
      }

      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 0,
            produto: produto,
            idUsuario: this.$store.getters.user.id,
          })
          .then(() => {
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Produto removido da sacola",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.carregarSacola();
      this.isLoading = false;
    },

    async carregarSacola() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 2,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.sacola = response.data;
            if (response.data.status != 400) {
              this.contaProdutosSacola = this.sacola.produtos.length;
            } else {
              this.contaProdutosSacola = 0;
              this.sacola.produtos = [];
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
