<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3">
      <h5 style="font-weight: 900">
        Pedidos de resgate pendentes
      </h5>
      
      <center v-if="resgatesACreditar != 'undefined'">
      <span v-if="resgatesACreditar > 0">
        <table class="table" v-if="this.resgates.length != null">
          <tr>
            <td>Data do pedido</td>
            <td>Nome</td>
            <td>Dados Bancários</td>
            <td>Valor a depositar</td>
            <td>Status</td>
            <td>Confirmar depósito</td>
          </tr>
          <tr v-for="resgate in resgates" :key="resgate.idProduto">
            <td v-if="resgate.Status == 1">
              <center>
                {{ resgate.DataPedido }}
              </center>
            </td>

            <td v-if="resgate.Status == 1">
              {{ resgate.NomeFornecedor }} ({{ resgate.idFornecedor }})
            </td>

            <td v-if="resgate.Status == 1">
              <p class="m-0 p-0">Banco: {{ resgate.Banco }}</p>
              <p class="m-0 p-0">Agência: {{ resgate.Agencia }}</p>
              <p class="m-0 p-0">Conta: {{ resgate.Conta }}</p>
              <p class="m-0 p-0">CPF: {{ resgate.CPF }}</p>
              <p class="m-0 p-0">PIX: <span v-if="resgate.pix">{{ resgate.pix }}</span><span v-else>não cadastrado</span></p>
            </td>

            <td v-if="resgate.Status == 1">
              {{
                Math.abs(resgate.Valor)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              }}
            </td>

            <td v-if="resgate.Status == 1">
              <font color="red">Aguardando Depósito</font>
            </td>

            <td v-if="resgate.Status == 1">
              <button class="btn btn-success" @click="alternarDeposito(resgate.idFornecedoresResgate, resgate.Status)">
                <i class="fas fa-check"></i>
              </button>
            </td>
          </tr>
        </table>
        </span>
        <span v-else>
        <h5>Não há pedidos de resgates pendentes neste momento.</h5>
        </span>
      </center>

      <h5 class="mt-5" style="font-weight: 900">
        Resgates feitos
      </h5>

      <center>
        <b-pagination
          v-if="resgatesFeitos > 0"
          class="d-flex justify-content-center"
          v-model="currentPage"
          :total-rows="resgatesFeitos"
          :per-page="perPage"
          aria-controls="resgates"
        ></b-pagination>

        <div
          id="resgates"
          :resgates="resgates"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <table class="table" v-if="this.resgates.length != null">
            <tr>
              <td>Data do pedido</td>
              <td>Nome</td>
              <td>Dados Bancários</td>
              <td>Valor a depositar</td>
              <td>Status</td>
              <td>Cancelar depósito</td>
            </tr>
            <tr
              v-for="resgate in resgates.slice(
                perPage * (currentPage - 1),
                perPage * currentPage
              )"
              :key="resgate.idProduto"
            >
              <td v-if="resgate.Status == 2">
                <center>
                  {{ resgate.DataPedido }}
                </center>
              </td>

              <td v-if="resgate.Status == 2">
                {{ resgate.NomeFornecedor }} ({{ resgate.idFornecedor }})
              </td>

              <td v-if="resgate.Status == 2">
                <p class="m-0 p-0">Banco: {{ resgate.Banco }}</p>
                <p class="m-0 p-0">Agência: {{ resgate.Agencia }}</p>
                <p class="m-0 p-0">Conta: {{ resgate.Conta }}</p>
                <p class="m-0 p-0">CPF: {{ resgate.CPF }}</p>
                <p class="m-0 p-0">PIX: <span v-if="resgate.pix">{{ resgate.pix }}</span><span v-else>não cadastrado</span></p>
              </td>

              <td v-if="resgate.Status == 2">
                {{
                  Math.abs(resgate.Valor)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </td>

              <td v-if="resgate.Status == 2">
                <font color="green">Depósito Efetuado</font>
              </td>

              <td v-if="resgate.Status == 2">
                <button class="btn btn-danger" @click="alternarDeposito(resgate.idFornecedoresResgate, resgate.Status)">
                  <i class="fas fa-times"></i>
                </button>
              </td>
            </tr>
          </table>
        </div>

        <b-pagination
          v-if="resgatesFeitos > 0"
          class="d-flex justify-content-center"
          v-model="currentPage"
          :total-rows="resgatesFeitos"
          :per-page="perPage"
          aria-controls="resgates"
        ></b-pagination>
      </center>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  mounted() {
    this.carregarPedidosResgate();
  },
  components: {
    Loading,
    AdministrativoMenu,
  },

  data() {
    return {
      isLoading: false,
      resgates: [],

      resgatesFeitos: 0,
      resgatesACreditar: 0,

      currentPage: 1,
      perPage: 50,

      fullPage: true,
      onCancel: this.cancelLoading(),
    };
  },
  watch: {
    $route() {
      this.carregarPedidosResgate();
    },

    filtro: {
      handler: function() {
        this.carregarPedidosResgate();
      },
      deep: true,
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarPedidosResgate() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 4.1,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.resgates = response.data
            this.resgatesFeitos = 0
            this.resgatesACreditar = 0
            for (var i = 0; i < this.resgates.length; i++) {
              if (this.resgates[i].Status == 2) {
                this.resgatesFeitos += 1
              } else {
                this.resgatesACreditar += 1
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async alternarDeposito(idFornecedoresResgate, Status){
      this.isLoading = true;
      var aux = 0

      if(Status == 1){
        aux = 2
      } else if (Status == 2){
        aux = 1
      }

      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 4.2,
            idUsuario: this.$store.getters.user.id,
            idFornecedoresResgate: idFornecedoresResgate,
            status: aux,
          })
          .then(() => {
            this.carregarPedidosResgate()
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
/* @import "../../assets/css/simple-sugest-autocomplete.css"; */

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
