<template>
  <div class="mt-5 pt-4" style="min-height: 100vh">
    <MainNavbar :user="user" :currentPage="paginaAtual" />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div v-if="user != null">
      <b-card
        no-body
        class="shadow-none mt-2"
        style="min-width: 100vw"
        v-if="user.conta == 2"
      >
        <b-tabs card justified>
          <b-tab
            title="Produtos"
            style="min-height: 100vh"
            active
            class="m-0 p-0"
          >
            <b-card-text>
              <b-pagination
                v-if="rows > 0"
                class="d-flex justify-content-center"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="produtosPaginados"
              ></b-pagination>

              <div
                id="produtosPaginados"
                :produtosFornecedor="produtosFornecedor"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <center>
                  <table
                    v-if="this.produtosFornecedor.length != null"
                    class="table table-striped"
                    border="0"
                    width="100%"
                    bordercolor="lightgray"
                  >
                    <tr>
                      <td align="center">
                        <b>Foto</b>
                      </td>
                      <td align="center">
                        <b>Produto</b>
                      </td>
                      <td align="center">
                        <b>Preço (R$)</b>
                      </td>
                      <td align="center">
                        <b>Status</b>
                      </td>
                    </tr>
                    <tr
                      v-for="produto in produtosFornecedor.slice(
                        perPage * (currentPage - 1),
                        perPage * currentPage
                      )"
                      :key="produto.idProduto"
                    >
                      <td align="center" class="m-0 p-1">
                        <img
                          class="rounded-circle"
                          style="height: 60px; width: 60px"
                          :src="
                            'https://www.infanziabrecho.com.br/uploads/' +
                              produto.Foto1
                          "
                        />
                      </td>
                      <td align="center" class="m-0 p-0">
                        {{ produto.NomeProduto }}
                        <p style="font-size: 12px">
                          (cód. {{ produto.idProduto }})
                        </p>
                      </td>
                      <td
                        v-if="produto.PrecoDescontoInfanzia != 0"
                        align="center"
                        class="m-0 p-0"
                      >
                        <s>
                          {{
                            parseFloat(produto.Preco)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}</s
                        >
                        <br />
                        {{
                          parseFloat(produto.PrecoDescontoInfanzia)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </td>
                      <td
                        v-else-if="produto.PrecoPromocional != 0"
                        align="center"
                        class="m-0 p-0"
                      >
                        <s>
                          {{
                            parseFloat(produto.Preco)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}</s
                        >
                        <br />
                        {{
                          parseFloat(produto.PrecoPromocional)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </td>
                      <td
                        v-else-if="produto.PrecoCategoria != 0"
                        align="center"
                        class="m-0 p-0"
                      >
                        <s>
                          {{
                            parseFloat(produto.Preco)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}</s
                        >
                        <br />
                        {{
                          parseFloat(produto.PrecoCategoria)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </td>
                      <td v-else align="center">
                        {{
                          parseFloat(produto.Preco)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </td>
                      <td
                        v-if="produto.Status == 0"
                        align="center"
                        class="m-0 p-0"
                      >
                        <font color="green">ANUNCIADO</font>
                      </td>
                      <td
                        v-if="produto.Status == 1"
                        align="center"
                        class="m-0 p-0"
                      >
                        <font color="orange">SACOLA</font>
                      </td>
                      <td
                        v-if="produto.Status == 2 && produto.Credito != 0"
                        align="center"
                        class="m-0 p-0"
                      >
                        <font color="blue">VENDIDO</font><br />
                        {{ produto.DataSacola }}
                      </td>
                      <td
                        v-if="produto.Status == 2 && produto.Credito == 0"
                        align="center"
                        class="m-0 p-0"
                      >
                        <font color="purple">PROCESSANDO VENDA</font><br />
                        {{ produto.DataSacola }}
                      </td>
                      <td
                        v-if="produto.Status == 3"
                        align="center"
                        class="m-0 p-0"
                      >
                        <font color="red">PENDENTE</font>
                      </td>
                      <td
                        v-if="produto.Status == 4"
                        align="center"
                        class="m-0 p-0"
                      >
                        <font color="gray">DEVOLUÇÃO</font>
                      </td>
                    </tr>
                  </table>
                </center>
              </div>

              <b-pagination
                v-if="rows > 0"
                class="d-flex justify-content-center"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="produtosPaginados"
              ></b-pagination>
            </b-card-text>
          </b-tab>

          <b-tab title="Minha Conta" style="min-height: 100vh" class="m-0 p-0">
            <b-card-text class="m-4">
              <div
                id="extrato"
                class="mb-4 p-0 border"
                style="overflow-y: auto; max-height: 400px;"
              >
                <table
                  class="table table-striped"
                  v-if="this.extratoFornecedor.length != null"
                >
                  <thead>
                    <tr>
                      <th
                        class="px-0 py-1 text-center"
                        style="position: sticky; top: 0; background-color: white; border: 1px;"
                      >
                        <font style="font-size: 10px;">Data</font>
                      </th>
                      <th
                        class="px-0 py-1 text-center"
                        style="position: sticky; top: 0; background-color: white; border: 1px;"
                      >
                        <font style="font-size: 10px;">Descrição</font>
                      </th>
                      <th
                        class="px-0 py-1 text-center"
                        style="position: sticky; top: 0; background-color: white; border: 1px;"
                      >
                        <font style="font-size: 10px;">Produto</font>
                      </th>
                      <th
                        class="px-0 py-1 text-center"
                        style="position: sticky; top: 0; background-color: white; border: 1px;"
                      >
                        <font style="font-size: 10px;">Valor</font>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in extratoFornecedor"
                      :key="item.idFornecedoresCreditos"
                    >
                      <td class="text-center">
                        <font style="font-size: 10px;">
                          {{ item.Data }}
                        </font>
                      </td>
                      <td v-if="item.TipoCredito == 1" class="text-center">
                        <font style="color: green; font-size: 10px;"
                          >acumulado</font
                        >
                      </td>
                      <td v-else-if="item.TipoCredito == 2" class="text-center">
                        <font style="color: red; font-size: 10px;"
                          >utilizado em compra</font
                        >
                      </td>
                      <td v-else-if="item.TipoCredito == 3" class="text-center">
                        <font style="color: orange; font-size: 10px;"
                          >resgatado</font
                        >
                      </td>
                      <td v-if="item.TipoCredito == 1" class="text-center">
                        <font style="font-size: 10px;">
                          {{ item.idProduto }}
                        </font>
                      </td>
                      <td v-else class="text-center">
                        ---
                      </td>
                      <td class="text-right">
                        <font style="font-size: 10px;">
                          {{
                            item.Credito.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}
                        </font>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">Saldo</td>
                      <td>
                        {{
                          parseFloat(Math.abs(saldo[0]))
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h5>
                Saldo: R$
                {{
                  parseFloat(Math.abs(saldo[0]))
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </h5>

              <p>
                Você pode utilizar esse valor como crédito em compras no próprio
                site a qualquer momento, ou enviar uma ordem de resgate entre os
                dias 1 e 5 de qualquer mês.
              </p>
              <ul>
                <li>
                  Para utilizar o valor como crédito em suas compras, basta
                  comprar! Os créditos necessários serão utilizados
                  automagicamente na sua sacola.
                </li>
                <li>
                  Para ter o valor depositado em sua conta bancária, basta
                  informar abaixo os seus dados bancários (caso ainda não tenha
                  informado) e pressionar o botão "resgatar valor". Feito isso
                  você receberá um e-mail de confirmação e o valor será
                  depositado em sua conta entre os dias 6 e 10 do mês do
                  resgate.
                </li>
              </ul>
              <div v-if="contaAtual[1] != ''">
                <form v-on:submit.prevent="gravarDadosBancarios()">
                  <h5>Meus dados bancários:</h5>
                  <table
                    class="table table-striped"
                    border="0"
                    width="100%"
                    bordercolor="lightgray"
                  >
                    <tr>
                      <td>PIX</td>
                      <td v-if="editarConta == false">
                        {{ contaAtual[6] }}
                      </td>
                      <td v-else>
                        <input
                          class="form-control mb-2"
                          type="text"
                          :placeholder="contaAtual[6]"
                          v-model="conta.pix"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Banco</td>
                      <td v-if="editarConta == false">
                        {{ contaAtual[1] }}
                      </td>
                      <td v-else>
                        <input
                          class="form-control mb-2"
                          type="text"
                          :placeholder="contaAtual[1]"
                          v-model="conta.banco"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Agência</td>
                      <td v-if="editarConta == false">
                        {{ contaAtual[2] }}
                      </td>
                      <td v-else>
                        <input
                          class="form-control mb-2"
                          type="text"
                          :placeholder="contaAtual[2]"
                          v-model="conta.agencia"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Conta</td>
                      <td v-if="editarConta == false">
                        {{ contaAtual[3] }}
                      </td>
                      <td v-else>
                        <input
                          class="form-control mb-2"
                          type="text"
                          :placeholder="contaAtual[3]"
                          v-model="conta.conta"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>CPF</td>
                      <td v-if="editarConta == false">
                        {{ contaAtual[4] }}
                      </td>
                      <td v-else>
                        <input
                          class="form-control mb-2"
                          type="text"
                          :placeholder="contaAtual[4]"
                          v-model="conta.cpf"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Observações</td>
                      <td v-if="editarConta == false">
                        <span v-if="contaAtual[5]">{{ contaAtual[5] }}</span>
                        <span v-else> Nenhuma observação cadastrada. </span>
                      </td>
                      <td v-else>
                        <input
                          class="form-control mb-2"
                          type="text"
                          :placeholder="contaAtual[5]"
                          v-model="conta.obs"
                        />
                      </td>
                    </tr>
                  </table>

                  <center v-if="editarConta == false">
                    <span class="btn btn-primary" @click="editarConta = true">
                      <i class="far fa-edit"></i> Atualizar Dados Bancários
                    </span>
                  </center>
                  <center v-else>
                    <button
                      class="btn btn-primary"
                      @click="editarConta = false"
                    >
                      <i class="fas fa-undo-alt"></i> Cancelar Edição
                    </button>
                    <button class="btn btn-success" type="submit">
                      <i class="far fa-save"></i> Gravar Alterações
                    </button>
                  </center>
                </form>
                <center>
                  <span
                    v-if="
                      confereDataParaResgate(Date()) && Math.abs(saldo[0]) > 0
                    "
                    class="btn btn-success"
                    @click="efetuarResgate(saldo[0])"
                  >
                    <i class="fas fa-hand-holding-usd"></i> Resgatar Valor
                  </span>

                  <button v-else class="btn btn-successs" disabled>
                    <i class="fas fa-hand-holding-usd"></i> Resgatar Valor
                  </button>
                  <p style="font-weight: 500">
                    <u>ATENÇÃO</u>: valor disponível para resgate somente entre
                    os dias 1 e 5 de cada mês. Hoje é dia
                    {{ formatDate(Date()) }}.
                  </p>
                </center>
              </div>
              <div v-else>
                <!-- Se fornecedor não tiver conta bancária cadastrada-->
                <h5>
                  Para efetuar o resgate é necessário que informe seus dados
                  bancários:
                </h5>
                <form v-on:submit.prevent="gravarDadosBancarios()">
                  Banco
                  <input
                    class="form-control mb-2"
                    type="text"
                    placeholder="Informar o nome do banco"
                    v-model="conta.banco"
                    required
                  />
                  Agência
                  <input
                    class="form-control mb-2"
                    type="text"
                    placeholder="Informar o número da agência"
                    v-model="conta.agencia"
                    required
                  />
                  Conta
                  <input
                    class="form-control mb-2"
                    type="text"
                    placeholder="Informar o número da conta"
                    v-model="conta.conta"
                    required
                  />
                  CPF
                  <input
                    class="form-control mb-2"
                    type="text"
                    placeholder="Informar o CPF do titular da conta"
                    v-model="conta.cpf"
                    required
                  />
                  Observações
                  <input
                    class="form-control mb-2"
                    type="text"
                    placeholder="Ex.: Conta Poupança, nome do titular..."
                    v-model="conta.obs"
                  />

                  <center>
                    <button class="btn btn-success" type="submit">
                      <i class="far fa-save"></i> Cadastrar Conta
                    </button>
                  </center>
                </form>
              </div>
              <div></div>
            </b-card-text>
          </b-tab>
          <b-tab
            title="Resgates em Andamento"
            style="min-height: 100vh"
            class="m-0 p-0"
          >
            <b-card-text class="m-4">
              <center>
                <h5 v-if="statusResgate.length == 0" class="mt-5">
                  Você ainda não possui nenhum pedido de resgate.
                </h5>

                <table
                  v-if="statusResgate.length > 0"
                  class="table table-striped"
                  border="0"
                  width="100%"
                  bordercolor="lightgray"
                >
                  <tr>
                    <td align="center">
                      <b>Data do Pedido</b>
                    </td>
                    <td align="center">
                      <b>Valor (R$)</b>
                    </td>
                    <td align="center">
                      <b>Status</b>
                    </td>
                  </tr>

                  <tr
                    v-for="item in statusResgate"
                    :key="item.idFornecedoresResgate"
                  >
                    <td align="center" class="m-0 p-1">
                      {{ item.DataPedido }}
                    </td>

                    <td align="center" class="m-0 p-1">
                      {{
                        parseFloat(Math.abs(item.Valor))
                          .toFixed(2)
                          .toString()
                          .replace(".", ",")
                      }}
                    </td>
                    <td v-if="item.Status == 1" align="center" class="m-0 p-1">
                      <font color="orange">Resgate em Andamento*</font>
                    </td>
                    <td v-if="item.Status == 2" align="center" class="m-0 p-1">
                      <font color="green">Depósito Efetuado</font>
                    </td>
                  </tr>
                </table>

                <small
                  >* O valor será creditado na sua conta bancária cadastrada até
                  o dia 10 do mês corrente. Acompanhe o andamento nesta
                  página.</small
                >
              </center>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-card v-else class="shadow-none" style="min-height: 100vh">
        <h4 style="text-align: center" class="mt-5 pt-5">
          Área restrita para fornecedores.
        </h4>
        <p class="text-muted" style="text-align: center">
          Verifique o seu login.
        </p>
      </b-card>
    </div>
    <div v-else style="min-height: 100vh" class="mt-5 pt-5">
      <h4 style="text-align: center" class="mt-5 pt-5">
        É preciso estar logado(a) para acessar o painel do fornecedor.
      </h4>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import MainFooter from "./MainFooter";
import MainNavbar from "./MainNavbar";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import ambiente from "../variables/variables.js";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.produtosFornecedor.length;
    },
    rowsExtrato() {
      return this.extratoFornecedor.length;
    },
  },

  async mounted() {
    if (this.$store.getters.user != null) {
      try {
        await this.carregarProdutosFornecedor();
      } catch (err) {
        console.log(err);
      }
      try {
        await this.carregarContaFornecedor();
      } catch (err) {
        console.log(err);
      }
    }
  },

  components: {
    Loading,
    MainNavbar,
    MainFooter,
  },

  data() {
    return {
      paginaAtual: "fornecedor",

      produtosFornecedor: [],
      extratoFornecedor: [],
      saldo: 0,
      perPage: 24,
      currentPage: 1,
      currentPageExtrato: 1,
      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),

      conta: {
        banco: null,
        agencia: null,
        conta: null,
        cpf: null,
        obs: null,
      },

      editarConta: false,

      contaAtual: [],

      statusResgate: [],
    };
  },
  watch: {
    $route() {
      this.carregarProdutosFornecedor();
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    formatDate(date) {
      return moment(String(date)).format("DD/MM/YY");
    },

    confereDataParaResgate(date) {
      const dia = moment(String(date)).format("DD");
      if (dia >= 1 && dia <= 5) {
        return true;
      } else {
        return false;
      }
    },

    async carregarContaFornecedor() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 2,
            email: this.$store.getters.user.email,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            if (response.data.extrato) {
              this.extratoFornecedor = response.data.extrato;
            } else {
              this.extratoFornecedor = [];
            }
            if (response.data.conta) {
              this.contaAtual = response.data.conta;
            } else {
              this.contaAtual = [];
            }
            if (response.data.saldo) {
              this.saldo = response.data.saldo;
            } else {
              this.saldo = [];
            }
            if (response.data.statusResgate) {
              this.statusResgate = response.data.statusResgate;
            } else {
              this.statusResgate = [];
            }

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    async carregarProdutosFornecedor() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 1,
            email: this.$store.getters.user.email,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            if (response.data.produtos) {
              this.produtosFornecedor = response.data.produtos;
            } else {
              this.produtosFornecedor = [];
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    async gravarDadosBancarios() {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Fornecedor - Dados bancários salvos");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Fornecedor - Dados bancários salvos");
      }

      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 3,
            conta: this.conta,
            email: this.$store.getters.user.email,
            idUsuario: this.$store.getters.user.id,
          })
          .then(() => {
            this.editarConta = false;
            this.carregarContaFornecedor();
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    efetuarResgate(saldo) {
      this.$swal({
        title: "Efetuar resgate",
        text: "Tem certeza que deseja efetuar o pedido de resgate?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Resgatar",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;

          if (!this.$store.getters.user) {
            this.$gtag.event("Fornecedor - Resgate requerido");
          } else if (this.$store.getters.user.conta != 1) {
            this.$gtag.event("Fornecedor - Resgate requerido");
          }

          try {
            axios
              .post(ambiente + "fornecedor.php", {
                transaction: 4,
                saldo: parseFloat(saldo).toFixed(2),
                email: this.$store.getters.user.email,
                idUsuario: this.$store.getters.user.id,
              })
              .then(() => {
                this.isLoading = false;
                this.enviaEmailConfirmacaoResgate(saldo);
                this.carregarContaFornecedor();

                this.$swal.fire({
                  toast: true,
                  icon: "success",
                  title:
                    "Resgate efetuado com sucesso!\nVerifique o seu e-mail para mais detalhes.",
                  showConfirmButton: false,
                  timer: 5000,
                  timerProgressBar: true,
                });
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
                this.$swal.fire({
                  toast: true,
                  icon: "error",
                  title: "Ops... Ocorreu um erro.",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
          this.isLoading = false;
        }
      });
    },

    async enviaEmailConfirmacaoResgate(saldo) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "mail.php", {
            email: this.$store.getters.user.email,
            titulo: "Resgate de Valores!",
            mensagem:
              "Olá, " +
              this.$store.getters.user.fullname +
              "! Recebemos o seu pedido de resgate do valor de R$ " +
              parseFloat(saldo)
                .toFixed(2)
                .toString()
                .replace(".", ",") +
              ".<br>Efetuaremos o depósito em sua conta bancária cadastrada entre os dias 6 e 10 deste mês.<br><br>Dados bancários para depósito:<br>PIX: " +
              this.contaAtual[6] +
              "<br>Banco: " +
              this.contaAtual[1] +
              "<br>Agência: " +
              this.contaAtual[2] +
              "<br>Conta: " +
              this.contaAtual[3] +
              "<br>CPF: " +
              this.contaAtual[4] +
              "<br>Obs.: " +
              this.contaAtual[5] +
              '	<br>Caso exista algum erro nos dados bancários acima, informar imediatamente através de uma resposta a este e-mail.<br>Você poderá acompanhar o andamento do seu pedido de resgate em nosso site, através da aba "Resgates em Andamento", dentro do seu painel de fornecedor.<br>Qualquer dúvida entre em contato respondendo a este e-mail.',
          })
          .then(() => {})
          .catch((error) => {
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "error",
              title:
                "Ops, ocorreu um erro no envio de e-mail de confirmação. Por favor entre em contato conosco para enviarmos novamente os detalhes da sua confirmação de resgate.",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });

            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
