import Vue from "vue";
import VueRouter from "vue-router";
import MainFooter from "./layout/MainFooter.vue";

import AdministrativoCadastrarProduto from "./layout/admin/AdministrativoCadastrarProduto.vue";
import AdministrativoExibirProdutos from "./layout/admin/AdministrativoExibirProdutos.vue";
import AdministrativoDetalheProduto from "./layout/admin/AdministrativoDetalheProduto.vue";
import AdministrativoVendas from "./layout/admin/AdministrativoVendas.vue";
import AdministrativoExibirFornecedores from "./layout/admin/AdministrativoExibirFornecedores.vue";
import AdministrativoDetalheFornecedor from "./layout/admin/AdministrativoDetalheFornecedor.vue";
import AdministrativoPedidosResgate from "./layout/admin/AdministrativoPedidosResgate.vue";
import AdministrativoCreditarVendas from "./layout/admin/AdministrativoCreditarVendas.vue";
import AdministrativoEmail from "./layout/admin/AdministrativoEmail.vue";
import AdministrativoDescontos from "./layout/admin/AdministrativoDescontos.vue";
import AdministrativoExibirClientes from "./layout/admin/AdministrativoExibirClientes.vue";

import AdministrativoBiVendas from "./layout/admin/AdministrativoBiVendas.vue";
import AdministrativoBiProdutos from "./layout/admin/AdministrativoBiProdutos.vue";

// import CampanhaDiaCriancas from "./layout/campanha/CampanhaDiaCriancas.vue";

import Produtos from "./layout/Produtos.vue";
import DetalheProduto from "./layout/DetalheProduto.vue";
import Sacola from "./layout/Sacola.vue";
import Compras from "./layout/Compras.vue";
import DadosPessoais from "./layout/DadosPessoais.vue";
import EnderecosCadastrados from "./layout/EnderecosCadastrados.vue";
import Favoritos from "./layout/Favoritos.vue";
import Fornecedor from "./layout/Fornecedor.vue";

Vue.use(VueRouter);

export const router = new VueRouter({

  mode: "history",
  
  scrollBehavior(to, from, savedPosition) {
    console.log(to.name);
    console.log(from);
    console.log(savedPosition);
    console.log(from.hash);

    if (to.name !== 'index') {
      setTimeout(() => {
        document.getElementById("app").scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
      }, 1000);
    } else if (from.hash) {
      var x = from.hash.replace("#", "");
      setTimeout(() => {
        document.getElementById(x).scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
      }, 50);
    } else {
      setTimeout(() => {
        document.getElementById("app").scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
      }, 50);
    }
  },

  linkExactActiveClass: "active",
  
  routes: [
    {
      path: "/",
      name: "index",
      components: { default: Produtos, footer: MainFooter },
    },
    {
      path: "/produto/detalhe",
      name: "produto-detalhe",
      components: { default: DetalheProduto },
    },
    {
      path: "/sacola",
      name: "sacola",
      components: { default: Sacola },
    },
    {
      path: "/compras",
      name: "compras",
      components: { default: Compras },
    },
    {
      path: "/dados-pessoais",
      name: "dados-pessoais",
      components: { default: DadosPessoais },
    },
    {
      path: "/enderecos",
      name: "enderecos",
      components: { default: EnderecosCadastrados },
    },
    {
      path: "/favoritos",
      name: "favoritos",
      components: { default: Favoritos },
    },
    {
      path: "/fornecedor",
      name: "fornecedor",
      components: { default: Fornecedor },
    },
    {
      path: "/administrativo/cadastrar-produto",
      name: "administrativo-cadastrar-produto",
      components: { default: AdministrativoCadastrarProduto },
    },
    {
      path: "/administrativo/exibir-produtos",
      name: "exibir-produtos",
      components: { default: AdministrativoExibirProdutos },
    },
    {
      path: "/administrativo/produto/detalhe",
      name: "administrativo-produto-detalhe",
      components: { default: AdministrativoDetalheProduto },
    },
    {
      path: "/administrativo/vendas",
      name: "administrativo-vendas",
      components: { default: AdministrativoVendas },
    },
    {
      path: "/administrativo/exibir-fornecedores",
      name: "exibir-fornecedores",
      components: { default: AdministrativoExibirFornecedores },
    },
    {
      path: "/administrativo/fornecedor/detalhe",
      name: "administrativo-fornecedor-detalhe",
      components: { default: AdministrativoDetalheFornecedor },
    },
    {
      path: "/administrativo/pedidos-resgate",
      name: "administrativo-pedidos-resgate",
      components: { default: AdministrativoPedidosResgate },
    },
    {
      path: "/administrativo/creditar-vendas",
      name: "administrativo-creditar-vendas",
      components: { default: AdministrativoCreditarVendas },
    },
    {
      path: "/administrativo/email",
      name: "administrativo-email",
      components: { default: AdministrativoEmail },
    },
    {
      path: "/administrativo/descontos",
      name: "administrativo-descontos",
      components: { default: AdministrativoDescontos },
    },
    {
      path: "/administrativo/bi-vendas",
      name: "administrativo-bi-vendas",
      components: { default: AdministrativoBiVendas },
    },
    {
      path: "/administrativo/bi-produtos",
      name: "administrativo-bi-produtos",
      components: { default: AdministrativoBiProdutos },
    },
    {
      path: "/administrativo/exibir-clientes",
      name: "administrativo-exibir-clientes",
      components: { default: AdministrativoExibirClientes },
    },
    // CAMPANHA DIA DAS CRIANÇAS
    // {
    //   path: "/campanha",
    //   name: "campanha",
    //   components: { default: CampanhaDiaCriancas },
    // },

    

  ],
});
