<template>
  <div class="px-0 mx-0" style="min-height: 100vh; padding-top: 48px">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <!--
    
    <div class="section pt-5 px-0 mb-0 pb-2" style="background-color: #fff3e6">
      <div class="card-deck mx-1 justify-content-center">

        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
        <a href="#produtos" @click="filtro.menina=true">
          <img class="card-img-top" src="img/campanha/1.png" style="border-radius: 10%;" />
          </a>
        </div>

        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/2.png" style="border-radius: 10%;" />
        </div>

        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/3.png" style="border-radius: 10%;" />
        </div>

        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/4.png" style="border-radius: 10%;" />
        </div>

        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/5.png" style="border-radius: 10%;" />
        </div>
        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/6.png" style="border-radius: 10%;" />
        </div>
        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/7.png" style="border-radius: 10%;" />
        </div>
        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/8.png" style="border-radius: 10%;" />
        </div>
        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/9.png" style="border-radius: 10%;" />
        </div>
        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/10.png" style="border-radius: 10%;" />
        </div>
        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/11.png" style="border-radius: 10%;" />
        </div>
        <div class="card p-0 m-2 d-flex align-items-center" style="border-radius: 10%;">
          <img class="card-img-top" src="img/campanha/12.png" style="border-radius: 10%;" />
        </div>
      </div>
    </div>
    -->

    <!-- 

CAMPANHA DIA DAS CRIANÇAS

    <div class="section pt-4 pb-0 mt-2" style="background-color: #ccc">
      <b-carousel id="carousel-1" v-model="slide" :interval="3000">
        <router-link
              :to="
                `/campanha?evento=dia-das-criancas`
              "
            >
          <b-carousel-slide
            img-src="https://www.infanziabrecho.com.br/uploads/campanha-dia-das-criancas.png"
          ></b-carousel-slide>
        </router-link>
      </b-carousel>
    </div>
    -->

    <div class="row px-1 mx-1 pt-4 mt-2">
      <div
        class="col-sm-4 d-flex justify-content-center align-items-center mt-2"
      >
        <div class="form-group row m-0 p-0">
          <label
            for="staticEmail"
            class="col-sm-4 col-form-label d-flex justify-content-start align-items-center m-0 p-0"
            >Buscar</label
          >
          <div class="col-sm-8  m-0 p-0">
            <input
              class="form-control"
              type="text"
              name="busca"
              style="height: 30px;"
              v-model="filtro.busca"
              placeholder="Digite aqui..."
              autocomplete="off"
            />
          </div>
        </div>
      </div>

      <div
        class="col-sm-4 d-flex justify-content-center align-items-center mt-2"
      >
        <div class="form-group row m-0 p-0">
          <label
            class="col-sm-6 col-form-label d-flex justify-content-start align-items-center m-0 p-0"
            >Ordenar por</label
          >
          <div class="col-sm-6 m-0 p-0">
            <select
              v-model="filtro.ordenar"
              class="form-control p-1"
              style="height: 32px;min-width: 133px;"
            >
              <option value="1" selected>destaques do dia</option>
              <option value="3">menor preço</option>
              <option value="2">maior preço</option>
              <option value="4">novidades</option>
            </select>
          </div>
        </div>
      </div>

      <div
        class="col-sm-4 d-flex justify-content-center align-items-center mt-2"
      >
        <b-dropdown
          id="dropdown-1"
          text="Filtrar"
          variant="success"
          size="md"
          class="m-md-0"
          style="width: 130px; font-size: 20px;"
        >
          <b-dropdown-item
            @click="exibirFiltros('genero', 'aplicarFiltroGenero')"
            style="color: #503626"
            ><span
              ><i class="fas fa-venus-mars"></i> Gênero</span
            ></b-dropdown-item
          >
          <b-dropdown-item
            @click="exibirFiltros('idade', 'aplicarFiltroIdade')"
            style="color: #503626"
            ><span><i class="fas fa-baby"></i> Idade</span></b-dropdown-item
          >
          <b-dropdown-item
            @click="exibirFiltros('categoria', 'aplicarFiltroCategoria')"
            style="color: #503626"
            ><span
              ><i class="far fa-folder-open"></i> Categoria</span
            ></b-dropdown-item
          >
          <b-dropdown-item
            @click="exibirFiltros('calcado', 'aplicarFiltroCalcado')"
            style="color: #503626"
            ><span
              ><i class="fas fa-shoe-prints"></i> Calçados</span
            ></b-dropdown-item
          >
          <b-dropdown-item
            @click="exibirFiltros('marca', 'aplicarFiltroMarca')"
            style="color: #503626"
            ><span><i class="fas fa-tag"></i> Marca</span></b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <div class="pt-3 d-flex justify-content-end"></div>

    <b-pagination
      v-if="rows > 0"
      class="d-flex justify-content-center"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="produtosPaginados"
    ></b-pagination>

    <!-- BÕTOES DE REMOÇÃO DE FILTROS -->
    <span v-for="(item, index) in filtro.categorias" :key="index">
      <button
        v-if="item != 'Calçados'"
        class="btn btn-sm"
        @click="filtro.categorias.splice(filtro.categorias.indexOf(item), 1)"
        style="background-color: #964B00"
      >
        <i class="fas fa-times"></i> {{ item }}
      </button>
    </span>

    <span v-if="filtro.busca != null">
      <button
        v-if="filtro.busca.length > 0"
        class="btn btn-sm"
        @click="filtro.busca = null"
        style="background-color: #669999"
      >
        <i class="fas fa-times"></i> Busca: {{ filtro.busca }}
      </button>
    </span>

    <button
      v-if="filtro.idade.length > 0"
      class="btn btn-sm"
      @click="filtro.idade = []"
      style="background-color: #000000"
    >
      <i class="fas fa-times"></i> Idade(s):
      <span
        v-for="(item, index) in filtro.idade"
        :key="index"
        style="color: #fff; font-weight: bold"
      >
        |
        <span v-if="item == 0">RN a 3 meses</span>
        <span v-if="item == 0.3">3 a 6 meses</span>
        <span v-if="item == 0.6">6 a 9 meses</span>
        <span v-if="item == 0.9">9 a 12 meses</span>
        <span v-if="item == 1">1 ano</span>
        <span v-if="item == 1.6">18 meses</span>
        <span v-if="item > 1.6">{{ parseInt(item) }} anos</span>
      </span>
    </button>

    <button
      v-if="filtro.categorias.includes('Calçados')"
      class="btn btn-sm"
      @click="
        filtro.categorias.splice(filtro.categorias.indexOf('Calçados'), 1);
        filtro.calcado = [];
      "
      style="background-color: #800000"
    >
      <i class="fas fa-times"></i> Calçado(s):
      <span
        v-for="(item, index) in filtro.calcado"
        :key="index"
        style="color: #fff; font-weight: bold"
      >
        |
        {{ parseInt(item) }}
      </span>
    </button>

    <button
      v-if="filtro.brand.length > 0"
      class="btn btn-sm"
      @click="filtro.brand = []"
      style="background-color: #ff634d"
    >
      <i class="fas fa-times"></i> Marca(s):
      <span
        v-for="(item, index) in filtro.brand"
        :key="index"
        style="color: #000; font-weight: bold"
      >
        | {{ item }}
      </span>
    </button>

    <div
      style="min-height: 20vh"
      class="container py-5"
      v-if="this.produtos.length == null"
    >
      <h4 style="text-align: center">
        Não há produtos com o filtro selecionado... Por favor, tente novamente.
      </h4>
    </div>
    <div
      id="produtosPaginados"
      :produtos="produtos"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <center>
        <div v-if="this.produtos.length != null" class="card-deck m-1 p-1">
          <div
            class="card mb-4 mobile-screen"
            v-for="produto in produtos.slice(
              perPage * (currentPage - 1),
              perPage * currentPage
            )"
            :key="produto.idProduto"
            :id="produto.idProduto"
          >
            <router-link
              :to="
                `/produto/detalhe?produto=${produto.idProduto}#${produto.idProduto}`
              "
            >
              <div style="position: relative; top: 0; left: 0;">
                <img
                  class="card-img-top m-0 p-0"
                  v-lazy="
                    'https://www.infanziabrecho.com.br/uploads/' + produto.Foto1
                  "
                />
                <i
                  class="fas fa-search-plus m-0 p-0"
                  style="position: absolute; bottom: 10px; right: 10px; color: #fff;"
                ></i>
                <i
                  v-if="produto.Condicao == 1"
                  class="fas fa-star m-0 p-0"
                  style="position: absolute; top: 10px; left: 10px; color: #ffff00;"
                ></i>
              </div>
            </router-link>

            <div class="card-body mb-0 py-0 px-1">
              <router-link
                :to="`/produto/detalhe?produto=${produto.idProduto}`"
              >
                <h5 class="card-title m-0 p-0" style="font-weight: 900">
                  {{ produto.NomeProduto }}
                </h5>
              </router-link>
              <ul class="list-group list-group-flush">
                <li class="list-group-item m-1 p-1">{{ produto.Tamanho }}</li>

                <li class="list-group-item m-1 p-1">{{ produto.Descricao }}</li>
              </ul>
            </div>

            <div class="mb-2">
              <h5 v-if="produto.PrecoDescontoInfanzia != 0" class="m-0 p-0">
                <p class="m-0 p-0" style="font-weight: 900">
                  {{
                    Math.ceil(
                      100 -
                        (produto.PrecoDescontoInfanzia * 100) / produto.Preco
                    )
                  }}% OFF
                </p>
                <p class="m-0 p-0" style="font-weight: normal">
                  <s
                    >R$
                    {{
                      produto.Preco.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}</s
                  >
                </p>
                <p class="m-0 p-0" style="font-weight: normal">
                  R$
                  {{
                    produto.PrecoDescontoInfanzia.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </p>
              </h5>
              <h5 v-else-if="produto.PrecoPromocional != 0" class="m-0 p-0">
                <p class="m-0 p-0" style="font-weight: 900">
                  {{
                    Math.ceil(
                      100 - (produto.PrecoPromocional * 100) / produto.Preco
                    )
                  }}% OFF!
                </p>
                <p class="m-0 p-0" style="font-weight: normal">
                  <s
                    >R$
                    {{
                      produto.Preco.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}</s
                  >

                  R$
                  {{
                    produto.PrecoPromocional.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </p>
              </h5>
              <h5 v-else-if="produto.PrecoCategoria != 0" class="m-0 p-0">
                <p class="m-0 p-0">
                  {{
                    Math.ceil(
                      100 - (produto.PrecoCategoria * 100) / produto.Preco
                    )
                  }}% OFF
                </p>
                <p class="m-0 p-0" style="font-weight: normal">
                  R$
                  <s>{{
                    produto.Preco.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}</s>
                </p>
                <p class="m-0 p-0" style="font-weight: normal">
                  R$
                  {{
                    produto.PrecoCategoria.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </p>
              </h5>
              <h5 v-else class="m-0 p-0">
                <p class="m-0 p-0" style="font-weight: normal">
                  R$
                  {{
                    produto.Preco.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </p>
              </h5>
            </div>

            <span v-if="$store.getters.user">
              <div
                class="mb-2 text-muted mx-1"
                style="font-size: 11px"
                v-if="produto.PessoasComprando == 2"
              >
                <i class="fas fa-fire-alt" style="color: red"></i> Tem
                {{ produto.PessoasComprando - 1 }} pessoa com esse produto na
                sacola!
              </div>
              <div
                class="mb-2 text-muted mx-1"
                style="font-size: 11px"
                v-if="produto.PessoasComprando > 2"
              >
                <i class="fas fa-fire-alt" style="color: red"></i> Tem
                {{ produto.PessoasComprando }} pessoas com esse produto na
                sacola!
              </div>
            </span>

            <div class="d-flex">
              <span v-if="$store.getters.user" class="flex-grow-1">
                <button
                  v-if="
                    sacola.produtos.filter(
                      (obj) => obj.idProduto == produto.idProduto
                    ).length == 0
                  "
                  @click="
                    colocarNaSacola(produto);
                    carregarTodosProdutos(filtro);
                  "
                  class="btn btn-success m-0 p-0"
                  style="width: 50%; height: 50px; border-radius: 0px; font-size: 16px;"
                >
                  <i class="fas fa-shopping-bag"></i> quero!
                </button>
                <button
                  v-else
                  @click="
                    removerDaSacola(produto);
                    carregarTodosProdutos(filtro);
                  "
                  class="btn btn-primary m-0 p-0"
                  style="width: 50%; height: 50px; border-radius: 0px; font-size: 14px;"
                >
                  <i class="fas fa-times"></i> remover
                </button>
                <button
                  v-if="confereFavorito(produto.idProduto)"
                  @click="adicionarFavoritos(produto.idProduto)"
                  class="btn m-0 p-2"
                  style="width: 25%; height: 50px; border-radius: 0px; font-size: 16px; color: #f00; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;"
                >
                  <i class="far fa-heart" style="color: pink"></i>
                </button>
                <button
                  v-else
                  @click="removerFavoritos(produto.idProduto)"
                  class="btn m-0 p-2"
                  style="width: 25%; height: 50px; border-radius: 0px; font-size: 16px; color: #f00; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;"
                >
                  <i class="fas fa-heart"></i>
                </button>
                <router-link
                  :to="
                    `/produto/detalhe?produto=${produto.idProduto}#${produto.idProduto}`
                  "
                >
                  <button
                    class="btn m-0 p-2"
                    style="width: 25%; height: 50px; border-radius: 0px; font-size: 16px; color: #666666; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0; border-left: 0.5px solid #e0e0e0;"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </router-link>
              </span>
              <span v-else class="flex-grow-1">
                <button
                  @click="modals.login = true"
                  class="btn btn-success m-0 p-2"
                  style="width: 50%; height: 50px; border-radius: 0px; font-size: 16px;"
                >
                  <i class="fas fa-shopping-bag"></i> quero!
                </button>

                <button
                  @click="modals.login = true"
                  class="btn m-0 p-2"
                  style="width: 25%; height: 50px; border-radius: 0px; font-size: 16px; color: #f00; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0;"
                >
                  <i class="far fa-heart" style="color: pink"></i>
                </button>
                <router-link
                  :to="
                    `/produto/detalhe?produto=${produto.idProduto}#${produto.idProduto}`
                  "
                >
                  <button
                    class="btn m-0 p-2"
                    style="width: 25%; height: 50px; border-radius: 0px; font-size: 16px; color: #666666; background: rgb(0,0,0,0); border-top: 0.5px solid #e0e0e0; border-left: 0.5px solid #e0e0e0;"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </center>
    </div>
    <b-pagination
      v-if="rows > 0"
      class="d-flex justify-content-center m-0 p-0"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="produtosPaginados"
    ></b-pagination>

    <div
      v-if="produtosPromocionais.length >= 4"
      class="section mt-5 pt-2"
      style="background-image: url('./pattern3.png');"
    >
      <!-- PROMOCOES -->
      <h4 class="title ml-1" style="color: #503625">
        Confira algumas de nossas promoções!
      </h4>

      <div
        class="row mx-1 d-flex text-center justify-content-center"
        style="color: #F96432"
      >
        <div
          class="col mx-1 p-0"
          v-for="produto in produtosPromocionais.slice(
            4 * (currentPagePromocionais - 1),
            4 * currentPagePromocionais
          )"
          :key="produto.idProduto"
        >
          <router-link :to="`/produto/detalhe?produto=${produto.idProduto}`">
            <img
              v-lazy="
                'https://www.infanziabrecho.com.br/uploads/' + produto.Foto1
              "
            />
          </router-link>
          {{ produto.NomeProduto }}
          <h5 v-if="produto.PrecoDescontoInfanzia != 0">
            <p style="text-align:center; font-weight: 900; font-size: 12px;">
              {{
                Math.ceil(
                  100 - (produto.PrecoDescontoInfanzia * 100) / produto.Preco
                )
              }}% off
            </p>
          </h5>
          <h5 v-else-if="produto.PrecoPromocional != 0">
            <p style="text-align:center; font-weight: 900; font-size: 12px;">
              {{
                Math.ceil(
                  100 - (produto.PrecoPromocional * 100) / produto.Preco
                )
              }}% off
            </p>
          </h5>
          <h5 v-else-if="produto.PrecoCategoria != 0">
            <p style="text-align:center; font-weight: 900; font-size: 12px;">
              {{
                Math.ceil(100 - (produto.PrecoCategoria * 100) / produto.Preco)
              }}% off
            </p>
          </h5>
        </div>
      </div>
      <center>
        <button
          class="btn btn-primary m-3 p-2"
          style="width: 100px"
          @click="paginacaoPromocoes(1)"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="btn btn-primary m-3 p-2"
          style="width: 100px"
          @click="paginacaoPromocoes(2)"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </center>
    </div>

    <!-- sobre -->
    <div class="section pt-1" ref="sobre" style="background-color: #fff9f2">
      <div class="container text-center">
        <div class="row justify-content-md-center">
          <div class="col-md-12 col-lg-8">
            <h4 class="title">Sobre o Infanzia Brechó</h4>
            <h5 class="description text-justify" style="font-weight: normal">
              O Infanzia Brechó nasceu a partir da idéia de uma mãe comum, de
              dois filhos, que muito cedo percebeu que as crianças crescem
              rápido demais e que as roupinhas compradas ou recebidas de
              presente acabavam sendo guardadas depois de alguns poucos meses...
              <br /><br />
              Quem tem filhos sabe disso... É complicado! ;) <br /><br />
              A partir dessa premissa, essa mãe passou a praticar o consumo
              consciente e dar preferência aos brechós locais, passando a
              comprar nesses locais as roupinhas para as crianças - roupas e
              calçados de qualidade e com excelente preços, bem como passou a
              vender os itens que já não cabiam em seus filhos em seu site recém
              criado: o Infanzia Brechó.
              <br /><br />E aí tudo começou... <br /><br />
              Rapidamente o Infanzia Brechó passou a vender para todo o país,
              além de aumentar a sua freguesia e rede de fornecedoras em sua
              cidade natal (Niterói/RJ), pessoas que acreditaram no nosso
              trabalho sério e que hoje temos orgulho de chamá-las de parceiras
              e clientes. <br /><br />
              Muito obrigada por tornarem tudo isso possível. :*
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="section p-0 m-0" style="background-color: #ccc">
      <b-carousel id="carousel-1" v-model="slide" :interval="3000">
        <span @click="bannerClick()">
          <b-carousel-slide
            img-src="https://www.infanziabrecho.com.br/uploads/publicidade1.png"
          ></b-carousel-slide>
        </span>
      </b-carousel>
    </div>

    <!-- depoimentos -->
    <div class="section pt-1 px-0" style="background-color: #fff3e6">
      <div class="col-lg-12 text-center mb-5">
        <h4 class="title">Nossas clientes são muito especiais.</h4>
        <h5 class="section-subheading text-muted">
          <i>Nosso objetivo é entregar qualidade, com muito capricho.</i>
        </h5>
      </div>

      <div class="card-deck mx-1 justify-content-center">
        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a
              href="https://www.instagram.com/vivianesoaresalmeida"
              target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Viviane Soares</h5>
            <p class="text-muted">
              <font size="2"
                ><i>
                  Sim, comprei uma bermuda jeans mais linda que usa até hoje e
                  um tênis também. Todos muito conservados.
                </i></font
              >
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>

        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a href="https://www.instagram.com/yvioliveira" target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Yvi Oliveira</h5>
            <p class="text-muted">
              <font size="2"
                ><i>
                  Já comprei e recomendo! Me atenderam super bem, além do
                  produto estar em perfeito estado!
                </i></font
              >
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>

        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a href="https://www.instagram.com/amanda_rpeixoto" target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Amanda Peixoto</h5>
            <p class="text-muted">
              <font size="2"
                ><i>
                  As peças sempre estão em ótimas condições, são entregues bem
                  rapidinho e super cheirosas! Dá pra ver o cuidado que colocam
                  no que fazem quando o pacote chega! Recomendo muito!
                </i></font
              >
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>

        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a href="https://www.instagram.com/flavia.helena_" target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Flavia Helena</h5>
            <p class="text-muted">
              <font size="2"
                ><i
                  >Fiz a primeira compra e fiquei apaixonada, as peças parecem
                  novas, muito bem cuidadas, o cuidado com a embalagem me
                  encantou, não largo mais. Fora que fui muito bem atendida.
                </i></font
              >
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>

        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a href="https://www.instagram.com/josi_aalmeida/" target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Josiane Almeida</h5>
            <p class="text-muted">
              <font size="2"
                ><i>
                  Efetuei uma compra com vocês mês passado e fiquei encantada
                  com o capricho que recebi a roupa. Lavada, cheirosa... Enfim.
                  Eu amei.
                </i></font
              >
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>

        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a href="https://www.instagram.com/rodriguinho_caio" target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Maria Inês</h5>
            <p class="text-muted">
              <font size="2"
                ><i
                  >Amei o atendimento online, as blusinhas praticamente
                  novas..embalagem na cx com todo capricho. Eu indico e gostei
                  muito 😘</i
                ></font
              >
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>

        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a href="https://www.instagram.com/diana_jardim" target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Diana Jardim</h5>
            <p class="text-muted">
              <font size="2"><i> Pontual, super indico! </i></font>
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>

        <div class="card p-1 m-1 d-flex align-items-center">
          <div class="card-body">
            <a
              href="https://www.instagram.com/juliapinhocostasa"
              target="_blank"
              ><i class="fab fa-instagram fa-2x m-3"></i
            ></a>
            <h5>Julia Pinho</h5>
            <p class="text-muted">
              <font size="2"
                ><i
                  >Sou cliente fiel! Já fiz várias compras e foi tudo ótimo.</i
                ></font
              >
            </p>
          </div>
          <div>
            <font class="text-muted"
              ><i class="far fa-heart fa-2x p-2" style="color: pink"></i
            ></font>
          </div>
        </div>
      </div>
    </div>
    <!--

CADASTRO DE FORNECEDORES

-->

    <div
      v-if="user !== null"
      class="section pt-1 pb-4"
      style="background-image: url('./pattern2.png');"
    >
      <h4 class="title  text-center">
        Seja um fornecedor Infanzia Brechó!
      </h4>
      <h5 class="section-subheading text-muted text-center">
        Preencha o formulário abaixo e rapidamente entraremos em contato por
        e-mail.
      </h5>
      <fieldset>
        <div class="card-body">
          <form
            v-on:submit.prevent="
              cadastrarFornecedor(
                user.fullname,
                user.email,
                user.Telefone,
                fornecedor.cep,
                novoEndereco.logradouro,
                fornecedor.numero,
                fornecedor.unidade,
                novoEndereco.bairro,
                novoEndereco.localidade,
                novoEndereco.uf,
                fornecedor.complemento
              )
            "
          >
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Nome*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="user.fullname"
                  readonly
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >E-mail*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="user.email"
                  readonly
                />
              </div>
            </div>

            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Celular*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="user.Telefone"
                />
              </div>
            </div>
            <div class="form-group row mt-3">
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >CEP (apenas números)*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="fornecedor.cep"
                  placeholder="Informe o seu CEP"
                  maxlength="8"
                  @keyup="consultaCep(fornecedor.cep)"
                />
              </div>
            </div>
            <div v-if="novoEndereco.erro" class="d-flex justify-content-center">
              <p style="color: #f00">
                CEP inválido, por favor tente novamente.
              </p>
            </div>
            <div
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
              class="form-group row mt-3"
            >
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Logradouro*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="novoEndereco.logradouro"
                  placeholder="Informe o CEP para preenchimento automático"
                  readonly
                />
              </div>
            </div>
            <div
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
              class="form-group row mt-3"
            >
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Número*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="fornecedor.numero"
                  placeholder="Número"
                />
              </div>
            </div>
            <div
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
              class="form-group row mt-3"
            >
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Unidade (ex.: apt, casa etc.)</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="fornecedor.unidade"
                  placeholder="Unidade"
                />
              </div>
            </div>
            <div
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
              class="form-group row mt-3"
            >
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Complemento/Referência</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="fornecedor.complemento"
                  placeholder="Complemento/referência"
                />
              </div>
            </div>
            <div
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
              class="form-group row mt-3"
            >
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Bairro*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="novoEndereco.bairro"
                  placeholder="Informe o CEP para preenchimento automático"
                  readonly
                />
              </div>
            </div>
            <div
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
              class="form-group row mt-3"
            >
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Cidade*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="novoEndereco.localidade"
                  placeholder="Informe o CEP para preenchimento automático"
                  readonly
                />
              </div>
            </div>
            <div
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
              class="form-group row mt-3"
            >
              <label for="name" class="col-md-4 col-form-label text-md-right"
                >Estado*</label
              >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="novoEndereco.uf"
                  placeholder="Informe o CEP para preenchimento automático"
                  readonly
                />
              </div>
            </div>
            <center
              v-if="novoEndereco.logradouro && fornecedor.cep.length == 8"
            >
              <button class="btn btn-success" type="submit">
                Enviar Formulário
              </button>
            </center>
          </form>
        </div>
      </fieldset>
    </div>

    <!--

FIM CADASTRO FORNECEDORES
    -->

    <!-- INICIO MODAL FRETE -->
    <modal
      :show.sync="freteModal"
      :show-close="false"
      headerClasses="m-0 p-0"
      style="z-index: 99999"
    >
      <center>
        <h4 style="color: #000" class="m-0 p-0"><u>Política de Frete</u></h4>
      </center>
      <h6 class="mt-2">Frete grátis</h6>
      <p style="font-weight: bold;" class="m-0 p-0">
        O frete é sempre grátis para:
      </p>
      <ul>
        <li>
          Compras de qualquer valor e com entrega nas proximidades do Engenho do
          Mato (Niterói/RJ);
        </li>
        <li>
          Compras a partir R$ 250,00 e com entrega para a região Sudeste.
        </li>
      </ul>
      <br />
      <h6>Frete promocional</h6>
      <p style="font-weight: bold;" class="m-0 p-0">
        O frete promocional R$ 19,99 é aplicável para:
      </p>
      <ul>
        <li>
          Compras a partir R$ 300,00 e com entrega para qualquer lugar do
          Brasil.
        </li>
      </ul>
      <hr />
      <i class="fas fa-info-circle"></i> Para consultar o frete, cadastre o
      endereço de entrega e acesse a sua sacola.
      <br />
      <i class="fab fa-whatsapp"></i> Caso tenha qualquer dúvida
      <a
        :href="
          'https://api.whatsapp.com/send?phone=5521982031042&text=Olá! Tenho uma dúvida com relação ao frete.'
        "
        target="_blank"
        >manda um Zap pra gente</a
      >!

      <center>
        <button class="btn btn-primary" @click="freteModal = false">Ok!</button>
      </center>
    </modal>
    <!-- FIM MODAL FRETE -->
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Modal } from "@/components";
import ambiente from "../variables/variables.js";

export default {
  props: {
    filtro: Object,
    colocarNaSacola: { type: Function },
    removerDaSacola: { type: Function },
    carregarSacola: { type: Function },
    confereFavorito: { type: Function },
    adicionarFavoritos: { type: Function },
    removerFavoritos: { type: Function },
    exibirFiltros: { type: Function },
    sacola: Object,
    favoritos: null,
    modals: {
      busca: false,
      login: false,
    },
  },
  metaInfo() {
    return {
      title: "Infanzia Brechó",
      meta: [
        {
          name: "description",
          content:
            "O melhor Brechó infantil online do Brasil! Qualidade superior e preço baixo! Aceitamos todos os cartões de crédito. Visite nosso Site!",
        },
        { property: "og:title", content: "Infanzia Brechó" },
        { property: "og:site_name", content: "Infanzia Brechó" },
        { property: "og:type", content: "website" },
        {
          property: "og:image",
          content: "https://www.infanziabrecho.com.br/img/infanzia_logo.png",
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.produtos.length;
    },
  },

  mounted() {
    this.carregarTodosProdutos(this.filtro);
    this.carregarTodosProdutosPromocionais();
  },
  components: {
    Loading,
    Modal,
  },

  data() {
    return {
      isLoading: false,
      produtos: [],
      perPage: 24,
      currentPage: 1,
      currentPagePromocionais: 1,
      fullPage: true,
      onCancel: this.cancelLoading(),
      freteModal: false,
      slide: 0,
      produtosPromocionais: [],
      fornecedor: {
        cep: "",
        nome: "",
        destinatario: "",
        numero: "",
        unidade: "",
        complemento: "",
      },
      novoEndereco: {
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        erro: false,
      },
      refreshBanner: Date(),
    };
  },
  watch: {
    $route() {
      this.carregarSacola();
    },

    filtro: {
      handler: function() {
        setTimeout(() => {
          this.carregarTodosProdutos(this.filtro);
        }, 500);
      },
      deep: true,
    },

    currentPage() {
      setTimeout(() => {
        document.getElementById("app").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }, 100);
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    bannerClick() {
      this.freteModal = !this.freteModal;
    },
    promoClick() {
      this.filtro.categorias.push("Pelúcia");
      this.filtro.categorias.push("Fantasias");
      this.filtro.categorias.push("Jogos");
      this.filtro.categorias.push("Brinquedos");
    },

    paginacaoPromocoes(direcao) {
      if (direcao == 1 && this.currentPagePromocionais > 1) {
        this.currentPagePromocionais--;
      } else if (
        direcao == 2 &&
        this.currentPagePromocionais < this.produtosPromocionais.length / 5
      ) {
        this.currentPagePromocionais++;
      }
    },

    async carregarTodosProdutos(filtro) {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "produtos.php", filtro)
          .then((response) => {
            this.produtos = response.data;
            if (this.produtos.length == 0) {
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    async carregarTodosProdutosPromocionais() {
      this.isLoading = true;
      var todosDisponiveis = { estado: [0, 1], promocao: 1, ordenar: 1 };

      try {
        await axios
          .post(ambiente + "produtos.php", todosDisponiveis)
          .then((response) => {
            this.produtosPromocionais = response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
        this.$swal.fire({
          toast: true,
          icon: "error",
          title: "Ops... Ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.isLoading = false;
    },

    cadastrarFornecedor(
      nome,
      email,
      telefone,
      cep,
      logradouro,
      numero,
      unidade,
      bairro,
      localidade,
      uf,
      complemento
    ) {
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Endereço Cadastrado");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Endereço Cadastrado");
      }

      if (this.user.Telefone.replace(/\D/g, "").length < 9) {
        this.isLoading = false;
        this.$swal.fire({
          toast: true,
          icon: "warning",
          title: "Por favor, informe um número de telefone válido.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        axios
          .post(ambiente + "fornecedor.php", {
            transaction: 5.3,
            idUsuario: this.user.id,
            fornecedor: {
              nome: nome,
              email: email,
              telefone: telefone,
              cep: cep,
              logradouro: logradouro,
              numero: numero,
              unidade: unidade,
              bairro: bairro,
              localidade: localidade,
              uf: uf,
              complemento: complemento,
            },
          })
          .then((response) => {
            this.fornecedor.cep = "";
            this.fornecedor.logradouro = "";
            this.fornecedor.numero = "";
            this.fornecedor.unidade = "";
            this.fornecedor.bairro = "";
            this.fornecedor.localidade = "";
            this.fornecedor.uf = "";
            this.fornecedor.complemento = "";

            this.isLoading = false;

            this.$swal.fire({
              toast: true,
              icon: "success",
              title: response.data.title + "\n\n" + response.data.message,
              showConfirmButton: false,
              timer: 10000,
              timerProgressBar: true,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    consultaCep(cep) {
      cep = cep.replace(/[^0-9]/g, "");
      if (cep.length >= 8) {
        this.novoEndereco = "";
        this.isLoading = true;
        axios
          .post(ambiente + "consulta_cep.php", {
            cep: cep,
          })
          .then((response) => {
            if (response.erro) {
              this.novoEndereco.erro == true;
            } else {
              this.novoEndereco.erro == false;
            }
            this.isLoading = false;
            this.novoEndereco = response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            alert(error);
          });
      }
    },
  },
};
</script>
<style scoped>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
