<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3 pb-5" style="min-height: 100vh;">
      <h5>Business Intelligence - Vendas</h5>
      <div
        class="mb-5 p-0"
        style="max-width: 100vw; overflow-x: auto; white-space: nowrap;"
      >
        <h6 class="m-0 p-0">
          Valor bruto - Histórico completo (soma:
          {{
            sum.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
          }}
          | média:
          {{
            media.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }})
        </h6>
        <graph-line
          v-if="loaded"
          :width="values[0].length * 50"
          :height="400"
          :shape="'curve'"
          :display="'all'"
          :axis-min="min"
          :axis-max="max"
          :axis-full-mode="true"
          :labels="labels"
          :names="names"
          :values="values"
        >
          <tooltip :names="names" :position="'left'"></tooltip>
          <legends :names="names"></legends>
          <guideline :tooltip-y="true" :position="'left'"></guideline>
        </graph-line>
      </div>

      <br />
      <br />
      <div
        class="mb-5 p-0"
        style="max-width: 100vw; overflow-x: auto; white-space: nowrap;"
      >
        <h6 class="m-0 p-0">
          Valor bruto - Evolução anual (soma:
          {{
            sum.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
          }}
          | média:
          {{
            media.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }})
        </h6>
        <center>
          <graph-line
            v-if="loaded2"
            :width="1200"
            :height="600"
            :activeIndex="parseInt(`${valuesEvolAnual.length - 1}`)"
            :shape="'normal'"
            :display="'all'"
            :axis-min="min2"
            :axis-max="max2"
            :axis-full-mode="true"
            :labels="labels2"
            :names="names2"
            :values="valuesEvolAnual"
          >
            <tooltip :names="names2" :position="'left'"></tooltip>
            <legends :names="names2"></legends>
            <guideline :tooltip-y="true" :tooltip-x="true"></guideline>
          </graph-line>
        </center>
      </div>

      <div
        class="mb-5 p-0"
        style="max-width: 100vw; overflow-x: auto; white-space: nowrap;"
      >
        <h6 class="m-0 p-0">
          Valor líquido (soma: {{sumLiq.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}})
        </h6>
        <center>
          <graph-bar
            v-if="loaded2"
            :width="1200"
            :height="600"
            :axis-min="0"
            :axis-max="5000"
            :labels="labels2"
            :values="valuesEvolAnualLiq"
          >
            <tooltip :names="names2" :position="'left'"></tooltip>
            <legends :names="names2" :filter="true"></legends>
          </graph-bar>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  mounted() {
    this.carregarChartData();
  },
  components: {
    Loading,
    AdministrativoMenu,
  },

  data() {
    return {
      loaded: false,
      loaded2: false,
      chartData: null,
      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),

      labels: [],
      labels2: [
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez",
      ],
      names: ["Vendas (valor bruto)", "Média"],
      names2: ["2018", "2019", "2020", "2021"],
      values: [[]],
      valuesEvolAnual: [[]],
      valuesEvolAnualLiq: [[]],
      min: 99999999999,
      max: 0,
      min2: 99999999999,
      max2: 0,
      sum: 0,
      sumLiq: 0,
      media: 0,
    };
  },
  watch: {
    $route() {
      this.carregarChartData();
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarChartData() {
      this.isLoading = true;

      try {
        await axios
          .post(
            ambiente + "business_intelligence.php",
            {
              transaction: "vendas",
              conta: this.$store.getters.user.conta,
              idUsuario: this.$store.getters.user.id,
            }
          )
          .then((response) => {
            this.chartData = response.data.vendas;
            var tam = this.chartData.length;
            var i = 0;

            // atribui data e valor mes a mes
            for (i = 0; i < tam; i++) {
              if (this.chartData[i].Valor < this.min) {
                this.min = parseInt(this.chartData[i].Valor);
              }
              if (this.chartData[i].Valor > this.max) {
                this.max = parseInt(this.chartData[i].Valor);
              }
              this.values[0].push(this.chartData[i].Valor);
              this.sum += parseInt(this.chartData[i].Valor);
              
              this.sumLiq += parseInt((this.chartData[i].Valor / 2) - this.chartData[i].CustoPostagem + this.chartData[i].Desconto -  this.chartData[i].CustoMercadoPago);

              var res = this.chartData[i].DataEntrega.split("-");
              this.labels.push(res[1] + "/" + res[0]);
            }

            // atribui media ao longo do tempo
            this.media = parseInt(this.sum / tam);
            this.values.push([]);
            for (i = 0; i < tam; i++) {
              this.values[1].push(this.media);
            }

            // organiza dados para evolução anual
            var year = 2018;
            var j = 0;

            var yearResult = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            var yearResultLiq = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            for (i = 0; i < tam; i++) {

              res = this.chartData[i].DataEntrega.split("-");

              if (this.chartData[i].Valor < this.min2) {
                this.min2 = parseInt(this.chartData[i].Valor);
              }
              if (this.chartData[i].Valor > this.max2) {
                this.max2 = parseInt(this.chartData[i].Valor);
              }

              if (res[0] == year) {
                yearResult[parseInt(res[1]) - 1] = this.chartData[i].Valor;
                this.valuesEvolAnual[j] = yearResult;

                yearResultLiq[parseInt(res[1]) - 1] = parseInt((this.chartData[i].Valor / 2) - this.chartData[i].CustoPostagem + this.chartData[i].Desconto -  this.chartData[i].CustoMercadoPago);
                this.valuesEvolAnualLiq[j] = yearResultLiq;
                
              } else {
                year++;
                j++;
                yearResult = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                yearResult[parseInt(res[1]) - 1] = this.chartData[i].Valor;
                this.valuesEvolAnual[j] = yearResult;

                yearResultLiq = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                yearResultLiq[parseInt(res[1]) - 1] = parseInt((this.chartData[i].Valor / 2) - this.chartData[i].CustoPostagem + this.chartData[i].Desconto -  this.chartData[i].CustoMercadoPago);
                this.valuesEvolAnualLiq[j] = yearResultLiq;
              }
            }

            this.loaded = true;
            this.loaded2 = true;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
