<template>
  <footer class="footer bg-primary">
    <div class="container">
      <div class="row justify-content-md-center sharing-area text-center">
        <div class="text-center col-md-12 col-lg-8">
          <h5 style="color: #503626">
            Siga nossas redes sociais e fique sempre por dentro das promoções!
          </h5>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <a
            target="_blank"
            href="https://www.instagram.com/infanziabrechoinfantil/"
            class="btn btn-neutral btn-icon btn-round btn-lg"
            style="color: #FD1D1D"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/infanziabrecho/"
            class="btn btn-neutral btn-icon btn-round btn-lg"
            style="color: #4267B2"
          >
            <i class="fab fa-facebook-f"></i>
          </a>

          <a
            target="_blank"
            href="https://wa.me/5521982031042"
            class="btn btn-neutral btn-icon btn-round btn-lg"
            style="color: #25D366"
          >
            <i class="fab fa-whatsapp"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style>
h3 {
  color: white;
}
</style>
