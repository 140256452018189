<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3">
      <h5>
        Clientes cadastrados
        <font style="color: #BEBEBE">({{ clientes.length }})</font>
      </h5>

      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="clientesPaginados"
      ></b-pagination>

      <div
        id="clientesPaginados"
        :clientes="clientes"
        :per-page="perPage"
        :current-page="currentPage"
        class="table-responsive"
      >
        <center>
          <table class="table" v-if="this.clientes.length != null">
            <tr>
              <td>Nome</td>
              <td>E-mail</td>
              <td>Telefone</td>
              <td>CPF</td>
              <td>Data do Cadastro</td>
            </tr>
            <template
              v-for="cliente in clientes.slice(
                perPage * (currentPage - 1),
                perPage * currentPage
              )"
            >
              <tr :key="cliente.idUsuario">
                <td>
                  {{ cliente.Nome }}
                </td>
                <td>
                  {{ cliente.Email }}
                </td>
                <td>
                  {{ cliente.Telefone }}
                </td>
                <td>
                  {{ cliente.CPF }}
                </td>
                <td>
                  {{ cliente.dataCadastro }}
                </td>
              </tr>

              <tr :key="cliente.idUsuario + 1">
                <td colspan="5">
                  <div>
                    <div
                      v-if="cliente.enderecos"
                      class="accordion"
                      role="tablist"
                    >
                      <b-card no-body class="mb-1" style="min-width: 100%">
                        <b-card-header
                          header-tag="header"
                          class="p-1"
                          role="tab"
                        >
                          <b-button
                            block
                            v-b-toggle="'accordion-' + cliente.idUsuario"
                            variant="primary"
                            >Ver endereço(s) cadastrados</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          :id="'accordion-' + cliente.idUsuario"
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <template v-for="endereco in cliente.enderecos">
                              <div :key="endereco.idEndereco">
                                <p>Nome: {{ endereco.Nome }}</p>
                                <p>Destinatário: {{ endereco.Destinatario }}</p>
                                <p>CEP: {{ endereco.CEP }}</p>
                                <p>Logradouro: {{ endereco.Logradouro }}</p>
                                <p>Número: {{ endereco.Numero }}</p>
                                <p>Unidade: {{ endereco.Unidade }}</p>
                                <p>Complemento: {{ endereco.Complemento }}</p>
                                <p>Bairro: {{ endereco.Bairro }}</p>
                                <p>Cidade: {{ endereco.Cidade }}</p>
                                <p>Estado: {{ endereco.UF }}</p>
                                <hr />
                              </div>
                            </template>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </center>
      </div>
      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center m-0 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="clientesPaginados"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../../variables/variables.js";

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.clientes.length;
    },
  },

  mounted() {
    this.carregarTodosClientes();
  },
  components: {
    Loading,
    AdministrativoMenu,
  },

  data() {
    return {
      isLoading: false,
      clientes: [],
      filtro: {
        estado: [0, 1, 2, 3, 4],
        busca: "",
        ordenar: 4, // mais novos primeiro
      },

      timestamp: new Date(),

      todos: [0, 1, 2, 3, 4],
      disponivel: [0],
      sacola: [1],
      vendido: [2],
      pendente: [3],
      devolucao: [4],

      perPage: 50,
      currentPage: 1,
      fullPage: true,
      onCancel: this.cancelLoading(),
      // rows: null,
    };
  },
  watch: {
    $route() {
      this.carregarTodosClientes();
    },

    filtro: {
      handler: function() {
        this.carregarTodosClientes();
      },
      deep: true,
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    limpaBusca() {
      this.filtro.busca = "";
    },

    async carregarTodosClientes() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "cliente.php", {
            transaction: 1,
            conta: this.$store.getters.user.conta,
          })
          .then((response) => {
            this.clientes = response.data.usuario;
            if (this.clientes.length == 0) {
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
/* @import "../../assets/css/simple-sugest-autocomplete.css"; */

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
