<template>
  <div class="mt-5" style="" id="app">
    <MainNavbar
      :currentPage="paginaAtual"
      :contaProdutosSacola="contaProdutosSacola"
      :user="user"
      :modals="modals"
    />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
    <div
      v-if="this.$store.getters.user != null"
      class="container mt-5 pt-5"
      style="min-height: 100vh"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 order-md-2 mb-4">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
              <span style="font-weight: 900;"
                >Minha sacola
                <span
                  style="font-size: 10px; color: #686868"
                  v-if="sacola.produtos[0]"
                  >cód. INF{{ sacola.produtos[0].idSacola }}</span
                ></span
              >
              <span v-if="sacola.produtos.length == 1" style="font-size: 14px"
                >({{ sacola.produtos.length }} item)</span
              >
              <span v-if="sacola.produtos.length > 1" style="font-size: 14px"
                >({{ sacola.produtos.length }} itens)</span
              >
            </h4>
            <ul v-if="sacola.produtos.length != 0" class="list-group mb-3">
              <li
                v-for="produto in sacola.produtos"
                :key="produto.idProduto"
                class="list-group-item d-flex justify-content-between lh-condensed mx-0 px-1"
              >
                <div class="mx-0 px-0">
                  <span v-if="produto.Status == 2" class="my-0">
                    <button
                      class="btn btn-secondary btn-sm my-0 p-0 mr-2"
                      style="height: 23px; width: 23px"
                      @click="removerDaSacolaProdutoVendido(produto)"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                    <img
                      class="rounded"
                      style="height: 50px; width: 50px"
                      :src="
                        'https://www.infanziabrecho.com.br/uploads/' +
                          produto.Foto1
                      "
                    />
                    <router-link :to="`#`">
                      <s>{{ produto.NomeProduto }}</s>
                      <br />
                      <font style="color: #bababa; font-size: 8px"
                        >INF00{{ produto.idProduto }}</font
                      >
                      <br />
                      <small
                        >Xiii... Alguém acabou comprando antes este item!</small
                      ></router-link
                    >
                  </span>
                  <span v-else class="my-0">
                    <table>
                      <tr>
                        <td style="vertical-align: top">
                          <button
                            class="btn btn-secondary btn-sm my-0 p-0 mr-2"
                            style="height: 23px; width: 23px"
                            @click="removerDaSacola(produto)"
                          >
                            <i class="fas fa-times"></i>
                          </button>
                        </td>
                        <td style="vertical-align: top">
                          <center>
                            <img
                              class="rounded"
                              style="height: 50px; width: 50px"
                              :src="
                                'https://www.infanziabrecho.com.br/uploads/' +
                                  produto.Foto1
                              "
                            />
                            <br />
                            <font style="color: #686868; font-size: 9px"
                              >INF00{{ produto.idProduto }}</font
                            >
                          </center>
                        </td>
                        <td style="vertical-align: top">
                          <router-link
                            :to="
                              `/produto/detalhe?produto=${produto.idProduto}`
                            "
                          >
                            {{ produto.NomeProduto }}</router-link
                          >
                          <br />

                          <span v-if="$store.getters.user">
                            <div
                              class="m-0 p-0 text-muted"
                              style="font-size: 9px"
                              v-if="produto.PessoasComprando == 2"
                            >
                              <i class="fas fa-fire-alt" style="color: red"></i>
                              Tem {{ produto.PessoasComprando-1 }} pessoa com esse
                              produto na sacola!
                            </div>
                            <div
                              class="mb-2 text-muted"
                              style="font-size: 11px"
                              v-if="produto.PessoasComprando > 2"
                            >
                              <i class="fas fa-fire-alt" style="color: red"></i>
                              Tem {{ produto.PessoasComprando }} pessoas com
                              esse produto na sacola!
                            </div>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </span>
                </div>

                <span
                  v-if="
                    produto.PrecoDescontoInfanzia != 0 && produto.Status <= 1
                  "
                >
                  <s>{{
                    produto.Preco.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}</s>
                  <br />
                  {{
                    produto.PrecoDescontoInfanzia.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </span>
                <span
                  v-else-if="
                    produto.PrecoPromocional != 0 && produto.Status <= 1
                  "
                >
                  <s>{{
                    produto.Preco.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}</s>
                  <br />
                  {{
                    produto.PrecoPromocional.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </span>
                <span
                  v-else-if="produto.PrecoCategoria != 0 && produto.Status <= 1"
                >
                  <s>{{
                    produto.Preco.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}</s>
                  <br />
                  {{
                    produto.PrecoCategoria.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </span>
                <span
                  v-else-if="
                    produto.PrecoCategoria == 0 &&
                      produto.PrecoPromocional == 0 &&
                      produto.PrecoDescontoInfanzia == 0 &&
                      produto.Status <= 1
                  "
                >
                  {{
                    produto.Preco.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                </span>
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span>Soma</span>
                {{
                  parseFloat(soma)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>
              <li
                v-if="valorFrete != 0 || cepEntrega == '24300000'"
                class="list-group-item d-flex justify-content-between"
              >
                <span>Frete</span>
                {{
                  parseFloat(valorFrete)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>
              <li v-else class="list-group-item d-flex justify-content-between">
                <span>Frete</span>
                ---
              </li>

              <li
                v-if="descontoFlag != 0"
                class="list-group-item d-flex justify-content-between"
              >
                <span>Desconto frete</span>
                {{
                  parseFloat(desconto)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>

              <li
                v-if="
                  fornecedor.credito != 0 &&
                    fornecedor.credito >=
                      parseFloat(soma) + parseFloat(valorFrete)
                "
                class="list-group-item d-flex justify-content-between"
              >
                <span>Desconto por uso de créditos de fornecedor(a)</span>
                -{{
                  parseFloat(fornecedor.creditoEmUso)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>
              <li
                v-if="
                  user.conta == 2 &&
                    fornecedor.credito != 0 &&
                    fornecedor.credito <=
                      parseFloat(soma) + parseFloat(valorFrete)
                "
                class="list-group-item d-flex justify-content-between"
              >
                <span>Desconto por uso de créditos de fornecedor(a)</span>
                -{{
                  parseFloat(fornecedor.creditoEmUso)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span><strong>Total (R$)</strong></span>
                <strong>{{
                  (
                    parseFloat(soma) +
                    parseFloat(valorFrete) +
                    parseFloat(desconto) -
                    parseFloat(fornecedor.creditoEmUso)
                  )
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}</strong>
              </li>

              <li
                class="list-group-item d-flex justify-content-between"
                style="color: green"
              >
                <b>{{ frete.mensagem }}</b>
              </li>
            </ul>
            <ul
              v-if="
                user.conta == 2 &&
                  fornecedor.credito != 0 &&
                  sacola.produtos.length != 0
              "
              class="m-0 p-0"
              style="border: 0px"
            >
              <li class="list-group-item d-flex justify-content-between">
                <span>Seus créditos de fornecedor(a) (R$) </span
                >{{
                  parseFloat(fornecedor.credito)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span>Créditos sendo utilizados nesta compra (R$) </span>-{{
                  parseFloat(fornecedor.creditoEmUso)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <span>Saldo de créditos após esta compra (R$) </span
                >{{
                  parseFloat(fornecedor.saldoAposCompra)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")
                }}
              </li>
            </ul>

            <ul v-if="sacola.produtos.length == 0" class="list-group mb-3">
              <li
                class="list-group-item d-flex justify-content-between lh-condensed"
              >
                Nenhum produto por aqui... <i class="far fa-frown mt-1 p-0"></i>
              </li>
            </ul>
            <div class="input-group my-3">
              <input type="text" class="form-control m-0" placeholder="Cupom" />
              <div class="input-group-append">
                <button
                  type="submit"
                  class="btn btn-sm m-0 form-control"
                  style="background-color: #19ce0f; color: #ffff; border: 1px"
                >
                  Aplicar
                </button>
              </div>
            </div>

            <div
              v-if="sacola.produtos.length != 0 && sugestoes.length != 0"
              class="cardGrande p-3 m-0"
            >
              <div
                class="m-0 p-0"
                style="font-size: 15px; text-align: justify;
  text-justify: inter-word;"
              >
                Que tal dar uma espiadinha nessas sugestões antes de finalizar o
                seu pedido? <i class="far fa-smile" style="color: #696969"></i>
              </div>
              <center>
                <div class="row d-flex">
                  <div
                    v-for="sugestao in sugestoes"
                    :key="sugestao.idProduto"
                    class="col-4 my-2 p-0"
                  >
                    <router-link
                      :to="`/produto/detalhe?produto=${sugestao.idProduto}`"
                    >
                      <img
                        class="rounded"
                        style="height: 85px; width: 85px"
                        :src="
                          'https://www.infanziabrecho.com.br/uploads/' +
                            sugestao.Foto1
                        "
                      />
                      <p
                        class="lh-condensed m-0 p-0"
                        style="font-size: 12px; font-weight: normal"
                      >
                        {{ sugestao.NomeProduto }}
                      </p>
                    </router-link>
                  </div>
                </div>
              </center>
            </div>
          </div>
          <div v-if="sacola.produtos[0]" class="col-md-6 order-md-1">
            <form
              v-on:submit.prevent="
                finalizarPedido(
                  sacola.produtos[0].idSacola,
                  sacola.produtos[0].CodigoPedido,
                  idEnderecoSelecionado,
                  frete.tipo,
                  formaDePagamento,
                  parseFloat(fornecedor.creditoEmUso).toFixed(2),
                  parseFloat(soma).toFixed(2),
                  parseFloat(valorFrete).toFixed(2),
                  parseFloat(desconto).toFixed(2)
                )
              "
            >
              <h4 class="mb-3" style="font-weight: 900;">
                Selecione o local para entrega
              </h4>

              <div class="cardGrande m-1 p-3">
                <input
                  id="retirada"
                  type="radio"
                  name="endereco"
                  :value="24300000"
                  required
                  v-model="cepEntrega"
                  @click="
                    descontoFlag = 0;
                    valorFrete = 0;
                    idEnderecoSelecionado = 0;
                    nomeEndereco = 'Retirada no local';
                    frete.tipo = 'Retirada no local';
                  "
                />
                <label for="retirada"
                  ><i class="fas fa-hand-holding mx-2"></i> Retirada no local
                  (agendamento por WhatsApp)
                </label>
                <br />
                <font style="font-size: 10px; color: #555">
                  Engenho do Mato - Niterói/RJ - Avenida Irene Lopes Sodre, 900 (Condomínio Ubá Floresta) -
                  PORTARIA
                </font>
              </div>

              <div v-if="enderecos.length == 0" class="cardGrande m-1 p-3">
                <center>
                  <h6>Você ainda não possui nenhum endereço cadastrado.</h6>
                  <p class="m-0 p-0">
                    Cadastre um endereço clicando no botão abaixo.
                  </p>
                </center>
              </div>

              <div
                v-for="endereco in enderecos"
                :key="endereco.idEndereco"
                class="cardGrande m-1 p-3"
              >
                <label>
                  <input
                    type="radio"
                    name="endereco"
                    :id="endereco.idEndereco"
                    required
                    :value="endereco.cep"
                    @click="
                      idEnderecoSelecionado = endereco.idEndereco;
                      cepEntrega = endereco.cep;
                      nomeEndereco = endereco.nome;
                      frete.tipo = '';
                      valorFrete = 0;
                    "
                  />
                  <i class="fas fa-shipping-fast mx-2"></i> {{ endereco.nome }}
                </label>
                <hr class="my-0 ml-5" />
                <small class="text-muted">
                  {{ endereco.destinatario }} | {{ endereco.logradouro }},
                  {{ endereco.numero }} -
                  {{ endereco.unidade }}
                  {{ endereco.bairro }} - {{ endereco.localidade }}/{{
                    endereco.uf
                  }}
                  {{ endereco.complemento }}
                  CEP {{ endereco.cep }}
                </small>

                <div
                  class="m-1 p-2"
                  v-if="
                    idEnderecoSelecionado == endereco.idEndereco &&
                      descontoFlag == 0
                  "
                >
                  <h5 class="mb-3">Selecione o tipo de envio</h5>

                  <ul class="list-group list-group-flush">
                    <li
                      v-if="frete.fretepac != 0 && frete.prazoPac"
                      class="list-group-item"
                    >
                      <input
                        type="radio"
                        name="valorFrete"
                        required
                        :value="frete.fretepac"
                        v-model="valorFrete"
                        :id="idEnderecoSelecionado + 'pac'"
                        class="mr-1"
                        @click="frete.tipo = 'PAC'"
                      />
                      <label :for="idEnderecoSelecionado + 'pac'">
                        PAC - R$
                        {{
                          parseFloat(frete.fretepac)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}</label
                      >
                      <p>
                        <small class="text-muted"
                          >Prazo dos Correios para entrega:
                          {{ frete.prazoPac }} dias úteis após a
                          postagem.</small
                        >
                      </p>
                    </li>
                    <li
                      v-if="
                        frete.fretesedex != 0 &&
                          frete.prazoSedex &&
                          descontoFlag != 2
                      "
                      class="list-group-item"
                    >
                      <input
                        type="radio"
                        name="valorFrete"
                        :value="frete.fretesedex"
                        v-model="valorFrete"
                        :id="idEnderecoSelecionado + 'sedex'"
                        @click="frete.tipo = 'SEDEX'"
                        class="mr-1"
                      />
                      <label :for="idEnderecoSelecionado + 'sedex'">
                        SEDEX - R$
                        {{
                          parseFloat(frete.fretesedex)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}</label
                      >
                      <p>
                        <small class="text-muted"
                          >Prazo dos Correios para entrega:
                          {{ frete.prazoSedex }} dias úteis após a
                          postagem.</small
                        >
                      </p>
                    </li>
                  </ul>
                </div>

                <div
                  class="m-1 p-2"
                  v-if="
                    idEnderecoSelecionado == endereco.idEndereco &&
                      descontoFlag != 0
                  "
                >
                  <h5 class="mb-3">Selecione o tipo de envio</h5>

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <input
                        type="radio"
                        name="valorFrete"
                        required
                        :value="frete.fretepac"
                        v-model="valorFrete"
                        :id="idEnderecoSelecionado + 'pac'"
                        class="mr-1"
                        @click="frete.tipo = 'PAC'"
                      />
                      <label :for="idEnderecoSelecionado + 'pac'">
                        PAC -
                        <s
                          >R$
                          {{
                            parseFloat(frete.fretepac)
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}</s
                        ></label
                      >
                      <p>
                        <small v-if="frete.prazoPac" class="text-muted"
                          >Prazo dos Correios para entrega:
                          {{ frete.prazoPac }} dias úteis após a
                          postagem.</small
                        >
                        <small
                          v-if="!frete.prazoPac && frete.prazoSedex"
                          class="text-muted"
                          >Prazo dos Correios para entrega:
                          {{ frete.prazoSedex }} dias úteis após a
                          postagem.</small
                        >
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <hr class="mb-1 mt-4" />

              <div class="container">
                <div class="row">
                  <div class="col-md-6 my-2">
                    <nav-link
                      to="/enderecos"
                      class="btn btn-primary btn-sm p-2 mt-2 mx-0"
                      style="height: 100%; white-space: normal; text-align: center"
                      ><i class="fas fa-map-marked-alt fa-3x mb-1"></i><br />Se
                      quiser cadastrar ou editar endereços para entrega clique
                      aqui!</nav-link
                    >
                  </div>
                  <div class="col-md-6 my-2">
                    <a
                      :href="
                        'https://api.whatsapp.com/send?phone=5521982031042&text=Olá! Minha sacola é a ' +
                          sacola.produtos[0].idSacola +
                          ' e eu tenho uma dúvida.'
                      "
                      class="btn btn-sm pt-2 p-2 mt-2 mx-0"
                      target="_blank"
                      style="height: 100%; white-space: normal; text-align: center; background-color: #128C7E; width: 100%"
                      ><i class="fab fa-3x fa-whatsapp mb-1"></i><br />Ficou com
                      dúvida? Fale conosco agora mesmo pelo WhatsApp!</a
                    >
                  </div>
                </div>
              </div>

              <hr class="mt-4" />

              <span
                v-if="
                  parseFloat(soma) +
                    parseFloat(valorFrete) +
                    parseFloat(desconto) !=
                    parseFloat(fornecedor.creditoEmUso)
                "
              >
                <h4 class="mb-3 mt-3" style="font-weight: 900;">
                  Selecione a forma de pagamento
                </h4>

                <div class="d-block my-3">
                  <div>
                    <input
                      id="credito"
                      type="radio"
                      name="pagamento"
                      :value="1"
                      required
                      v-model="formaDePagamento"
                      class="mr-1"
                      @click="nomeFormaDePagamento = 'Cartão de crédito'"
                    />
                    <label for="credito">
                      Cartão de Crédito (em até 2x sem juros)</label
                    >
                  </div>
                  <!-- <div>
                    <input
                      id="debito"
                      type="radio"
                      name="pagamento"
                      :value="2"
                      required
                      v-model="formaDePagamento"
                      class="mr-1"
                      @click="nomeFormaDePagamento = 'Cartão de débito'"
                    />
                    <label for="debito"> Cartão de débito</label>
                  </div> -->
                  <div>
                    <input
                      id="deposito"
                      type="radio"
                      name="pagamento"
                      :value="3"
                      required
                      v-model="formaDePagamento"
                      class="mr-1"
                      @click="
                        nomeFormaDePagamento =
                          'Depósito em conta / Transferência / Pix'
                      "
                    />
                    <label for="deposito">
                      PIX / Depósito em conta / Transferência</label
                    >
                  </div>
                  <div>
                    <input
                      id="boleto"
                      type="radio"
                      name="pagamento"
                      :value="4"
                      required
                      v-model="formaDePagamento"
                      class="mr-1"
                      @click="nomeFormaDePagamento = 'Boleto bancário'"
                    />
                    <label for="boleto"> Boleto Bancário</label>
                  </div>
                  <!-- <div>
                    <input
                      id="paypal"
                      type="radio"
                      name="pagamento"
                      :value="5"
                      required
                      v-model="formaDePagamento"
                      class="mr-1"
                      @click="nomeFormaDePagamento = 'PayPal'"
                    />
                    <label for="paypal"> PayPal</label>
                  </div> -->
                  <div>
                    <input
                      id="mp"
                      type="radio"
                      name="pagamento"
                      :value="6"
                      required
                      v-model="formaDePagamento"
                      class="mr-1"
                      @click="nomeFormaDePagamento = 'Mercado Pago'"
                    />
                    <label for="mp"> Mercado Pago</label>
                  </div>
                </div>
              </span>

              <div v-if="nomeFormaDePagamento == 'Mercado Pago' || nomeFormaDePagamento == 'Cartão de crédito' || nomeFormaDePagamento == 'Boleto bancário'" class="d-block my-3" style="font-size: 18px">
                <b>Ao clicar em "Finalizar Pedido" sua compra será finalizada e você será redirecionado(a) de
                forma segura para o Mercado Pago para efetuar o pagamento da forma escolhida.</b>
                <br />
                Após finalizar o pedido você poderá acompanhar o andamento em
                <font style="font-size: 11px"
                  ><b>MEU PERFIL > MINHAS COMPRAS</b></font
                >
                no menu.
              </div>

              <div v-if="nomeFormaDePagamento == 'Depósito em conta / Transferência / Pix'" class="d-block my-3" style="font-size: 18px">
                <b>Ao clicar em "Finalizar Pedido" sua compra será finalizada e você receberá um e-mail com o nosso código PIX e demais dados da conta para o caso de depósito ou transferência bancária.</b>
                <br />
                Após finalizar o pedido você poderá acompanhar o andamento em
                <font style="font-size: 11px"
                  ><b>MEU PERFIL > MINHAS COMPRAS</b></font
                >
                no menu.
              </div>

              <button
                @click="verificaCheckout()"
                v-if="
                  sacola.produtos[0] && botaoFinalizarCompraCarregando == false
                "
                class="btn btn-success btn-lg btn-block"
                type="submit"
              >
                Finalizar Pedido
              </button>
              <button
                v-if="!sacola.produtos[0]"
                disabled
                class="btn btn-success btn-lg btn-block"
              >
                Finalizar Pedido
              </button>
              <button
                v-if="botaoFinalizarCompraCarregando == true"
                disabled
                class="btn btn-success btn-lg btn-block"
              >
                Aguarde, finalizando o seu pedido...
              </button>

              <hr class="my-5" />
              <img src="/img/mercado_pago.png" style="" />
            </form>
          </div>
        </div>
      </div>

      <div v-if="sacola.produtos.length == 0">
        <center>
          <p>
            Para visualizar as suas compras acesse o item
            <router-link to="/compras">minhas compras</router-link> no menu, ou
            <router-link to="/compras">clique aqui</router-link>!
          </p>
        </center>
      </div>
    </div>
    <div v-else style="height: 100vh" class="mt-5 pt-5">
      <h4 style="text-align: center" class="mt-5 pt-5">
        É preciso estar logado(a) para acessar a sacola.
      </h4>
    </div>

    <!-- INICIO MODAL LOGIN -->
    <modal
      :show.sync="modals.login"
      :show-close="false"
      headerClasses=" p-0 m-0"
      style="z-index: 9999"
    >
      <n-button
        type="primary p-2 m-0"
        class=""
        round
        icon
        simple
        style="width: 50px; height: 50px"
        @click.native="modals.login = false"
        ><i class="fas fa-times"></i
      ></n-button>
      <Login />
    </modal>
    <!-- FIM MODAL LOGIN -->

    <MainFooter />
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";
import Login from "./admin/Login";
import { NavLink, Modal, Button, Checkbox } from "@/components";
import ambiente from "../variables/variables.js"

export default {
  props: {
    filtro: Object,
    colocarNaSacola: { type: Function },
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  watch: {
    $route() {
      if (this.$store.getters.user != null) {
        this.frete.tipo = "";
        this.valorFrete = 0;
        this.carregarEnderecos();
        this.carregarSacola();
        this.carregarCreditoFornecedor();
      }
    },

    valorFrete: {
      handler: function() {
        if (this.descontoFlag == 1) {
          this.desconto = parseFloat(this.valorFrete) * parseFloat(-1);
        } else if (this.descontoFlag == 2) {
          this.desconto =
            (parseFloat(-19.99) + parseFloat(this.valorFrete)) * parseFloat(-1);
        } else if (this.descontoFlag == 0) {
          this.desconto = 0;
        }
        this.carregarCreditoFornecedor();
      },
      deep: true,
    },

    cepEntrega() {
      return this.consultaCEP(this.soma);
    },
  },
  mounted() {
    if (!this.$store.getters.user) {
      this.$gtag.event("Visualização - Sacola");
    } else if (this.$store.getters.user.conta != 1) {
      this.$gtag.event("Visualização - Sacola");
    }

    if (this.$store.getters.user != null) {
      this.carregarSacola();
      this.carregarEnderecos();
      this.carregarCreditoFornecedor();
    }
  },
  components: {
    Loading,
    MainNavbar,
    MainFooter,
    NavLink,
    Modal,
    Login,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },

  data() {
    return {
      paginaAtual: "sacola",
      produtos: null,

      produto: {
        Preco: Number,
        PrecoDescontoInfanzia: Number,
        PrecoPromocional: Number,
        PrecoCategoria: Number,
      },

      soma: 0,
      frete: {
        fretesedex: 0.001,
        fretepac: 0.001,
        mensagem: "",
        tipo: "",
      },
      cepEntrega: "",
      valorFrete: 0,
      desconto: 0,
      descontoFlag: 0,
      formaDePagamento: "",
      nomeFormaDePagamento: "",
      nomeEndereco: "",

      sacola: {
        produtos: Array,
        total: Number,
      },

      fornecedor: {
        credito: 0,
        creditoEmUso: 0,
        saldoAposCompra: 0,
      },

      enderecos: Object,
      novoEndereco: "",
      idEnderecoSelecionado: null,

      produtosIndisponiveis: [],

      contaProdutosSacola: 0,

      sugestoes: [],

      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),

      botaoFinalizarCompraCarregando: false,

      modals: {
        busca: false,
        login: false,
      },
    };
  },

  methods: {
    verificaCheckout() {
      var error = [];
      if (!this.idEnderecoSelecionado && this.idEnderecoSelecionado != 0) {
        error.push("<li>Selecione o local de entrega para prosseguir.</li>");
      } else if (
        this.valorFrete === 0 &&
        this.nomeEndereco != "Retirada no local"
      ) {
        error.push(
          "<li>Selecione o tipo de envio (PAC ou SEDEX) para prosseguir.</li>"
        );
      }
      if (!this.formaDePagamento && parseFloat(this.soma) + parseFloat(this.valorFrete) + parseFloat(this.desconto) !=
        parseFloat(this.fornecedor.creditoEmUso)) {
        error.push("<li>Selecione a forma de pagamento para prosseguir.</li>");
      }
      if (
        this.$store.getters.user.Telefone.replace(/\D/g, "").length < 10 &&
        this.nomeEndereco == "Retirada no local"
      ) {
        error.push(
          "<li>Para retirada no local você deve ter um número de telefone celular cadastrado. <a href='/dados-pessoais'>Cadastre seu celular clicando aqui</a> e em seguida selecione <b>'editar dados'</b>.</li>"
        );
      }

      const formErrMsg = String(error).replace(/,/g, "<br/>");

      if (formErrMsg.length > 3) {
        this.$swal({
          title: "Ops...",
          html: "<ul>" + formErrMsg + "</ul>",
          icon: "warning",
          confirmButtonText: "OK",
          showLoaderOnConfirm: false,
        });
        return false;
      }
      return true;
    },

    cancelLoading() {
      this.isLoading = false;
    },

    finalizarPedido(
      idSacola,
      codigoPedido,
      idEnderecoSelecionado,
      freteTipo,
      formaDePagamento,
      usoCredito,
      soma,
      valorFrete,
      desconto
    ) {
      this.isLoading = true;

      if (!this.verificaCheckout()) {
        this.isLoading = false;
        return;
      }

      if (!this.$store.getters.user) {
        this.$gtag.event("Pedido Finalizado");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Pedido Finalizado");
      }

      if (
        parseFloat(soma) + parseFloat(valorFrete) + parseFloat(desconto) ==
        parseFloat(usoCredito)
      ) {
        this.nomeFormaDePagamento = "Créditos de fornecedor";
      }

      this.$swal({
        title: "Finalizar pedido",
        icon: "question",
        html:
          "Endereço selecionado: <b>" +
          this.nomeEndereco +
          "</b><br><br>Forma de pagamento: <b>" +
          this.nomeFormaDePagamento +
          "</b>",
        showCancelButton: true,
        confirmButtonText: "Finalizar pedido",
        cancelButtonText: "Voltar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.botaoFinalizarCompraCarregando = true;
          this.carregarSacola();

          for (var i = 0; i < this.sacola.produtos.length; i++) {
            if (this.sacola.produtos[i].Status > 1) {
              this.produtosIndisponiveis.push(
                " " + this.sacola.produtos[i].NomeProduto
              );
            }
          }
          if (this.produtosIndisponiveis.length != 0) {
            this.$swal({
              title: "Revise a sua sacola",
              text: "Alguns itens já foram comprados por outra cliente...",
              icon: "warning",
              type: "warning",
              confirmButtonText: "OK",
              showLoaderOnConfirm: true,
            }).then(() => {
              window.location.href = "/sacola/";
              return;
            });
          } else {
            this.$swal({
              title: "Aguarde um momento",
              text: "O seu pedido está sendo finalizado...",
              icon: "info",
              type: "warning",
              showConfirmButton: false,
            });

            this.isLoading = true;
// console.log(soma)
// console.log(valorFrete)
// console.log(desconto)
// console.log(usoCredito)

            try {
              axios
                .post(ambiente + "pagamento.php", {
                  idSacola: idSacola,
                  idUsuario: this.$store.getters.user.id,
                  idEndereco: idEnderecoSelecionado,
                  email: this.$store.getters.user.email,
                  codigoPedido: this.sacola.produtos[0].idSacola,
                  soma: soma*100,
                  freteTipo: freteTipo,
                  formaDePagamento: formaDePagamento,
                  valorFrete: valorFrete*100,
                  desconto: desconto*100,
                  usoCredito: usoCredito*100,
                })
                .then((response) => {
                  this.$swal(
                    "Pedido finalizado",
                    "Seu pedido foi finalizado com sucesso!",
                    "success"
                  ).then(() => {
                    this.isLoading = true;
                    window.location.href = response.data.mercadopago;
                  });
                })
                .catch((error) => {
                  this.isLoading = false;
                  console.log(error);
                });
            } catch (err) {
              this.isLoading = false;
              console.log(err);
            }
            this.isLoading = false;
          }
        } else {
          this.frete.tipo = "";
          this.valorFrete = 0;
          this.isLoading = false;
          return;
        }
      });

      this.carregarSacola();
    },

    async removerDaSacola(produto) {
      this.$swal({
        title: "Remover item da sacola",
        text: "Tem certeza?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;

          if (!this.$store.getters.user) {
            this.$gtag.event("Produto Removido da Sacola - Pelo Usuário");
          } else if (this.$store.getters.user.conta != 1) {
            this.$gtag.event("Produto Removido da Sacola - Pelo Usuário");
          }

          try {
            axios
              .post(ambiente + "sacola.php", {
                transaction: 0,
                produto: produto,
                idUsuario: this.$store.getters.user.id,
              })
              .then(() => {
                this.carregarSacola();
                this.carregarCreditoFornecedor();
                this.isLoading = false;
                this.$swal.fire({
                  toast: true,
                  icon: "success",
                  title: "Produto removido",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                });
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
                this.$swal(
                  "Ocorreu um erro",
                  "Por favor, tente novamente.",
                  "error"
                );
              });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$swal(
              "Ocorreu um erro",
              "Por favor, tente novamente.",
              "error"
            );
          }

          this.isLoading = false;
        }
      });
    },

    async removerDaSacolaProdutoVendido(produto) {
      this.$swal({
        title: "Uma pena terem comprado esse produto antes de você...",
        text: "Pressione OK para confirmar a remoção.",
        showCancelButton: true,
        confirmButtonText: "OK",
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;

          if (!this.$store.getters.user) {
            this.$gtag.event("Produto Removido da Sacola - Já vendido");
          } else if (this.$store.getters.user.conta != 1) {
            this.$gtag.event("Produto Removido da Sacola - Já vendido");
          }

          try {
            axios
              .post(ambiente + "sacola.php", {
                transaction: 0.1,
                produto: produto,
                idUsuario: this.$store.getters.user.id,
              })
              .then(() => {
                this.carregarSacola();
                this.carregarCreditoFornecedor();
                this.isLoading = false;
                this.$swal("Produto removido", "", "success");
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
                this.$swal(
                  "Ocorreu um erro",
                  "Por favor, tente novamente.",
                  "error"
                );
              });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
            this.$swal(
              "Ocorreu um erro",
              "Por favor, tente novamente.",
              "error"
            );
          }
          this.isLoading = false;
        }
      });
    },

    async carregarSacola() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 2,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.sacola = response.data;
            if (!this.sacola.produtos) {
              this.soma = 0;
            } else {
              this.soma = this.sacola.produtos[0].Valor;
            }

            this.carregarCreditoFornecedor();
            this.consultaCEP(this.soma);

            if (response.data.status != 400) {
              this.contaProdutosSacola = this.sacola.produtos.length;
            } else {
              this.contaProdutosSacola = 0;
              this.sacola.produtos = [];
            }

            this.isLoading = false;

            this.carregarSugestoesInteligentes();
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }

      this.isLoading = false;
    },

    carregarEnderecos() {
      this.isLoading = true;
      axios
        .post(ambiente + "enderecos.php", {
          transaction: 1,
          idUsuario: this.$store.getters.user.id,
        })
        .then((response) => {
          this.enderecos = response.data.enderecos;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    carregarCreditoFornecedor() {
      if (this.$store.getters.user) {
        this.isLoading = true;
        axios
          .post(ambiente + "fornecedor.php", {
            transaction: 0,
            idUsuario: this.$store.getters.user.id,
            email: this.$store.getters.user.email,
          })
          .then((response) => {
            if (response.data.credito.Credito > 0) {
              this.fornecedor.credito = response.data.credito.Credito;
            }

            if (
              parseFloat(this.soma) + parseFloat(this.valorFrete) <=
              this.fornecedor.credito
            ) {
              this.fornecedor.creditoEmUso =
                parseFloat(this.soma) +
                parseFloat(this.valorFrete) +
                parseFloat(this.desconto);
              this.fornecedor.saldoAposCompra =
                this.fornecedor.credito - this.fornecedor.creditoEmUso;
            } else if (
              parseFloat(this.soma) + parseFloat(this.valorFrete) >=
              this.fornecedor.credito
            ) {
              this.fornecedor.creditoEmUso = this.fornecedor.credito;
              this.fornecedor.saldoAposCompra = 0;
            }

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },

    consultaCEP(total) {
      this.isLoading = true;
      axios
        .post(ambiente + "frete.php", {
          cep: this.cepEntrega,
        })
        .then((response) => {
          if (response.data.status != 200) {
            this.isLoading = false;
            this.frete = response.data;
            if (
              (String(this.cepEntrega).charAt(0) == 0 ||
                String(this.cepEntrega).charAt(0) == 1 ||
                String(this.cepEntrega).charAt(0) == 2 ||
                String(this.cepEntrega).charAt(0) == 3) &&
              !String(this.cepEntrega).startsWith("243") && //cada linha adicionada neste padrão torna o cep com frete grátis
              this.soma < 250 &&
              this.cepEntrega != "" &&
              this.cepEntrega != 24300000 &&
              this.frete.fretepac != 0
            ) {
              this.frete.mensagem =
                "Opa! Faltam R$ " +
                parseFloat(250 - parseFloat(total))
                  .toFixed(2)
                  .toString()
                  .replace(".", ",") +
                " para você conseguir FRETE GRÁTIS!";
              this.descontoFlag = 0;
              this.desconto = 0;
            } else if (
              (String(this.cepEntrega).charAt(0) == "4" ||
                String(this.cepEntrega).charAt(0) == "5" ||
                String(this.cepEntrega).charAt(0) == "6") &&
              this.soma < 300 &&
              this.cepEntrega != "" &&
              this.cepEntrega != 24300000
            ) {
              this.frete.mensagem =
                "Opa! Faltam R$ " +
                parseFloat(300 - parseFloat(total))
                  .toFixed(2)
                  .toString()
                  .replace(".", ",") +
                " para você conseguir o FRETE PROMOCIONAL de R$ 19,99!";
              this.descontoFlag = 0;
              this.desconto = 0;
            } else if (
              (String(this.cepEntrega).charAt(0) == "4" ||
                String(this.cepEntrega).charAt(0) == "5" ||
                String(this.cepEntrega).charAt(0) == "6") &&
              this.soma >= 300
            ) {
              this.frete.mensagem =
                "Parabéns, você ganhou o FRETE PROMOCIONAL de R$ 19,99!";
              this.descontoFlag = 2;
              this.desconto =
                (parseFloat(-19.99) + parseFloat(this.valorFrete)) *
                parseFloat(-1);
            } else if (
              (String(this.cepEntrega).charAt(0) == "0" ||
                String(this.cepEntrega).charAt(0) == "1" ||
                String(this.cepEntrega).charAt(0) == "2" ||
                String(this.cepEntrega).charAt(0) == "3") &&
              this.soma >= 250
            ) {
              this.frete.mensagem = "Parabéns, você ganhou o FRETE GRÁTIS!";
              this.valorFretePac = response.data.fretepac;
              this.valorFreteSedex = response.data.fretesedex;
              this.descontoFlag = 1;
              this.desconto = parseFloat(this.valorFrete) * parseFloat(-1);
            } else if (
              String(this.cepEntrega).startsWith("243")
            ) {
              this.frete.mensagem = "Parabéns, você ganhou o FRETE GRÁTIS!";
              this.valorFretePac = response.data.fretepac;
              this.valorFreteSedex = response.data.fretesedex;
              this.descontoFlag = 1;
              this.desconto = parseFloat(this.valorFrete) * parseFloat(-1);
            }
          } else {
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    async carregarSugestoesInteligentes() {
      var auxProdutosIdProduto = [];
      //ALOCAR OS IDS DE SACOLA EM UM ARRAY
      this.sacola.produtos.forEach(function(entry) {
          auxProdutosIdProduto.push(entry.idProduto);
      });

      try {
        await axios
          .post(ambiente + "sugestoes.php", {
            idProduto: auxProdutosIdProduto,
          })
          .then((response) => {
            this.sugestoes = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style scoped>
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
