import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import NowUiKit from './plugins/now-ui-kit';
import store from './store'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'

import VueGtag from "vue-gtag";
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueLazyload from 'vue-lazyload'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'

import VueGraph from 'vue-graph'
Vue.use(VueGraph)

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: { 
    "image": true,
    "table": true,
    "code": true,
    "separator": true,
    "removeFormat": true,
  },
});

import 'sweetalert2/dist/sweetalert2.min.css';

import VueMask from 'v-mask'
Vue.use(VueMask);


Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: "G-YSS6GYQXGT" }
});

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/img/loading.gif',
  loading: '/img/loading.gif',
  attempt: 1
})
const options = {
  confirmButtonColor: '#19CE0F',
  cancelButtonColor: '#F96432',
};

Vue.use(VueSweetalert2, options);
Vue.use(VueSocialSharing);
Vue.use(VueMeta)

Vue.use(BootstrapVue);

Vue.use(NowUiKit, router, Datetime);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');