<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande mb-0 mt-3" style="min-height: 100vh">
      <h5 class="p-3">
        Creditar vendas
        <font style="color: #BEBEBE">({{ vendas.length }})</font>
      </h5>

      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center m-0 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="vendasPaginadas"
      ></b-pagination>
      <div
        class="container"
        id="vendasPaginadas"
        :vendas="vendas"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <div class="row">
          <div class="col-md-12">
            <ul v-if="vendas.length > 0" class="list-group mb-3">
              <span
                v-for="(produto, index) in vendas.slice(
                  perPage * (currentPage - 1),
                  perPage * currentPage
                )"
                :key="index"
                class="mt-1"
              >
              <div>
                <li
                  class="list-group-item d-flex justify-content-left lh-condensed mx-0 pb-1"
                >
                  <img
                    :src="
                      'https://www.infanziabrecho.com.br/uploads/' +
                        produto.Foto1
                    "
                    class="rounded-circle mr-4"
                    style="width: 50px; height: 50px"
                  />
                  <div class="mx-0 px-0">
                    <h6 class="my-0">
                      Produto:
                      <router-link
                        :to="
                          `/administrativo/produto/detalhe?produto=${produto.idProduto}`
                        "
                        target="_blank"
                      >
                        {{ produto.NomeProduto }} ({{ produto.idProduto }})
                      </router-link>
                      <br />
                      Fornecedor:
                      <router-link
                        :to="
                          `/administrativo/fornecedor/detalhe?fornecedor=${produto.idFornecedor}`
                        "
                      >
                        {{ produto.NomeFornecedor }} ({{
                          produto.idFornecedor
                        }})
                      </router-link>
                      <br />
                      Sacola: {{ produto.idSacola }}
                      <br />
                      Data da entrega: {{ produto.DataEntrega }}
                    </h6>
                    <p class="text-muted m-0 p-0">
                      <span v-if="produto.PrecoDescontoInfanzia != 0">
                        <s
                          >R$
                          {{
                            produto.Preco.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}</s
                        >

                        R$
                        {{
                          produto.PrecoDescontoInfanzia.toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </span>
                      <span v-else-if="produto.PrecoPromocional != 0">
                        <s
                          >R$
                          {{
                            produto.Preco.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}</s
                        >
                        R$
                        {{
                          produto.PrecoPromocional.toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </span>
                      <span v-else-if="produto.PrecoCategoria != 0">
                        <s
                          >R$
                          {{
                            produto.Preco.toFixed(2)
                              .toString()
                              .replace(".", ",")
                          }}</s
                        >
                        R$
                        {{
                          produto.PrecoCategoria.toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </span>
                      <span v-else>
                        R$
                        {{
                          produto.Preco.toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </span>
                    </p>

                    <b-button
                      size="sm"
                      pill
                      style="background-color: white; color: #ff6052; border: 1px solid #ff6052;"
                      @click="
                        creditar(
                          produto,
                          produto.idFornecedor,
                          produto.NomeFornecedor
                        )
                      "
                      >Creditar item ao fornecedor</b-button
                    >
                  </div>
                </li>
                </div>
              </span>
            </ul>
          </div>
        </div>
      </div>

      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center m-0 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="vendasPaginadas"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Button, Checkbox } from "@/components";
import AdministrativoMenu from "./AdministrativoMenu";
import ambiente from "../../variables/variables.js"

export default {
  props: {
    filtro: Object,
    colocarNaSacola: { type: Function },
    contaProdutosSacola: Number,
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.vendas.length;
    },
  },

  watch: {
    $route() {
      this.carregarProdutosVendidos();
    },
  },
  mounted() {
    if (this.$store.getters.user != null) {
      this.carregarProdutosVendidos();
    }
  },
  components: {
    Loading,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    AdministrativoMenu,
  },

  data() {
    return {
      paginaAtual: "compras",
      produtos: null,

      produto: {
        idProduto: Number,
        Preco: Number,
        PrecoDescontoInfanzia: Number,
        PrecoPromocional: Number,
        PrecoCategoria: Number,
      },

      perPage: 50,
      currentPage: 1,

      vendas: {},

      idProduto: "",

      isLoading: false,
      fullPage: true,
      onCancel: this.cancelLoading(),

      modals: {
        busca: false,
        login: false,
      },
    };
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarProdutosVendidos() {
      this.isLoading = true;
      try {
        await axios
          .post(ambiente + "sacola.php", {
            transaction: 4,
            conta: this.$store.getters.user.conta,
            modulo: 4,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            if (response.data.produtos) {
              this.vendas = response.data.produtos;
            } else {
              this.vendas = [];
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
      this.isLoading = false;
    },

    creditar(produto, idFornecedor, nomeFornecedor) {
      this.isLoading = true;
      this.$gtag.event("Admin - Venda Creditada");

      this.$swal({
        title: "Creditar venda",
        text:
          "Tem certeza que deseja creditar o produto " +
          produto.NomeProduto +
          " ao fornecedor " +
          nomeFornecedor +
          " (" +
          idFornecedor +
          ")?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Creditar",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          try {
            axios
              .post(ambiente + "produto.php", {
                transaction: 3,
                conta: this.$store.getters.user.conta,
                produto: produto,
                idFornecedor: idFornecedor,
              })
              .then((response) => {
                if (response.data == true) {
                  this.$swal.fire({
                    toast: true,
                    icon: "success",
                    title: "Produto creditado com sucesso!",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  this.carregarProdutosVendidos();
                } else {
                  this.$swal.fire({
                    toast: true,
                    icon: "error",
                    title: "Ocorreu um erro, tente novamente.",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  this.carregarProdutosVendidos();
                }
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
              });
          } catch (error) {
            this.isLoading = false;
            console.log(error);
          }
        }
      });
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
