<template>
  <div>
    <MainNavbar :modals="modals" :user="user" :currentPage="paginaAtual" />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="container mt-5 pt-5 mx-1 px-0" style="min-height: 100vh">
      <h4>Dados Pessoais</h4>
      <p>Nome: {{ user.fullname }}</p>
      <p>E-mail: {{ user.email }}</p>
      <p>Telefone: {{ user.Telefone }}</p>
      <p>CPF: {{ user.CPF }}</p>
      <p>Senha: <i class="fas fa-lock ml-2" style="color: gray"></i></p>
      <center>
        <button class="btn btn-primary" @click="modals.editarDados = true">
          <i class="far fa-edit"></i> Editar Dados
        </button>
        <button class="btn btn-primary" @click="modals.editarSenha = true">
          <i class="fas fa-lock"></i> Alterar a Senha
        </button>
        <button class="btn btn-success" @click="$router.push('/')">
          <i class="fas fa-store"></i> Voltar para Loja
        </button>
      </center>
    </div>

    <!-- INICIO MODAL EDITAR DADOS -->
    <modal
      :show.sync="modals.editarDados"
      :show-close="false"
      headerClasses="justify-content-center"
      style="z-index: 99999"
    >
      <p>Editar dados pessoais:</p>
      <label class="m-2"
        >Nome
        <input
          id="nome"
          type="text"
          class="form-control m-1"
          name="nome"
          v-model="novoNome"
          autocomplete="off"
      /></label>
      <label class="m-2"
        >Telefone
        <input
          v-mask="'(##)#########'"
          id="telefone"
          type="text"
          class="form-control m-1"
          name="telefone"
          v-model="novoTelefone"
          autocomplete="off"
      /></label>
      <label class="m-2"
        >CPF
        <input
          id="CPF"
          type="text"
          class="form-control m-1"
          name="CPF"
          v-model="novoCPF"
          autocomplete="off"
      /></label>

      <center>
        <n-button type="secondary" @click.native="modals.editarDados = false"
          >Cancelar</n-button
        >
        <n-button type="primary" @click.native="salvarAlteracoes()"
          >Salvar Alterações</n-button
        >
      </center>
    </modal>
    <!-- FIM MODAL EDITAR DADOS -->

    <!-- INICIO MODAL EDITAR SENHA -->
    <modal
      :show.sync="modals.editarSenha"
      :show-close="false"
      headerClasses="justify-content-center"
      style="z-index: 99999"
    >
      <p>Alterar a senha:</p>
      <label class="m-2"
        >Informe a senha <b>atual</b>
        <input
          id="senhaAtual"
          type="password"
          class="form-control m-1"
          name="senhaAtual"
          placeholder="Senha atual"
          v-model="senhaAtual"
          autocomplete="off"
      /></label>
      <hr />
      <label class="m-2"
        >Informe a <b>nova</b> senha
        <input
          id="novaSenha"
          type="password"
          class="form-control m-1"
          name="senha"
          placeholder="Senha nova"
          v-model="novaSenha"
          autocomplete="off"
      /></label>
      <label class="m-2"
        >Confirme a <b>nova</b> senha
        <input
          id="confirmaSenha"
          type="password"
          class="form-control m-1"
          name="confirmaSenha"
          placeholder="Confirmar senha"
          v-model="confirmaSenha"
          autocomplete="off"
      /></label>
      <div
        style="color: red"
        v-if="
          novaSenha.length > 0 &&
            confirmaSenha.length > 0 &&
            confirmaSenha != novaSenha
        "
      >
        • As senhas precisam ser idênticas.
      </div>
      <div
        style="color: red"
        v-if="
          novaSenha.length > 0 &&
            confirmaSenha.length > 0 &&
            confirmaSenha == novaSenha &&
            novaSenha.length < 8
        "
      >
        • A sua senha precisa ter no mínimo 8 caracteres.
      </div>
      <center>
        <n-button type="secondary" @click.native="modals.editarSenha = false"
          >Cancelar</n-button
        >
        <n-button
          type="primary"
          @click.native="salvarAlteracoesSenha()"
          v-if="
            novaSenha.length > 0 &&
              confirmaSenha.length > 0 &&
              confirmaSenha == novaSenha &&
              novaSenha.length >= 8 &&
              senhaAtual.length > 3
          "
          >Salvar Alterações
        </n-button>
        <n-button type="secondary" v-else>Salvar Alterações </n-button>
      </center>
    </modal>
    <!-- FIM MODAL EDITAR SENHA -->
  </div>
</template>
<script>
// import firebase from "firebase";
import MainNavbar from "./MainNavbar";
import { Modal, Button } from "@/components";
import { mapGetters } from "vuex";
import store from "../store";
import * as axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },

  mounted() {
    if (!this.$store.getters.user) {
      this.$gtag.event("Visualização - Dados Pessoais");
    } else if (this.$store.getters.user.conta != 1) {
      this.$gtag.event("Visualização - Dados Pessoais");
    }
  },

  components: {
    MainNavbar,
    Modal,
    [Button.name]: Button,
    Loading,
  },
  data() {
    return {
      paginaAtual: "dados-pessoais",
      novoNome: this.$store.getters.user.fullname,
      novoTelefone: this.$store.getters.user.Telefone,
      novoCPF: this.$store.getters.user.CPF,
      modals: {
        editarDados: false,
        editarSenha: false,
      },
      isLoading: false,
      onCancel: Function,
      fullPage: "",
      novaSenha: "",
      confirmaSenha: "",
      senhaAtual: "",
    };
  },
  methods: {
    salvarAlteracoes() {
      this.modals.editarDados = false;
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Dados Pessoais Editados");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Dados Pessoais Editados");
      }

      axios
        .post(ambiente + "editar_cadastro.php", {
          nome: this.novoNome,
          cpf: this.novoCPF,
          email: this.$store.getters.user.email,
          telefone: this.novoTelefone,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.isLoading = false;
            localStorage.setItem("infanzia", JSON.stringify(response.data));
            store.dispatch("fetchUser", response.data);

            this.$swal.fire({
              toast: true,
              icon: "success",
              title: response.data.title + "\n" + response.data.message,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.isLoading = false;
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: response.data.title + "\n" + response.data.message,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          alert(error);
        });
    },

    salvarAlteracoesSenha() {
      this.modals.editarSenha = false;
      this.isLoading = true;

      if (!this.$store.getters.user) {
        this.$gtag.event("Senha Alterada");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Senha Alterada");
      }

      axios
        .post(ambiente + "redefinir_senha.php", {
          email: this.$store.getters.user.email,
          novaSenha: this.novaSenha,
          senhaAtual: this.senhaAtual,
          transaction: 2,
        })
        .then((response) => {
          this.isLoading = false;
          if (response.data == true) {
            this.$swal.fire({
              toast: true,
              icon: "success",
              title: "Senha alterada com sucesso!",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: "Ops... Ocorreu um erro.",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$swal.fire({
            toast: true,
            icon: "error",
            title: "Ops... Ocorreu um erro.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
<style scopped></style>
