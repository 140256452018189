<template>
  <div class="section section-login m-0 p-0">
    <div v-if="isLoading">
      <center>
        <h5 class="mt-2">Aguarde, carregando...</h5>
        <img class="m-0" style="width: 70px" src="/img/loading.gif" />
      </center>
    </div>
    <div v-else>
      <div v-if="user == null">
        <div v-if="!formCadastro.formCadastrar">
          <p class="mt-3 mb-0 mx-3">
            Entre com e-mail e senha para prosseguir:
          </p>

          <div class="mx-2">
            <form action="#" @submit.prevent="login">
              <input
                id="email"
                type="email"
                class="form-control m-2"
                name="email"
                value
                required
                v-model="formLogin.email"
                placeholder="E-mail"
              />

              <input
                id="password"
                type="password"
                class="form-control m-2"
                name="password"
                required
                v-model="formLogin.senha"
                placeholder="Senha"
              />

              <p class="mb-0" style="font-size: 14px">
                Esqueceu a senha?
                <a href="#" @click="redefinirSenha(formLogin.email)"
                  >Redefinir senha agora!</a
                >

                <br />
                <center>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style="width: 200px"
                  >
                    Login
                  </button>
                </center>
              </p>
            </form>
            <br />
            <center>
              <p class="mb-0">Ainda não possui cadastro?</p>
              <button
                @click="formCadastro.formCadastrar = true"
                class="btn btn-primary"
                style="width: 200px"
              >
                Cadastre-se já!
              </button>
            </center>
          </div>
        </div>
        <div v-else-if="formCadastro.termosUso">
          <p class="mt-4 mb-4 mx-0" style="text-align: center;">
            Termos de Uso
          </p>

          <div class="mx-2">
            <div style="max-height: 40vh; overflow-y: auto">
              Os presentes Termos de Uso regulam as condições gerais de
              utilização dos sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ, neste ato representada na forma de seu contrato
              social, dentro do website www.infanziabrecho.com.br ("Site"). Ao
              utilizar os sites, plataformas ou aplicações de Internet do
              INFANZIA BRECHÓ você concorda expressa e integralmente com os
              presentes Termos de Uso.<br />
              Recomendamos aos usuários que, previamente à utilização dos sites,
              plataformas ou aplicações de Internet do INFANZIA BRECHÓ, realizem
              atenta leitura dos presentes TERMOS DE USO.<br />
              1. DEFINIÇÕES<br />
              A menos que por outra forma expressamente disposta ou exigida em
              função do contexto, as expressões iniciadas em letra maiúscula, no
              singular ou plural, terão os significados especificados ao longo
              deste Termo, entre parênteses e sublinhados, ou os significados
              listados abaixo:<br />
              FORNECEDOR: significa o Usuário cadastrado no Site quando envia
              suas peças de roupas e acessórios e tem seu Produto anunciado no
              site;<br />
              USUÁRIO COMPRADOR: significa o Usuário cadastrado no Site quando
              realiza compras de Produtos via Site.<br />
              DADOS PESSOAIS: significam todas as informações necessárias para
              identificar o Usuário na realização do cadastro.<br />
              2. OBJETO<br />
              Através dos seus sites, plataformas e aplicações de Internet, o
              INFANZIA BRECHÓ disponibiliza o acesso a diversos conteúdos e
              serviços, permitindo maior interação com seus clientes e
              possibilitando a realização de compras online. Os presentes Termos
              de Uso regulam a utilização desses sites, plataformas e aplicações
              de Internet.<br />
              3. FLUXO PARA VENDER NO INFANZIA BRECHÓ<br />
              Para vender no Site, o FORNECEDOR deverá ter se cadastrado
              previamente no site e, após aprovação, que é feita por e-mail,
              poderá enviar suas roupas e acessórios para o INFANZIA BRECHÓ para
              aprovação das peças.<br />
              Caso o FORNECEDOR opte pela devolução dos Produtos não
              selecionados, arcará com os todos custos relacionados ao envio.<br />
              Se o endereço do FORNECEDOR estiver dentro da zona atendida pelo
              INFANZIA BRECHÓ, o FORNECEDOR deverá agendar a retirada através do
              e-mail contato@infanziabrecho.com.br.<br />
              Se o endereço do FORNECEDOR estiver fora da zona atendida pelo
              INFANZIA BRECHÓ, o FORNECEDOR enviará a sacola pelos Correios.<br />
              Em caso de roubo ou extravio o INFANZIA BRECHÓ não se
              responsabiliza por erros dos Correios, dessa forma, informa e
              adverte aos Usuários Vendedores a garantir o seguro dos Correios
              no momento do envio.<br />
              O FORNECEDOR obriga-se a não enviar produtos de contrabando,
              falsificados ou adulterados, bem como cópia ou réplica de produtos
              de terceiros, ou quaisquer produtos que violem leis de direitos
              autorais, patentes, marcas, modelos e desenhos industriais.<br />
              Caso o INFANZIA BRECHÓ verifique a existência de produtos nestas
              condições, reserva-se no direito de destruí-los ou, a seu
              exclusivo critério, doá-los para cooperativas de reciclagem de
              tecidos.<br />
              4. PROCESSAMENTO DAS ROUPAS E ACESSÓRIOS<br />
              O INFANZIA BRECHÓ, ao receber a os produtos, irá enviar ao
              FORNECEDOR um e-mail contendo a quantidade dos Produtos recebidos,
              bem como a previsão para conclusão do processamento de suas roupas
              e acessórios.<br />
              Após o processamento dos produtos pelo INFANZIA BRECHÓ, o
              FORNECEDOR receberá um e-mail contendo: (i) fotos, títulos e
              descrição detalhada dos Produtos que foram selecionados, (ii)
              valor sugerido para tais Produtos, (iii) contagem dos Produtos não
              selecionados e (iv) se for o caso, e-mail de confirmação de
              destino das peças não selecionadas.<br />
              Sobre o valor sugerido para os Produtos selecionados, o Usuário
              poderá fazer um ajuste, dentro de 7 (sete) dias antes do Anúncio
              ir ao ar, que corresponderá ao aumento de até 25% (vinte e cinco
              por cento) sobre o valor do Produto, ou diminuição de até 50%
              (cinquenta por cento).<br />
              Se o FORNECEDOR ajustar o valor fora dos moldes acima, receberá
              uma mensagem do INFANZIA BRECHÓ para optar por fazer o ajuste
              adequado ou por receber o Produto de volta.<br />
              Se o FORNECEDOR optar pela devolução do(s) Produto(s) cujo valor
              não concordar, deverá arcar com o pagamento do frete (caso resida
              fora do município de NITERÓI). A devolução somente ocorrerá após
              confirmação deste pagamento.<br />
              Os Produtos selecionados serão mantidos sob a guarda do INFANZIA
              BRECHÓ, sem que a propriedade Produtos seja transferida,
              comprometendo-se o INFANZIA BRECHÓ a manter os Produtos em
              perfeito estado de conservação, até a data a data da efetiva venda
              ao Usuário Comprador.<br />
              Os Produtos selecionados serão anunciados no Site pela próprio
              INFANZIA BRECHÓ, pelo período máximo de 180 (cento e oitenta)
              dias.<br />
              Durante este período, poderá o INFANZIA BRECHÓ enviar mensagens ao
              FORNECEDOR sugerindo desconto dos Produtos, sendo que a aceitação
              ficará a exclusivo critério do FORNECEDOR.<br />
              A qualquer momento, poderá o FORNECEDOR solicitar a devolução dos
              Produtos, enviando um e-mail para contato@infanziabrecho.com.br.
              <br />
              5. RECEBIMENTO DAS VENDAS PELO AO FORNECEDOR<br />
              Uma vez vendido as roupas e acessórios, o INFANZIA BRECHÓ pagará
              ao FORNECEDOR 50% (cinquenta por cento) do valor vendido (sem
              taxas e comissões das plataformas de venda). Os demais 50%
              (cinquenta por cento) integram a comissão do INFANZIA BRECHÓ
              ("Comissão").<br />
              Os valores serão efetuados após 30 (trinta) dias, a contar do
              pagamento efetuado pelo cliente ou das plataformas de venda, e se
              transcorrido o Período de Direito de Arrependimento, sem que o
              Produto tenha sido devolvido, o que ocorrer primeiro.<br />
              6. ADESÃO EM CONJUNTO COM A POLÍTICA DE PRIVACIDADE E SEGURANÇA<br />
              A utilização dos sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ implica na adesão aos presentes Termos de Uso e à
              versão mais atual da sua Política de Privacidade e Segurança,
              disponível no setor de cadastro do site.<br />
              7. CONDIÇÕES DE ACESSO E USO DOS SITES, PLATAFORMAS OU APLICAÇÕES
              DE INTERNET DO INFANZIA BRECHÓ<br />
              Em geral, o acesso aos sites, plataformas e aplicações de Internet
              do INFANZIA BRECHÓ tem caráter gratuito para todos os usuários e
              não exige prévia inscrição ou registro.<br />
              Contudo, para usufruir de algumas funcionalidades dos sites,
              plataformas e aplicações de Internet do INFANZIA BRECHÓ o usuário
              eventualmente precisará cadastrar-se, criando uma Conta de
              Usuário, com Login e Senha próprios, além de outros dados a seu
              respeito.<br />
              É responsabilidade do usuário prestar unicamente informações
              corretas, verdadeiras, autênticas, completas e atualizadas, bem
              como zelar pelo sigilo de sua Senha, não divulgando-a a
              terceiros.<br />
              Cada usuário é responsável exclusivo pelos seus atos no contexto
              dos sites, plataformas e aplicações de Internet do INFANZIA
              BRECHÓ, especialmente quando utilizado o seu Login e Senha.<br />
              O INFANZIA BRECHÓ não se responsabiliza pelo conteúdo, correção,
              veracidade, autenticidade, completude ou atualização dos dados
              prestados por seus usuários, nem mesmo pelo eventual uso indevido
              de informações publicadas por usuários ou por fraudes decorrentes
              da violação de senhas.<br />
              O INFANZIA BRECHÓ não pactua com a publicação de conteúdos que
              infrinjam direitos de autor, segredos de negócio ou quaisquer
              outros direitos de terceiros, ou ainda que tenham natureza
              discriminatória, ofensiva ou ilícita.<br />
              O usuário obriga-se a utilizar os sites, plataformas e aplicações
              de Internet do INFANZIA BRECHÓ de forma a não causar prejuízos a
              quaisquer direitos ou interesses de terceiros, ou de qualquer
              forma que possa vir a causar dano, sobrecarregar, inutilizar ou
              impedir o seu regular funcionamento. É especialmente vedado ao
              usuário dos sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ publicar materiais que contenham vírus ou outros
              programas de computador maliciosos, que possam causar danos aos
              sites, plataformas e aplicações de Internet do INFANZIA BRECHÓ,
              ou, ainda, a outros usuários.<br />
              A publicação de quaisquer conteúdos pelo usuário dos sites,
              plataformas e aplicações de Internet do INFANZIA BRECHÓ, incluindo
              mensagens e comentários, implica em licença não-exclusiva,
              irrevogável e irretratável, para sua utilização, reprodução e
              publicação pela INFANZIA BRECHÓ, nos seus sites, plataformas e
              aplicações de Internet, ou ainda em outras plataformas, sem
              qualquer limitação.<br />
              Os sites, plataformas e aplicações de Internet do INFANZIA BRECHÓ
              mantêm um canal de atendimento aos usuários e para denúncia de
              perfis/dados falsos através do endereço eletrônico
              contato@infanziabrecho.com.br. Nesse canal também poderão ser
              encaminhadas quaisquer outras denúncias.<br />
              Os sites, plataformas e aplicações de Internet do INFANZIA BRECHÓ
              se reservam o direito de, sem qualquer aviso prévio, cancelar
              Contas de Usuário e bloquear o acesso a seus serviços caso tenham
              fundada suspeita de falsidade de dados, ou, ainda, em qualquer
              caso de utilização dos sites, plataformas e aplicações de Internet
              do INFANZIA BRECHÓ em desconformidade com a lei, com os presentes
              Termos de Uso ou com a sua Política de Privacidade e Segurança.<br />
              Os sites, plataformas e aplicações de Internet do INFANZIA BRECHÓ
              também se reservam o direito de editar ou mesmo excluir quaisquer
              conteúdos publicados por usuários que não estejam em conformidade
              com a lei, com os presentes Termos de Uso ou com a sua Política de
              Privacidade e Segurança.<br />
              Essa condição, todavia, não configura obrigação do INFANZIA
              BRECHÓ, sendo que, por força do art. 15, parágrafo 3o da Lei
              12.965/14, via de regra solicitações de edição ou remoção de
              conteúdos devem se dar sob a forma de ordem judicial, solicitação
              do Ministério Público, de autoridade policial ou
              administrativa.<br />
              8. INEXISTÊNCIA DE GARANTIA DE CONTINUIDADE DOS SERVIÇOS E
              SUSCETIBILIDADE A FALHAS TÉCNICAS<br />
              Em decorrência de questões técnicas e operacionais, os sites,
              plataformas e aplicações de Internet do INFANZIA BRECHÓ não
              garantem disponibilidade permanente dos seus serviços e
              funcionalidades, bem como se reservam o direito de cancelar
              qualquer destes serviços, plataformas ou funcionalidades, a
              qualquer tempo.<br />
              O INFANZIA BRECHÓ não se responsabiliza por quaisquer danos
              indiretos decorrentes do uso de suas plataformas ou por danos
              diretos ou indiretos decorrentes de caso fortuito ou força maior,
              do uso inadequado da plataforma, ou, ainda, por eventuais
              paralisações transitórias.<br />
              Quando for razoavelmente possível, os sites, plataformas e
              aplicações de Internet do INFANZIA BRECHÓ informarão previamente
              as interrupções do funcionamento dos seus serviços, plataformas e
              funcionalidades.<br />
              Os conteúdos disponibilizados nos sites, plataformas e aplicações
              de Internet do INFANZIA BRECHÓ poderão eventualmente conter erros
              ou falhas técnicas. Colabore nos informando esses problemas
              mediante e-mail para contato@infanziabrecho.com.br.<br />
              9. PRIVACIDADE DE DADOS DOS USUÁRIOS – SPAM – RESPONSABILIDADE POR
              FRAUDES<br />
              O INFANZIA BRECHÓ zela pela privacidade dos dados de seus usuários
              e veda a utilização dos seus sites, plataformas e aplicações de
              Internet para o envio de mensagens não-solicitadas de qualquer
              natureza, via correio eletrônico.<br />
              Contudo, o usuário deve ter ciência de que diversos fraudadores
              tentam se valer de marcas para obter informações pessoais como
              senhas e dados de cartões de crédito. O INFANZIA BRECHÓ NUNCA IRÁ
              SOLICITAR SUA SENHA, NOME DO USUÁRIO, DADOS DE CARTÃO DE CRÉDITO
              OU OUTRAS INFORMAÇÕES PESSOAIS ATRAVÉS DE E-MAIL OU CONTATO
              TELEFÔNICO.<br />
              Caso você receba qualquer e-mail não-solicitado ou potencialmente
              invasivo em nome do INFANZIA BRECHÓ, colabore enviando denúncia
              para contato@infanziabrecho.com.br.<br />
              10. PROPRIEDADE INTELECTUAL<br />
              Os conteúdos acessados mediante os sites, plataformas e aplicações
              de Internet do INFANZIA BRECHÓ, incluindo, sem limitação, textos
              em geral, apresentações, tabelas, bases de dados, fotografias,
              ilustrações, gráficos, vídeos, softwares ou quaisquer outros tipos
              de obras intelectuais, bem como as informações e os dados em geral
              obtidos através dos seus serviços, plataformas e funcionalidades,
              são protegidos por direitos de propriedade intelectual, como
              direitos autorais, marcas, desenhos industriais, patentes, dentre
              outros, sendo que o seu acesso através dos sites, plataformas e
              aplicações de Internet do INFANZIA BRECHÓ não implica em qualquer
              cessão de direitos ou licença adicional ao mero uso no contexto
              dos respectivos sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ.<br />
              Sempre que os sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ oferecerem meios diretos de compartilhamento dos
              seus conteúdos, mediante ferramentas ou ícones de redes sociais ou
              de outros sites, plataformas ou aplicações de Internet (como
              Facebook, Twitter, etc.), fica desde logo autorizado o
              compartilhamento desses específicos conteúdos, de acordo com os
              termos de uso de cada ferramenta, condicionado à devida indicação
              de procedência do conteúdo (crédito) e que o seu compartilhamento
              não tenha finalidade comercial e não concorra com os sites,
              plataformas e aplicações de Internet do INFANZIA BRECHÓ.<br />
              Em quaisquer outros casos, salvo mediante permissão expressa, é
              vedado:<br />
              (i) realizar reproduções adicionais ao mero acesso às obras
              intelectuais, aos conteúdos e às informações disponibilizados nos
              sites, plataformas e aplicações de Internet do INFANZIA BRECHÓ;
              <br />
              (ii) transmitir, distribuir, divulgar ou de qualquer forma de
              comunicar as obras intelectuais, os conteúdos e as informações
              disponibilizados nos sites, plataformas e aplicações de Internet
              do INFANZIA BRECHÓ a terceiros; <br />
              (iii) utilizar artifícios técnicos para quebra de senhas ou da
              codificação dos sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ, para acesso e interpretação do seu código-fonte,
              bem como para tradução para diversa linguagem computacional;
              <br />
              (iv) a transmissão eletrônica dos sites, plataformas e aplicações
              de Internet do INFANZIA BRECHÓ ou de seus conteúdos de um
              computador para outro através de rede, não se incluindo nessa
              restrição a mera utilização dos sites, plataformas e aplicações de
              Internet do INFANZIA BRECHÓ em rede; <br />
              (v) o uso de plataformas e aplicações de Internet do INFANZIA
              BRECHÓ por terceiros ou por qualquer outra pessoa diversa daquele
              que foi originalmente autorizado/licenciado.<br />
              11. CONEXÕES (LINKS) A OUTROS SITES<br />
              Os sites, plataformas e aplicações de Internet do INFANZIA BRECHÓ
              podem conter conexões e links para outros sites na Internet. A
              disponibilização desses links tem como finalidade facilitar a
              busca de informações disponíveis na Internet, não existindo
              qualquer tipo de vinculação ou associação entre o INFANZIA BRECHÓ
              e os proprietários das páginas externas.<br />
              <br />
              O INFANZIA BRECHÓ não avalia previamente, nem controla ou aprova,
              os serviços, informações, dados, arquivos, produtos e qualquer
              tipo de material existente nessas páginas externas, nem assume
              qualquer tipo de responsabilidade pelos danos, prejuízos e/ou
              lucros cessantes de qualquer tipo e que possam decorrer da
              qualidade, utilidade, disponibilidade, confiabilidade e legalidade
              dos serviços, informações, dados, arquivos, produtos ou qualquer
              tipo de material existente nas páginas externas.<br />
              12. INFORMAÇÕES SOBRE PRODUTOS, CARACTERÍSTICAS TÉCNICAS E
              ORIENTAÇÕES DE USO<br />
              As informações e características técnicas sobre os produtos
              comercializados nos sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ não induzem a dispensa de contratação de suporte
              técnico competente e habilitado, quando necessário.<br />
              Os produtos anunciados nos sites, plataformas e aplicações de
              Internet do INFANZIA BRECHÓ podem apresentar pequenas diferenças
              em relação às imagens visualizadas na sua tela. Monitores/telas
              apresentam diferentes calibragens de cor, podendo também causar
              diferenças nas tonalidades das cores, se comparadas com a peça
              física.<br />
              13. SERVIÇOS PRESTADOS POR TERCEIROS ATRAVÉS DO SITE, VÍRUS,
              REQUISITOS DE SEGURANÇA E ATUALIZAÇÃO<br />
              Algumas operações financeiras realizadas nos sites, plataformas e
              aplicações de Internet do INFANZIA BRECHÓ são concluídas em sites,
              plataformas ou aplicações de Internet de terceiros, inclusive para
              assegurar a segurança dos seus dados financeiros. Nesses casos, o
              INFANZIA BRECHÓ exime-se de responsabilidade pelos danos,
              prejuízos e/ou lucros cessantes de qualquer natureza que possam
              advir dos serviços prestados por terceiros ou no ambiente de seus
              sites, plataformas e aplicações de Internet.<br />
              O INFANZIA BRECHÓ exime-se de responsabilidade pelos danos,
              prejuízos e/ou lucros cessantes de qualquer natureza que possam
              advir em decorrência da existência de vírus ou de outros elementos
              nocivos no computador/smartphone/tablet utilizado para acessar os
              seus sites, plataformas e aplicações de Internet, ou ainda nos
              sites, plataformas e aplicações de Internet de terceiros.<br />
              Você é o responsável exclusivo por manter o seu
              computador/smartphone/tablet livre de vírus e atualizado com a
              mais recente versão do sistema operacional e dos softwares
              utilizados para acessar os sites, plataformas e aplicações de
              Internet do INFANZIA BRECHÓ, bem como por eventuais prejuízos
              decorrentes do não atendimento dessas obrigações ou das melhores
              práticas de segurança no acesso à Internet.<br />
              14. PRAZO E ALTERAÇÕES<br />
              O funcionamento dos sites, plataformas e aplicações de Internet do
              INFANZIA BRECHÓ se dá por prazo indeterminado.<br />
              Os sites, plataformas e aplicações de Internet do INFANZIA BRECHÓ,
              no todo ou em cada uma das suas seções, podem ser encerrados,
              suspensos ou interrompidos unilateralmente pela INFANZIA BRECHÓ, a
              qualquer momento e sem necessidade de prévio aviso.<br />
              Os presentes Termos de Uso também podem ser alterados a qualquer
              tempo.<br />
              As alterações destes Termos de uso serão informadas com destaque
              nos sites, plataformas e aplicações de Internet do INFANZIA
              BRECHÓ.<br />
              15. FORO E LEGISLAÇÃO APLICÁVEL<br />
              Todos os itens deste Termo de Uso estão regidos pelas leis
              vigentes na República Federativa do Brasil. Para todos os assuntos
              referentes à sua interpretação e cumprimento, as partes se
              submeterão ao Foro da Comarca de Niterói/RJ, exceção feita a
              reclamações apresentadas por Usuários que se enquadrem no conceito
              legal de consumidores, que poderão submeter ao foro de seu
              domicílio.<br />
            </div>
            <br />
            <center>
              <button
                @click="formCadastro.termosUso = false"
                class="btn btn-primary"
                style="width: 200px"
              >
                Voltar
              </button>
            </center>
          </div>
        </div>
        <div v-else>
          <div class="form-group row justify-content-center">
            <p class="mt-3 mb-0 mx-3">
              Preencha corretamente os campos abaixo para efetuar o seu
              cadastro:
            </p>
          </div>
          <form action="#" @submit.prevent="cadastrar">
            <div class="form-group row justify-content-center">
              <input
                autocomplete="off"
                id="name"
                type="name"
                class="form-control m-2"
                name="name"
                value
                required
                v-model="formCadastro.name"
                placeholder="Nome completo"
              />

              <input
                autocomplete="off"
                id="email"
                type="email"
                class="form-control m-2"
                name="email"
                value
                required
                v-model="formCadastro.email"
                placeholder="E-mail"
              />

              <input
                autocomplete="off"
                id="confirmaEmail"
                type="email"
                class="form-control m-2"
                name="confirmaEmail"
                value
                required
                v-model="formCadastro.emailConfirm"
                placeholder="Confirme o seu e-mail"
              />

              <input
                autocomplete="off"
                required
                v-mask="'(##)#########'"
                id="telefone"
                type="text"
                class="form-control m-2"
                name="telefone"
                v-model="formCadastro.telefone"
                placeholder="Telefone com DDD"
                minlength="5"
              />

              <input
                autocomplete="off"
                id="password"
                type="password"
                class="form-control m-2"
                name="password"
                required
                v-model="formCadastro.password"
                placeholder="Senha"
              />

              <input
                autocomplete="off"
                id="passwordConfirm"
                type="password"
                class="form-control m-2"
                name="passwordConfirm"
                required
                v-model="formCadastro.passwordConfirm"
                placeholder="Confirme a sua senha"
              />

              <p class="mb-0">
                <label style="font-size: 14px">
                  <input
                    type="checkbox"
                    name="termos"
                    style="width: 20px; height: 20px"
                    required
                  />
                  &nbsp; Li e estou de acordo com os&nbsp;</label
                >&nbsp;
                <a href="#" @click="formCadastro.termosUso = true"
                  ><u>termos de uso</u>.</a
                >
              </p>
            </div>
            <div>
              <center>
                <button
                  type="submit"
                  class="btn btn-primary"
                  style="width: 200px"
                >
                  Cadastrar
                </button>

                <br />
                <br />

                <p class="mb-0">Já é cadastrado?</p>

                <button
                  @click="formCadastro.formCadastrar = false"
                  class="btn btn-primary"
                  style="width: 200px"
                >
                  Efetuar login!
                </button>
              </center>
            </div>
          </form>
        </div>
      </div>
      <div v-else>
        <center>
          <br />
          <h5>Login efetuado com sucesso!</h5>
          <p>
            Muito bom ter você aqui,
            {{ user.fullname.replace(/ .*/, "") }}!
          </p>
          <p>Boas compras!</p>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
// import firebase from "firebase";
import { mapGetters } from "vuex";
import store from "../../store";
import * as axios from "axios";
import ambiente from "../../variables/variables.js"

export default {
  name: "login",
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  components: {},
  data() {
    return {
      formCadastro: {
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
        telefone: "",
        formCadastrar: false,
        termosUso: false,
      },
      formLogin: {
        email: "",
        senha: "",
      },
      error: null,
      isLoading: false,
    };
  },
  methods: {
    login() {
      axios
        .post(ambiente + "login.php", {
          email: this.formLogin.email,
          senha: this.formLogin.senha,
        })
        .then((response) => {
          if (response.data.status == 200) {
            localStorage.setItem("infanzia", JSON.stringify(response.data));
            store.dispatch("fetchUser", response.data);
          } else {
            this.$swal.fire({
              toast: true,
              icon: "error",
              title: response.data.title + "\n" + response.data.message,
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    cadastrar() {
      if (
        this.formCadastro.passwordConfirm == this.formCadastro.password &&
        this.formCadastro.email == this.formCadastro.emailConfirm &&
        this.formCadastro.telefone.length >= 12
      ) {
        axios
          .post(ambiente + "cadastro.php", {
            email: this.formCadastro.email,
            password: this.formCadastro.password,
            username: this.formCadastro.name,
            telefone: this.formCadastro.telefone
          })
          .then((response) => {
            if (response.data.status == 200) {
              localStorage.setItem("infanzia", JSON.stringify(response.data));
              store.dispatch("fetchUser", response.data);
              this.$swal.fire({
                toast: true,
                icon: "success",
                title: response.data.title + "\n" + response.data.message,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.$swal.fire({
                toast: true,
                icon: "error",
                title: response.data.title + "\n" + response.data.message,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((error) => {
            alert(error);
          });
      } else if (
        this.formCadastro.passwordConfirm != this.formCadastro.password
      ) {
        this.$swal.fire({
          toast: true,
          icon: "error",
          title:
            "Ops, ocorreu um erro!\n\nAs senhas digitadas não conferem, por favor tente novamente.",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      } else if (this.formCadastro.email != this.formCadastro.emailConfirm) {
        this.$swal.fire({
          toast: true,
          icon: "error",
          title:
            "Ops, ocorreu um erro!\n\nOs e-mails digitados não conferem, por favor tente novamente.",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      } else if (this.formCadastro.telefone.length < 12) {
        this.$swal.fire({
          toast: true,
          icon: "error",
          title:
            "Ops, ocorreu um erro!\n\nInforme um número válido de telefone.",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    signOut() {
      if (!this.$store.getters.user) {
        this.$gtag.event("Usuário fez Logout");
      } else if (this.$store.getters.user.conta != 1) {
        this.$gtag.event("Usuário fez Logout");
      }
      localStorage.removeItem("infanzia");
      store.dispatch("fetchUser", null);
      this.$router.push("/");
      this.$swal.fire({
        toast: true,
        icon: "warning",
        title: "Você deslogou do sistema.",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
    },

    redefinirSenha(email) {
      if (email.length < 5) {
        this.$swal.fire({
          toast: true,
          icon: "warning",
          title: "Preencha o campo e-mail para prosseguir.",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }

      this.$swal({
        title: "Redefinir senha",
        text: "Tem certeza que deseja redefinir a sua senha?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Redefinir",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          try {
            axios
              .post(
                ambiente + "redefinir_senha.php",
                {
                  email: email,
                  transaction: 1,
                }
              )
              .then((response) => {
                this.isLoading = false;
                if (response.data == true) {
                  //senha alterada com sucesso
                  this.$swal.fire({
                    toast: true,
                    icon: "success",
                    title:
                      "Senha alterada com sucesso! Verifique o seu e-mail de cadastro e siga as instruções para prosseguir.\n\nO e-mail poderá demorar até 1 minuto para chegar.",
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                  });
                } else {
                  this.$swal.fire({
                    toast: true,
                    icon: "error",
                    title: "Ocorreu um erro, tente novamente.",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
              });
          } catch (error) {
            this.isLoading = false;
            console.log(error);
          }
        }
      });
    },
  },
};
</script>
<style scopped></style>
