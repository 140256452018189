<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3" style="min-height: 100vh">
      <h5>Descontos</h5>
      <br />
      <h6>Status: 
      <span v-if="promocaoAtiva.ativo == 1" style="color: #19BD09">
      ATIVADO
      </span>
      <span v-else-if="promocaoAtiva.ativo == 0" style="color: #BD4209">
      DESATIVADO
      </span>
      </h6>
      <p class="m-0 p-0">Categoria: {{promocaoAtiva.categoria}}
      </p>
      <p class="m-0 p-0">
        Porcentagem: {{promocaoAtiva.porcentagem*100}}%
      </p>
      <p v-if="promocaoAtiva.meses > 1" class="m-0 p-0">
        Periodo: {{promocaoAtiva.meses}} meses
      </p>
      <p v-else-if="promocaoAtiva.meses == 1" class="m-0 p-0">
        Periodo: todo o periodo (produtos cadastrados em qualquer data)
      </p>
      <p class="m-0 p-0" v-if="promocaoAtiva.ativo == 1">
        Ativado em: {{promocaoAtiva.atualizado}}
      </p>
      <p class="m-0 p-0" v-if="promocaoAtiva.ativo == 0">
        Desativado em: {{promocaoAtiva.atualizado}}
      </p>
      <br />
      <div class="border p-3">
        <p>Criar desconto</p>
        <form v-on:submit.prevent="aplicardGrandeesconto()">
          Selecione o período
          <select
            class="form-control"
            v-model="desconto.periodo"
            style="border-radius: 20px"
          >
            <option value=1>Todos</option>
            <option value=6>6 meses</option>
            <option value=9>9 meses</option>
            <option value=12>12 meses</option>
            <option value=18>+18 meses</option>
          </select>

          <br />
          Selecione a categoria
          <select
            class="form-control"
            v-model="desconto.categoria"
            style="border-radius: 20px"
          >
          <option
              key="999"
              id="999" :value="todos">TODOS OS PRODUTOS</option>
            <option v-for="categoria in categorias"
              :key="categoria.idCategoria"
              :id="categoria.idCategoria" :value="categoria">{{categoria.categoria}}</option>
              
          </select>
          <br />
          Selecione a porcentagem
          <select
            class="form-control"
            v-model="desconto.porcentagem"
            style="border-radius: 20px"
          >
            <option value=0.1>10%</option>
            <option value=0.15>15%</option>
            <option value=0.2>20%</option>
            <option value=0.25>25%</option>
            <option value=0.3>30%</option>
            <option value=0.35>35%</option>
            <option value=0.40>40%</option>
          </select>
          <button class="btn btn-success my-5 mr-3" type="submit">
            Aplicar desconto
          </button>
          <button class="btn btn-danger my-5 mr-3" type="reset" @click="desativarDesconto()">
            Desativar desconto
          </button>
        </form>
        
      </div>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.fornecedores.length;
    },
  },

  mounted() {
    this.carregarCategorias();
    this.carregarStatusDesconto();
  },
  components: {
    Loading,
    AdministrativoMenu,
  },

  data() {
    return {
      isLoading: false,

      desconto: {
        periodo: "",
        categoria: Object,
        porcentagem: "",
      },
      promocaoAtiva: {
        categoria: "",
        porcentagem: "",
        meses: "",
        ativo: "",
        atualizado: "",
      },

      todos: {
        idCategoria: 0,
        categoria: "TODOS OS PRODUTOS"
      },

      categorias: [],

      fullPage: true,
      onCancel: this.cancelLoading(),
      // rows: null,
    };
  },
  watch: {
    $route() {
      this.carregarStatusDesconto();
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    async carregarStatusDesconto() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "desconto.php", {
            transaction: 1,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {

            this.promocaoAtiva.categoria = response.data.Categoria;
            this.promocaoAtiva.porcentagem = response.data.Porcentagem;
            this.promocaoAtiva.meses = response.data.Meses;
            this.promocaoAtiva.ativo = response.data.Ativo;
            this.promocaoAtiva.atualizado = response.data.Atualizado;

          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async carregarCategorias() {
      axios
        .post(ambiente + "categorias.php", {
          transaction: "carregar",
        })
        .then((response) => {
          this.categorias = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async aplicardGrandeesconto() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "desconto.php", {
            transaction: 2,
            idUsuario: this.$store.getters.user.id,
            desconto: this.desconto
          })
          .then(() => {})
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.carregarStatusDesconto()
      this.isLoading = false;
    },

    async desativarDesconto() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "desconto.php", {
            transaction: 3,
            idUsuario: this.$store.getters.user.id
          })
          .then(() => {})
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      await this.carregarStatusDesconto();
      this.desconto.periodo = "";
      this.desconto.categoria = {};
      this.desconto.porcentagem = "";

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
