<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="cardGrande p-3 mb-0 mt-3">
      <h5>Produtos cadastrados <font style="color: #BEBEBE">({{ produtos.length }})</font></h5>

      <div class="pt-0 mt-0 mb-2">
        <div class="row">
          <div class="col">
            <label class="p-0 m-0"
              >Buscar
              <input type="text" v-model="filtro.busca">
              <button class="btn btn-primary m-0 px-2 py-1" @click="limpaBusca()">
                <i class="fas fa-times"></i>
              </button>
            </label>
          </div>
          <div class="col">
          <label class="p-0 m-0"
          >Status
          <select v-model="filtro.estado" class="mr-1">
            <option :value="todos">Todos</option>
            <option :value="disponivel">Disponíveis</option>
            <option :value="sacola">Em Sacola</option>
            <option :value="vendido">Vendido</option>
            <option :value="pendente">Pendente</option>
            <option :value="devolucao">Devolução</option>
          </select>
        </label>
          </div>
          <div class="col">
          <label class="p-0 m-0"
          >Ordenar
          <select v-model="filtro.ordenar" class="mr-1">
            <option value="4">mais recentes</option>
            <option value="5">mais antigos</option>
            <option value="3">menor preço</option>
            <option value="2">maior preço</option>
          </select>
        </label>
          </div>
        </div>
        
      </div>



      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="produtosPaginados"
      ></b-pagination>

      <div
        id="produtosPaginados"
        :produtos="produtos"
        :per-page="perPage"
        :current-page="currentPage"
        class="table-responsive"
      >
        <center>
          <table class="table" v-if="this.produtos.length != null">
            <tr>
              <td>Foto</td>
              <td>Produto</td>
              <td>Fornecedor</td>
              <td>Detalhes</td>
              <td>Preço</td>
            </tr>
            <tr
              v-for="produto in produtos.slice(
                perPage * (currentPage - 1),
                perPage * currentPage
              )"
              :key="produto.idProduto"
            >
              <td>
                <center>
                  <router-link
                    :to="
                      `/administrativo/produto/detalhe?produto=${produto.idProduto}`
                    "
                  >
                    <img
                      style="width: 150px"
                      v-lazy="
                        'https://www.infanziabrecho.com.br/uploads/' +
                          produto.Foto1+'?ver='+timestamp.getTime()
                      "
                    />
                    {{ produto.idProduto }}
                  </router-link>
                </center>
              </td>

              <td>
                <router-link
                  :to="
                    `/administrativo/produto/detalhe?produto=${produto.idProduto}`
                  "
                >
                  {{ produto.NomeProduto }}                  
                </router-link>
                <p
                  class="mt-1"
                  style="font-size: 14px; color: green;"
                  v-if="produto.Status == 0"
                >
                  Disponivel
                </p>
                <p
                  class="mt-1"
                  style="font-size: 14px; color: orange;"
                  v-if="produto.Status == 1"
                >
                  Sacola
                </p>
                <p
                  class="mt-1"
                  style="font-size: 14px; color: blue;"
                  v-if="produto.Status == 2 && produto.Credito == 1"
                >
                  Vendido
                </p>
                <p
                  class="mt-1"
                  style="font-size: 14px; color: purple;"
                  v-if="produto.Status == 2 && produto.Credito == 0"
                >
                  Processando Venda
                </p>
                <p
                  class="mt-1"
                  style="font-size: 14px; color: red;"
                  v-if="produto.Status == 3"
                >
                  Pendente
                </p>
                <p
                  class="mt-1"
                  style="font-size: 14px; color: gray;"
                  v-if="produto.Status == 4"
                >
                  Devolução
                </p>
                <p
                  class="mt-1"
                  v-if="produto.Instagram == 1"
                >
                  <img src="https://www.infanziabrecho.com.br/img/instaicon.png" style="width: 20px">
                </p>
              </td>

              <td>
                <router-link
                  :to="`/administrativo/fornecedor/detalhe?fornecedor=${produto.Fornecedor}`"
                >
                  {{ produto.NomeFornecedor }} ({{ produto.Fornecedor }})
                </router-link>
              </td>

              <td>
                <p class="mb-1" style="font-size: 12px">
                  {{ produto.Marca }}
                </p>
                <p class="mb-1" style="font-size: 12px">
                  {{ produto.Tamanho }}
                </p>

                <p
                  class="mb-1"
                  style="font-size: 12px"
                  v-if="produto.Menino == 1 && (produto.Menina == 0 || !produto.Menina)"
                >
                  Gênero: masculino
                </p>
                <p
                  class="mb-1"
                  style="font-size: 12px"
                  v-else-if="(produto.Menino == 0 || !produto.Menino) && produto.Menina == 1"
                >
                  Gênero: feminino
                </p>
                <p
                  class="mb-1"
                  style="font-size: 12px"
                  v-else-if="produto.Menino == 1 && produto.Menina == 1"
                >
                  Gênero: unisex
                </p>
                <p class="mb-1" style="font-size: 12px; color: red;" v-else>
                  Gênero: NÃO CADASTRADO
                </p>
                <p
                  class="mb-1"
                  style="font-size: 12px"
                  v-if="produto.Condicao == 1"
                >
                  Condição: Wow! Ainda com etiqueta! Que tal presentear alguém?
                </p>
                <p
                  class="mb-1"
                  style="font-size: 12px"
                  v-else-if="produto.Condicao == 2"
                >
                  Condição: Como novo! Nunca usado ou pouquíssimo uso.
                </p>
                <p
                  class="mb-1"
                  style="font-size: 12px"
                  v-else-if="produto.Condicao == 3"
                >
                  Condição: Super conservado! Pouquíssimas marcas de uso.
                </p>
                <p
                  class="mb-1"
                  style="font-size: 12px"
                  v-else-if="produto.Condicao == 4"
                >
                  Condição: Conservado, possui marcas de uso.
                </p>
                <p class="mb-1" style="font-size: 12px; color: red;" v-else>
                  Condição: NÃO CADASTRADO
                </p>
                <p class="mb-1" style="font-size: 12px">
                  Descrição: {{ produto.Descricao }}
                </p>
              </td>

              <td>
                <p v-if="produto.PrecoDescontoInfanzia != 0">
                  <s>
                    {{
                      produto.Preco.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}</s
                  >

                  {{
                    produto.PrecoDescontoInfanzia.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                  <br>
                  ({{
                    Math.ceil(
                      100 -
                        (produto.PrecoDescontoInfanzia * 100) / produto.Preco
                    )
                  }}%)
                </p>
                <p v-else-if="produto.PrecoPromocional != 0">
                  <s>
                    {{
                      produto.Preco.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}</s
                  >
                  <br>
                  {{
                    produto.PrecoPromocional.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                  <br>
                  ({{
                    Math.ceil(
                      100 - (produto.PrecoPromocional * 100) / produto.Preco
                    )
                  }}%)
                </p>
                <p v-else-if="produto.PrecoCategoria != 0">
                  <s>{{
                    produto.Preco.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}</s>
                  <br>
                  {{
                    produto.PrecoCategoria.toFixed(2)
                      .toString()
                      .replace(".", ",")
                  }}
                  <br>
                  ({{
                    Math.ceil(
                      100 - (produto.PrecoCategoria * 100) / produto.Preco
                    )
                  }}%)
                </p>
                <p v-else>
                    {{
                      produto.Preco.toFixed(2)
                        .toString()
                        .replace(".", ",")
                    }}
                </p>
              </td>
            </tr>
          </table>
        </center>
      </div>
      <b-pagination
        v-if="rows > 0"
        class="d-flex justify-content-center m-0 p-0"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="produtosPaginados"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.produtos.length;
    },
  },

  mounted() {
    this.carregarTodosProdutos(this.filtro);
  },
  components: {
    Loading,
    AdministrativoMenu,
  },

  data() {
    return {
      isLoading: false,
      produtos: [],
      filtro: {
        estado: [0, 1, 2, 3, 4],
        busca: '',
        ordenar: 4, // mais novos primeiro
      },

      timestamp: new Date(),

      todos: [0, 1, 2, 3, 4],
      disponivel: [0],
      sacola: [1],
      vendido: [2],
      pendente: [3],
      devolucao: [4],

      perPage: 50,
      currentPage: 1,
      fullPage: true,
      onCancel: this.cancelLoading(),
      // rows: null,
    };
  },
  watch: {
    $route() {
      this.carregarTodosProdutos(this.filtro);
    },

    filtro: {
      handler: function() {
        this.carregarTodosProdutos(this.filtro);
      },
      deep: true,
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    limpaBusca(){
      this.filtro.busca = "";
    },

    async carregarTodosProdutos(filtro) {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "produtos.php", filtro)
          .then((response) => {
            this.produtos = response.data;
            if (this.produtos.length == 0) {
              this.isLoading = false;
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
/* @import "../../assets/css/simple-sugest-autocomplete.css"; */

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
