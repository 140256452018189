<template>
  <div v-if="$store.getters.user.conta == 1" class="section my-0 py-0">
    <AdministrativoMenu />

    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="Fornecedores ativos" active>
          <div class="cardGrande p-3 mb-0 mt-3">
            <h5>
              Fornecedores ativos
              <font style="color: #BEBEBE">({{ fornecedores.length }})</font>
            </h5>

            <b-pagination
              v-if="rows > 0"
              class="d-flex justify-content-center"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="fornecedoresPaginados"
            ></b-pagination>

            <div
              id="fornecedoresPaginados"
              :fornecedores="fornecedores"
              :per-page="perPage"
              :current-page="currentPage"
              class="table-responsive"
            >
              <center>
                <table class="table" v-if="fornecedores.length != null">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Telefone</th>
                      <th>E-mail</th>
                      <th>Saldo (R$)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="fornecedor in fornecedores.slice(
                        perPage * (currentPage - 1),
                        perPage * currentPage
                      )"
                      :key="fornecedor.idFornecedor"
                    >
                      <td>
                        {{ fornecedor.idFornecedor }}
                      </td>
                      <td>
                        <router-link
                          :to="
                            `/administrativo/fornecedor/detalhe?fornecedor=${fornecedor.idFornecedor}`
                          "
                        >
                          {{ fornecedor.NomeFornecedor }}
                        </router-link>
                      </td>

                      <td>
                        {{ fornecedor.Telefone }}
                      </td>

                      <td>
                        {{ fornecedor.Email }}
                      </td>

                      <td v-if="fornecedor.Credito">
                        {{
                          Math.abs(fornecedor.Credito)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        }}
                      </td>
                      <td v-else>
                        0,00
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>

            <b-pagination
              v-if="rows > 0"
              class="d-flex justify-content-center m-0 p-0"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="fornecedoresPaginados"
            ></b-pagination>
          </div>
        </b-tab>
        <b-tab title="Fornecedores em fila">
          <div class="cardGrande p-3 mb-0 mt-3">
            <h5>
              Fornecedores em fila
              <font style="color: #BEBEBE"
                >({{ pretendentesFornecedores.length }})</font
              >
            </h5>

            <b-pagination
              v-if="rows > 0"
              class="d-flex justify-content-center"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="fornecedoresPaginados"
            ></b-pagination>

            <div
              id="fornecedoresPaginados"
              :fornecedores="pretendentesFornecedores"
              :per-page="perPage"
              :current-page="currentPage"
              class="table-responsive"
            >
              <center>
                <table
                  class="table"
                  v-if="pretendentesFornecedores.length != null"
                >
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Telefone</th>
                      <th>E-mail</th>
                      <th>Endereço</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="fornecedor in pretendentesFornecedores.slice(
                        perPage * (currentPage - 1),
                        perPage * currentPage
                      )"
                      :key="fornecedor.idContato"
                    >
                      <td>
                        {{ fornecedor.Nome }}
                        <br />
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="aceitarFornecedor(fornecedor)"
                        >
                          Aceitar fornecedor
                        </button>
                      </td>

                      <td>
                        {{ fornecedor.Telefone }}
                      </td>

                      <td>
                        {{ fornecedor.Email }}
                      </td>

                      <td>
                        Logradouro: {{ fornecedor.Logradouro }}<br />
                        Número: {{ fornecedor.Numero }}<br />
                        Unidade: {{ fornecedor.Unidade }}<br />
                        Bairro: {{ fornecedor.Bairro }}<br />
                        Cidade: {{ fornecedor.Cidade }}<br />
                        Estado: {{ fornecedor.UF }}<br />
                        Complemento: {{ fornecedor.Complemento }}<br />
                        CEP: {{ fornecedor.CEP }}<br />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>

            <b-pagination
              v-if="rows > 0"
              class="d-flex justify-content-center m-0 p-0"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="fornecedoresPaginados"
            ></b-pagination>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import * as axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import AdministrativoMenu from "./AdministrativoMenu";
import "vue-loading-overlay/dist/vue-loading.css";
import ambiente from "../../variables/variables.js"

export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    rows() {
      return this.fornecedores.length;
    },
  },

  mounted() {
    this.carregarFornecedores();
    this.carregarPretendentesFornecedores();
  },
  components: {
    Loading,
    AdministrativoMenu,
  },

  data() {
    return {
      isLoading: false,
      fornecedores: [],
      pretendentesFornecedores: [],

      perPage: 100,
      currentPage: 1,
      fullPage: true,
      onCancel: this.cancelLoading(),
      // rows: null,
    };
  },
  watch: {
    $route() {
      this.carregarFornecedores();
      this.carregarPretendentesFornecedores();
    },
  },

  methods: {
    cancelLoading() {
      this.isLoading = false;
    },

    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    async carregarFornecedores() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 5,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.fornecedores = response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    async carregarPretendentesFornecedores() {
      this.isLoading = true;

      try {
        await axios
          .post(ambiente + "fornecedor.php", {
            transaction: 5.1,
            idUsuario: this.$store.getters.user.id,
          })
          .then((response) => {
            this.pretendentesFornecedores = response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
      this.isLoading = false;
    },

    aceitarFornecedor(fornecedor) {
      this.$swal({
        title: "Aceitar fornecedor",
        text:
          "Tem certeza que deseja aceitar o fornecedor " +
          fornecedor.Nome +
          "?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Aceitar",
        cancelButtonText: "Cancelar",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          try {
            axios
              .post(ambiente + "fornecedor.php", {
                transaction: 5.2,
                idUsuario: this.$store.getters.user.id,
                fornecedor: fornecedor,
              })
              .then((response) => {
              this.$swal.fire({
              toast: true,
              icon: "success",
              title: response.data,
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
              })
              .catch((error) => {
                this.isLoading = false;
                console.log(error);
              });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
          this.isLoading = false;
        }
      });

      this.carregarFornecedores();
      this.carregarPretendentesFornecedores();
    },
  },
};
</script>
<style scoped>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 0px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
</style>
